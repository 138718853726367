.input {
  @apply block w-full border rounded-sm shadow-sm h-10 px-3 focus:outline-none sm:text-sm placeholder-gray-300;
}

.inputSmall {
  @apply block w-full border rounded shadow-sm h-8 px-3 focus:outline-none text-sm placeholder-gray-300;
}

.inputReadOnly {
  @apply block w-full border rounded-sm shadow-none h-10 px-0 focus:outline-none text-base placeholder-gray-300;
}

.inputSmallReadOnly {
  @apply block w-full border rounded shadow-none h-8 px-0 focus:outline-none text-sm placeholder-gray-300;
}

.inputError {
  @apply border-red-300 text-red-900;
}

.errorIcon {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none h-10;
}