import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @param id
 * @example
 */
const useUnit = (id) => {
  const {
    data, error, mutate
  } = useSWR(`/units/${id}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    unit: data?.payload.unit
  };
};

export default useUnit;
