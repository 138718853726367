import AddPublicName from "./add-public-name";

/**
 *
 * @param props0 - The root object
 * @param props0.handleClick - The root object
 * @example
 */
const UnitDocumentSuggestions = ({ handleClick }) => (
  <>
    <h4 className="text-sm font-medium text-gray-700">Vorschläge:</h4>

    <ul className="mt-1 list-disc pl-8 text-sm">
      <AddPublicName onClick={handleClick} value="Grundriss" />

      <AddPublicName onClick={handleClick} value="Exposé" />
    </ul>
  </>
);

export default UnitDocumentSuggestions;
