import { initialValues } from "~/src/features/project-units/schema";
import AreaField from "~/src/ui/forms/area-field";
import CheckboxField from "~/src/ui/forms/checkbox-field";
import CollectionField from "~/src/ui/forms/collection-field";
import ComboField from "~/src/ui/forms/combo-field";
import DateField from "~/src/ui/forms/date-field";
import MultiField from "~/src/ui/forms/multi-field";
import NumberField from "~/src/ui/forms/number-field";
import NumberInput from "~/src/ui/forms/number-input/index.new";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

/**
 *
 * @param props0 - The root object
 * @param props0.isEdit - The root object
 * @param props0.onSubmit - The root object
 * @example
 */
const OfferForm = ({ isEdit, onSubmit }) => {
  const {
    clearErrors, control, formState: { errors }, handleSubmit, register, resetField, setValue, watch
  } = useFormContext();

  const verwertetDateReference = useRef();

  const verwertet = watch("verwertet");

  const rentable = watch("rentable");

  const showRentNettoHistory = watch("showRentNettoHistory");
  const showRentBruttoHistory = watch("showRentBruttoHistory");
  const showRentBkHistory = watch("showRentBkHistory");

  const buyable = watch("buyable");

  const showOfferPriceNormalHistory = watch("showOfferPriceNormalHistory");
  const showOfferPriceInvestorHistory = watch("showOfferPriceInvestorHistory");
  const showOfferPriceBruttoInvestorHistory = watch("showOfferPriceBruttoInvestorHistory");

  useEffect(() => {
    if (errors.verwertetDate) {
      verwertetDateReference.current.scrollIntoView();
    }
  }, [errors]);

  return (
    <div className="border-t-2 border-primary-brown pt-6">
      <h2 className="ml-6 text-xl font-medium">Angebot</h2>

      {/* --- verwertet --- */}

      <CheckboxField
        label="Objekt verwertet"
        name="verwertet"
        {...{
          errors,
          register
        }}
      />

      {verwertet
        ? (
          <div className="m-4 rounded bg-white bg-opacity-50" ref={verwertetDateReference}>
            <DateField
              label="Verwertungsdatum"
              name="verwertetDate"
              {...{
                control,
                errors,
                register
              }}
              className="col-span-1"
              defaultValue={initialValues.verwertetDate}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
              minDate={new Date("2000-01-01")}
              onChange={(e) => {
                handleSubmit(
                  () => { },
                  (innerErrors) => {
                    clearErrors(Object.keys(innerErrors).filter((key) => key !== "verwertetDate"));
                  }
                )({ verwertetDate: e });
              }}
            />

            <ComboField
              defaultOptions
              label="Verwertet-Datumsformat"
              name="verwertetDateFormat"
              messages={{
                queryEmpty: "Verwertet-Datumsformat..."
              }}
              options={[
                {
                  label: "Tag",
                  value: "day"
                },
                {
                  label: "Monat",
                  value: "month"
                },
                {
                  label: "Quartal",
                  value: "quarter"
                },
                {
                  label: "Jahr",
                  value: "year"
                }
              ]}
              {...{
                control,
                isEdit,
                resetField,
                setValue,
                watch
              }}
              className="col-span-6"
            />
          </div>
        )
        : null}

      {/* --- rentable --- */}

      <CheckboxField
        label="Mietpreise vorhanden"
        name="rentable"
        {...{
          errors,
          register
        }}
      />

      {rentable
        ? (
          <div className="m-4 rounded bg-white bg-opacity-50">
            <NumberField
              className="col-span-3"
              label="Miete Netto"
              name="rentNetto"
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showRentNettoHistory"
              {...{
                errors,
                register
              }}
            />

            {showRentNettoHistory
              ? (
                <div className="m-4 inline-block rounded bg-white bg-opacity-50">
                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Miete Netto</>}
                    name="rentNettoHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              className="col-span-3"
              label={<>Miete <span className="ml-0.5 font-bold text-indigo-600">Netto + USt</span></>}
              name="rentBrutto"
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showRentBruttoHistory"
              {...{
                errors,
                register
              }}
            />

            {showRentBruttoHistory
              ? (
                <div className="m-4 inline-block bg-white bg-opacity-50">
                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Miete <span className="ml-0.5 font-bold text-indigo-600">Netto + USt</span></>}
                    name="rentBruttoHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              className="col-span-3"
              label={<>Miete <span className="ml-1 font-bold text-green-500">Gesamtbelastung</span></>}
              name="rentBk"
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showRentBkHistory"
              {...{
                errors,
                register
              }}
            />

            {showRentBkHistory
              ? (
                <div className="m-4 inline-block bg-white bg-opacity-50">
                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Miete <span className="ml-1 font-bold text-green-500">Gesamtbelastung</span></>}
                    name="rentBkHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}
          </div>
        )
        : null}

      {/* --- buyable --- */}

      <CheckboxField
        label="Kaufpreise vorhanden"
        name="buyable"
        {...{
          errors,
          register
        }}
      />

      {buyable
        ? (
          <div className="m-4 bg-white bg-opacity-50">
            <NumberField
              className="col-span-3"
              label="Kaufpreis Eigennutzer"
              name="offerPriceNormal"
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showOfferPriceNormalHistory"
              {...{
                errors,
                register
              }}
            />

            {showOfferPriceNormalHistory
              ? (
                <div className="m-4 inline-block bg-white bg-opacity-50">
                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Kaufpreis Eigennutzer</>}
                    name="offerPriceNormalHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              className="col-span-3"
              label={<>Kaufpreis Anleger <span className="ml-0.5 font-bold text-indigo-600">exkl. USt</span></>}
              name="offerPriceInvestor"
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showOfferPriceInvestorHistory"
              {...{
                errors,
                register
              }}
            />

            {showOfferPriceInvestorHistory
              ? (
                <div className="m-4 inline-block bg-white bg-opacity-50">
                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Kaufpreis Anleger <span className="ml-0.5 font-bold text-indigo-600">exkl. USt</span></>}
                    name="offerPriceInvestorHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },

                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}

            <NumberField
              className="col-span-3"
              label={<>Kaufpreis Anleger <span className="ml-0.5 font-bold text-green-500">inkl. USt</span></>}
              name="offerPriceBruttoInvestor"
              {...{
                control,
                errors,
                register
              }}
            />

            <CheckboxField
              label="historische Preise"
              name="showOfferPriceBruttoInvestorHistory"
              {...{
                errors,
                register
              }}
            />

            {showOfferPriceBruttoInvestorHistory
              ? (
                <div className="m-4 inline-block bg-white bg-opacity-50">
                  <CollectionField
                    multiple
                    insertMethod="prepend"
                    label={<>(historisch)<br />Kaufpreis Anleger <span className="ml-0.5 font-bold text-green-500">inkl. USt</span></>}
                    name="offerPriceBruttoInvestorHistory"
                    inputs={[
                      {
                        minDate: new Date("2000-01-01"),
                        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
                        label: "Datum bis",
                        name: "date",
                        type: "date"
                      },
                      {
                        label: "Preis",
                        name: "price",
                        type: "number"
                      }
                    ]}
                    {...{
                      control,
                      errors,
                      handleSubmit,
                      register
                    }}
                  />
                </div>
              )
              : null}
          </div>
        )
        : null}

      {/* --- data --- */}

      <NumberField
        area
        label="Fläche"
        name="offerArea"
        {...{
          control,
          errors,
          register
        }}
      />

      <NumberField
        int
        label="Zimmer"
        name="offerRoomCount"
        {...{
          control,
          errors,
          register
        }}
      />

      <MultiField id="offerLoggiaCount" label="Loggia Anzahl / Fläche">
        <NumberInput
          int
          name="offerLoggiaCount"
          placeholder="Loggia Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerLoggiaArea"
          placeholder="Loggia Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="offerBalkonCount" label="Balkon Anzahl / Fläche">
        <NumberInput
          int
          name="offerBalkonCount"
          placeholder="Balkon Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerBalkonArea"
          placeholder="Balkon Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="offerGartenCount" label="Garten Anzahl / Fläche">
        <NumberInput
          int
          name="offerGartenCount"
          placeholder="Garten Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerGartenArea"
          placeholder="Garten Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="offerTerrasseCount" label="Terrasse Anzahl / Fläche">
        <NumberInput
          int
          name="offerTerrasseCount"
          placeholder="Terrasse Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerTerrasseArea"
          placeholder="Terrasse Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <MultiField id="offerKellerCount" label="Keller Anzahl / Fläche">
        <NumberInput
          int
          name="offerKellerCount"
          placeholder="Keller Anzahl"
          {...{
            control,
            errors,
            register
          }}
        />

        <NumberInput
          area
          name="offerKellerArea"
          placeholder="Keller Fläche"
          {...{
            control,
            errors,
            register
          }}
        />
      </MultiField>

      <AreaField
        label="Sonstige Bemerkungen"
        name="offerNotice"
        {...{
          control,
          errors,
          register
        }}
      />

      <AreaField
        label="Interne Notizen"
        name="internalOfferNotes"
        {...{
          control,
          errors,
          register
        }}
      />

    </div>
  );
};

export default OfferForm;
