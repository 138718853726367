import API from "~/src/modules/api";

/**
 *
 * @param projectId
 * @param published
 * @param mutate
 * @example
 */
const handlePublishToggle = (projectId, published, mutate) => {
  const url = `/projects/${projectId}/${published ? "unpublish" : "publish"}`;

  API.post(url)
    .then(async (res) => {
      await mutate();
    }).catch((error) => {
      console.log(error);
    })
    .finally(() => {
    });
};

export { handlePublishToggle };
