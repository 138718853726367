/* eslint-disable max-lines-per-function */
import { validationErrors } from "~/src/modules/errors";
import produce from "immer";
import {
  useEffect, useRef, useState
} from "react";
import isEqual from "react-fast-compare";

/**
 *
 * @param root0 - The root object
 * @param root0.initialValues - The root object
 * @param root0.onSubmit - The root object
 * @param root0.schema - The root object
 * @param root0.context - The root object
 * @example
 */
const useForm = ({
  context = {}, initialValues = {}, onSubmit = () => { }, schema = {}
}) => {
  const isMounted = useRef(false);
  const isEditing = useRef(false);
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isEditing.current === true) {
      isEditing.current = false;

      return;
    }
    if (
      isMounted.current === true &&
      !isEqual(values, initialValues)
    ) {
      setValues(initialValues);
    }
  }, [initialValues, values]);

  /**
   *
   * @param event
   * @example
   */
  const handleChange = (event) => {
    isEditing.current = true;

    const newValues = makeNewValues({
      target: event.target,
      values
    });

    setValues(newValues);

    const validated = validate(newValues, schema, { context });

    setErrors(validated);
  };

  /**
   *
   * @param event
   * @example
   */
  const handleBlur = (event) => {
    isEditing.current = true;

    const newTouched = makeNewTouched({
      target: event.target,
      touched
    });

    setTouched(newTouched);

    const validated = validate(values, schema, { context });

    setErrors(validated);
  };

  /**
   *
   * @param event
   * @example
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    isEditing.current = true;
    setIsSubmitting(true);

    const validated = validate(values, schema, { context });

    setErrors(validated);

    if (!hasErrors(validated)) {
      setIsSubmitting(true);
      onSubmit();
    }
  };

  /**
   *
   * @example
   */
  function resetForm() {
    setValues(initialValues);
    setTouched({});
    setErrors({});
    setIsSubmitting(false);
  }

  /**
   *
   * @param key
   * @param newItem
   * @example
   */
  function push(key, newItem) {
    isEditing.current = true;

    const newValues = produce(values, (draft) => {
      if (!draft[key]) {
        draft[key] = [];
      }
      draft[key].push(newItem);
    });

    setValues(newValues);

    const validated = validate(newValues, schema, { context });

    setErrors(validated);
  }

  /**
   *
   * @param key
   * @param index
   * @example
   */
  function remove(key, index) {
    isEditing.current = true;
    const newValues = produce(values, (draft) => {
      draft[key].splice(index, 1);
    });

    setValues(newValues);

    const validated = validate(newValues, schema, { context });

    setErrors(validated);
  }

  /**
   *
   * @param properties
   * @example
   */
  function updateProperties(properties) {
    isEditing.current = true;
    const newValues = produce(values, (draft) => {
      for (const key of Object.keys(properties)) {
        draft[key] = properties[key];
      }
    });

    setValues(newValues);

    const validated = validate(newValues, schema, { context });

    setErrors(validated);
  }

  return {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    hasErrors,
    isLoading,
    isSubmitting,
    push,
    remove,
    resetForm,
    setIsLoading,
    setIsSubmitting,
    touched,
    updateProperties,
    values
  };
};

export default useForm;

/**
 *
 * @param object
 * @example
 */
function hasErrors(object) {
  return Object.keys(object).length > 0;
}

/**
 *
 * @param options0 - The root object
 * @param options0.target - The root object
 * @param options0.values - The root object
 * @example
 */
function makeNewValues({ target, values }) {
  const value = target?.type === "checkbox" ? target.checked : target.value;
  const [
    name,
    index,
    subName
  ] = target.name.split(".");

  return produce(values, (draft) => {
    if (index === undefined) {
      draft[name] = value;

      return;
    }

    if (!draft[name]) {
      draft[name] = {};
    }

    if (!subName) {
      draft[name][index] = value;

      return;
    }

    if (draft[name][index]) {
      draft[name][index][subName] = value;

      return;
    }

    draft[name][index] = {};
    draft[name][index][subName] = value;
  });
}

/**
 *
 * @param options0 - The root object
 * @param options0.target - The root object
 * @param options0.touched - The root object
 * @example
 */
function makeNewTouched({ target, touched }) {
  const [
    name,
    index,
    subName
  ] = target.name.split(".");

  return produce(touched, (draft) => {
    if (index === undefined) {
      draft[name] = true;

      return;
    }

    if (!draft[name]) {
      draft[name] = {};
    }

    if (!subName) {
      draft[name][index] = true;

      return;
    }

    if (draft[name][index]) {
      draft[name][index][subName] = true;

      return;
    }

    draft[name][index] = {};
    draft[name][index][subName] = true;
  });
}

/**
 *
 * @param input
 * @param schema
 * @param options0 - The root object
 * @param options0.context - The root object
 * @example
 */
const validate = (input, schema, { context }) => {
  const validation = schema.validate(input, {
    abortEarly: false,
    context
  });

  if (validation.error) {
    return buildErrorObject(validation.error.details);
  }

  return {};
};

const buildErrorObject = (errors) => {
  const error = {};

  for (const errorItem of errors) {
    const errorValue = {
      code: `error.${errorItem.path.join("_")}.${errorItem.type}`,
      message: validationErrors(errorItem),
      type: errorItem.type
    };

    const [
      key = "common",
      index,
      subKey
    ] = errorItem.path;

    if (!error.hasOwnProperty(key)) {
      error[key] = {};
    }

    if (index === undefined) {
      error[key] = errorValue;

      continue;
    }
    error[key][index] = errorValue;

    if (!subKey) {
      error[key][index] = errorValue;

      continue;
    }

    if (error[key][index]) {
      error[key][index][subKey] = errorValue;

      continue;
    }

    error[key][index] = {};
    error[key][index][subKey] = errorValue;
  }

  return error;
};
