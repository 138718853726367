import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 */
export default function useSyncStatus() {
  const {
    data, error, mutate
  } = useSWR("/status/project-sync", fetcher);

  return {
    syncStatus: data?.payload.syncStatus,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
}
