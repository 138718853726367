import axios from "axios";

const instance = axios.create({
  baseURL: window?.settings?.REACT_APP_API_ENDPOINT,
  withCredentials: true
});

// instance.interceptors.response.use((response) => {
//   return response.data;
// })

/**
 *
 * @param url
 * @example
 */
const fetcher = (url) => instance.get(url).then((res) => res.data).catch((error_) => {
  const error = new Error("An error occurred while fetching the data.");

  error.status = error_.response.status;

  return error;
});

export { fetcher };

/**
 *
 * @param error
 * @example
 */
const parseError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // console.log(error.response.status);
    // console.log(error.response.data);

    return {
      data: error.response.data,
      status: error.response.status
    };
  }
  else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);

    return {
      status: 500
    };
  }
  // Something happened in setting up the request that triggered an Error
  console.log("Error", error.message);

  return {
    status: 500
  };
};

export { parseError };

export default instance;
