import API from "~/src/modules/api";

import {
  createIssuerCertificateOption,
  createIssuerOption,
  generateEditValues
} from "./schema";

export const getIssuerCertificateOptions =
  async (searchString, issuerId) => (await fetchIssuerCertificates(searchString, issuerId))
    .map(createIssuerCertificateOption);

const fetchIssuerCertificates = async (searchString, issuerId) => {
  const res = await requestField("certifications", {
    issuer_id: issuerId,
    q: searchString
  });

  return extractResponse(res, "certifications");
};

export const fetchIssuers = async (inputValue) => {
  const res = await requestField("issuers", { q: inputValue });
  const queryRes = await extractResponse(res, "issuers");

  return queryRes
    .map((issuer) => ({
      label: issuer.name,
      ...issuer
    }))
    .filter((issuer) => issuer.searchable);
};

const requestField = async (fieldName, body) => API.post(`/certificates/${fieldName}/search`, body);

const extractResponse = async (res, fieldName) => res.data.payload[fieldName];

export const noFetchedCertificates = (issuerCertificates) => issuerCertificates.length === 1 &&
  Object.keys(issuerCertificates[0]).length === 0;

export const createCertificateForNewIssuer = (certificateId, newIssuer) => {
  const newCertificate = generateEditValues();

  newCertificate.id = certificateId;
  newCertificate.issuer = createIssuerOption(newIssuer);

  return newCertificate;
};

export const createCertificateForNewIssuerCertificate = (certificateId, issuer, newIssuerCertificate) => Object.assign(generateEditValues(), {
  id: certificateId,
  issuer: createIssuerOption(issuer),
  issuer_certificate: createIssuerCertificateOption(newIssuerCertificate)
});

