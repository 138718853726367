import API from "~/src/modules/api";
import { useEffect, useState } from "react";

/**
 *
 * @param fileName
 * @example
 */
const UseSignedSource = (fileName) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    getSignedUrl(fileName)
      .then((res) => {
        setSource(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fileName]);

  return {
    src: source
  };
};

export default UseSignedSource;

/**
 *
 * @param fileName
 * @example
 */
function getSignedUrl(fileName) {
  const file = {
    name: fileName
  };

  return new Promise((resolve, reject) => {
    API.post("/files/sign-download", { file })
      .then((res) => {
        resolve(res.data.payload.url);
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });
  });
}
