/* eslint-disable no-empty-function */
/* eslint-disable class-methods-use-this */

const commonOptions = /** @type {const} */ ({
  credentials: "include",
  headers: new Headers({
    "Content-Type": "application/json"
  })
});

/**
 * @typedef {(options: object) => void|Promise<void>} Hook
 * @typedef {(error: Error, options: object) => void|Promise<void>} ErrorHook
 * @typedef {(data: object, options?: object) => object|Promise<object>} Transform
 */

/**
 * @typedef {object} HandlerOptions
 * @property {string} endpoint - The endpoint.
 * @property {Hook} [afterCreateSuccess] - Function to run after create success.
 * @property {Hook} [afterDeleteSuccess] - Function to run after delete success.
 * @property {Hook} [afterUpdateSuccess] - Function to run after update success.
 * @property {Hook} [afterEverySuccess] - Function to run after every success.
 * @property {Hook} [afterCreate] - Function to run after create.
 * @property {Hook} [afterDelete] - Function to run after delete.
 * @property {Hook} [afterUpdate] - Function to run after update.
 * @property {Hook} [afterEvery] - Function to run after every.
 * @property {ErrorHook} [afterCreateError] - Function to run on create error.
 * @property {ErrorHook} [afterDeleteError] - Function to run on delete error.
 * @property {ErrorHook} [afterUpdateError] - Function to run on update error.
 * @property {ErrorHook} [afterEveryError] - Function to run on every error.
 * @property {Transform} [transformCreateData] - Function to transform create data.
 * @property {Transform} [transformUpdateData] - Function to transform update data.
 */

/**
 *
 */
const Handler = class {

  /**
   * @type {Hook}
   * @example
   */
  #afterCreate = () => {};

  /**
   * @type {ErrorHook}
   * @example
   */
  #afterCreateError = () => {};

  /**
   * @type {Hook}
   * @example
   */
  #afterCreateSuccess = () => {};

  /**
   * @type {Hook}
   * @example
   */
  #afterDelete = () => {};

  /**
   * @type {ErrorHook}
   * @example
   */
  #afterDeleteError = () => {};

  /**
   * @type {Hook}
   * @example
   */
  #afterDeleteSuccess = () => {};

  /**
   * @type {Hook}
   * @example
   */
  #afterEvery = () => {};

  /**
   * @type {ErrorHook}
   * @example
   */
  #afterEveryError = () => {};

  /**
   * @type {Hook}
   * @example
   */
  #afterEverySuccess = () => {};

  /**
   * @type {Hook}
   * @example
   */
  #afterUpdate = () => {};

  /**
   * @type {ErrorHook}
   * @example
   */
  #afterUpdateError = () => {};

  /**
   * @type {Hook}
   * @example
   */
  #afterUpdateSuccess = () => {};

  /**
   *
   * @param {string} [id]
   * @param parts
   * @returns
   * @example
   */
  #constructUrl = (parts) => {
    const {
      endpoint
    } = this;

    const baseUrl = window?.settings?.REACT_APP_API_ENDPOINT;

    const actualEndpoint = endpoint.replaceAll(
      /(?<part>:[^/]+)/gu,
      (match, ...replaceArguments) => {
        const groups = replaceArguments.at(-1);

        return parts[groups.part.slice(1)] ?? "";
      }
    );

    return `${baseUrl}${actualEndpoint}`;
  };

  #getHook = (operation, type) => ({
    create: {
      error: this.#afterCreateError,
      finally: this.#afterCreate,
      success: this.#afterCreateSuccess
    },
    delete: {
      error: this.#afterDeleteError,
      finally: this.#afterDelete,
      success: this.#afterDeleteSuccess
    },
    every: {
      error: this.#afterEveryError,
      finally: this.#afterEvery,
      success: this.#afterEverySuccess
    },
    update: {
      error: this.#afterUpdateError,
      finally: this.#afterUpdate,
      success: this.#afterUpdateSuccess
    }
  }[operation][type]);

  #handleResponse = async ({
    operation,
    options = {},
    response
  }) => {
    const {
      setIsLoading = () => {}
    } = options;

    const safeOptions = new Proxy(
      options,
      {
        get: (target, key) => target[key] ?? (() => this.#warnMissingFunction(key))
      }
    );

    let returnedValue;

    if (response.ok) {
      try {
        returnedValue = response.headers.get("content-type").includes("application/json")
          ? await response.json()
          : await response.text();
      }
      catch {
        try {
          returnedValue = await response.text();
        }
        catch {
          // do nothing
        }
      }

      await this.#getHook(operation, "success")(returnedValue, safeOptions);
      await this.#getHook("every", "success")(returnedValue, safeOptions);
    }
    else {
      const error = new Error(response.statusText);

      await this.#getHook(operation, "error")(error, safeOptions);
      await this.#getHook("every", "error")(error, safeOptions);

      returnedValue = error;
    }

    await this.#getHook(operation, "finally")(safeOptions);
    await this.#getHook("every", "finally")(safeOptions);

    setIsLoading(false);

    return returnedValue;
  };

  /**
   * @type {Transform}
   * @example
   */
  #transformCreateData = (data) => data;

  /**
   * @type {Transform}
   * @example
   */
  #transformUpdateData = (data) => data;

  #warnMissingFunction = (name) => {
    console.debug(new Error(`Function "${name}" is not implemented.`));
    console.debug(this);
  };

  /**
   *
   * @param options
   * @example
   */
  handleCreate = async (options) => {
    const {
      id,
      data,
      setIsLoading = () => {}
    } = options;

    setIsLoading(true);

    const url = this.#constructUrl(options);

    const body = JSON.stringify(
      await this.#transformCreateData(data, options)
    );

    const response = await fetch(
      url,
      {
        ...commonOptions,
        body,
        method: "POST"
      }
    );

    return this.#handleResponse({
      operation: "create",
      options,
      response
    });
  };

  /**
   *
   * @param options
   * @example
   */
  handleDelete = async (options) => {
    const {
      setIsLoading = () => {}
    } = options;

    setIsLoading(true);

    const url = this.#constructUrl(options);

    const response = await fetch(
      url,
      {
        ...commonOptions,
        method: "DELETE"
      }
    );

    return this.#handleResponse({
      operation: "delete",
      options,
      response
    });
  };

  /**
   *
   * @param options
   * @example
   */
  handleUpdate = async (options) => {
    const {
      data,
      setIsLoading = () => {}
    } = options;

    setIsLoading(true);

    const url = this.#constructUrl(options);

    const body = JSON.stringify(
      await this.#transformUpdateData(data, options)
    );

    const response = await fetch(
      url,
      {
        ...commonOptions,
        body,
        method: "PATCH"
      }
    );

    return this.#handleResponse({
      operation: "update",
      options,
      response
    });
  };

  /**
   * The constructor for Handler.
   *
   * @param {HandlerOptions} options - The options object.
   * @example
   */
  constructor({
    endpoint,

    afterCreateSuccess = this.#afterCreateSuccess,
    afterDeleteSuccess = this.#afterDeleteSuccess,
    afterUpdateSuccess = this.#afterUpdateSuccess,

    afterEverySuccess = this.#afterEverySuccess,

    afterCreateError = this.#afterEverySuccess,
    afterDeleteError = this.#afterEverySuccess,
    afterUpdateError = this.#afterEverySuccess,

    afterEveryError = this.#afterEverySuccess,

    afterCreate = this.#afterEverySuccess,
    afterDelete = this.#afterEverySuccess,
    afterUpdate = this.#afterEverySuccess,

    afterEvery = this.#afterEverySuccess,

    transformCreateData = this.#transformCreateData,

    transformUpdateData = this.#transformUpdateData

  }) {
    this.endpoint = endpoint;
    this.#afterCreateSuccess = afterCreateSuccess;
    this.#afterDeleteSuccess = afterDeleteSuccess;
    this.#afterUpdateSuccess = afterUpdateSuccess;
    this.#afterEverySuccess = afterEverySuccess;
    this.#afterCreateError = afterCreateError;
    this.#afterDeleteError = afterDeleteError;
    this.#afterUpdateError = afterUpdateError;
    this.#afterEveryError = afterEveryError;
    this.#afterCreate = afterCreate;
    this.#afterDelete = afterDelete;
    this.#afterUpdate = afterUpdate;
    this.#afterEvery = afterEvery;
    this.#transformCreateData = transformCreateData;
    this.#transformUpdateData = transformUpdateData;
  }

};

export default Handler;
