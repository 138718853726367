.tableContainer {
  @apply mt-6 my-6 grid grid-cols-3 md:grid-cols-1 border-2 border-gray-200 overflow-hidden rounded-sm;
}

.tableHeaderContainer {
  @apply hidden md:grid grid-cols-4 border-b-2 border-gray-200;
}

.headerItem {
  @apply bg-gray-100 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider;
}

.tableBody {
  @apply grid grid-cols-1 col-span-2 md:col-span-1 divide-y-2 divide-gray-200;
}

.colProjectName {
  @apply col-span-1 md:col-span-2 px-4 py-4 text-left whitespace-nowrap text-sm text-gray-500;
}

.colStyle {
  @apply px-4 py-1 md:py-4 text-left whitespace-nowrap text-sm text-gray-500;
}

.colDateEdited {
  @apply col-span-1 md:col-span-1 px-4 py-1 md:py-4 text-left whitespace-nowrap text-sm text-gray-500;
}

.colBadge {
  @apply col-span-1 md:col-span-1 px-4 pt-1 pb-2 md:py-4 text-left whitespace-nowrap text-sm text-gray-500;
}