import ProjectBuilders from "~/src/features/company-roles/builders";
import ProjectMarketers from "~/src/features/company-roles/marketers";
import ProjectOwners from "~/src/features/company-roles/owners";
import NewCompanyModal from "~/src/features/modals/new-company-modal";
import useModal from "~/src/hooks/use-modal";
import useProject from "~/src/hooks/use-project";
import TabHeader from "~/src/ui/headers/tab-header";
import Skeleton from "~/src/ui/loading/item-skeleton";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const ProjectCompaniesPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  const {
    hide: hideNewCompanyModal, show: showNewCompanyModal, visible: newCompanyModalVisible
  } = useModal();

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <div>
      <NewCompanyModal hide={hideNewCompanyModal} visible={newCompanyModalVisible} />

      <TabHeader>Unternehmen</TabHeader>

      <div className="divide-y divide-gray-200">

        <ProjectBuilders showNewCompanyModal={showNewCompanyModal} />

        <ProjectOwners showNewCompanyModal={showNewCompanyModal} />

        <ProjectMarketers showNewCompanyModal={showNewCompanyModal} />

      </div>
    </div>
  );
};

export default ProjectCompaniesPage;
