import Joi from "joi";

export const initialValues = {
  construction_start_date: "",
  construction_start_date_format: {
    label: "",
    value: ""
  },
  construction_year: "",
  estimated_finish_date: "",
  estimated_finish_date_format: {
    label: "",
    value: ""
  },
  is_new: {
    label: "",
    value: true
  },
  reconstruction_year: ""
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    const { construction_infos } = project;

    return {
      construction_start_date: construction_infos?.construction_start_date || null,
      construction_start_date_format: {
        value: construction_infos?.construction_start_date_format || "month"
      },
      construction_year: construction_infos?.construction_year || null,
      estimated_finish_date: construction_infos?.estimated_finish_date || null,
      estimated_finish_date_format: {
        value: construction_infos?.estimated_finish_date_format || "month"
      },
      is_new: {
        label: "",
        value: construction_infos?.is_new !== false
      },
      reconstruction_year: construction_infos?.reconstruction_year || null
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  construction_start_date: Joi.date().allow(null),
  construction_start_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),
  construction_year: Joi.date().allow(null),
  estimated_finish_date: Joi.date().allow(null),
  estimated_finish_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),
  is_new: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.boolean().required()
  }).required(),
  reconstruction_year: Joi.date().allow(null)
});
