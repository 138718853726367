import NotificationProvider from "~/src/contexts/notificationContext";
import ErrorNotification from "~/src/ui/notifications/error-notification";
import NotificationContainer from "~/src/ui/notifications/notification-container";
import SuccessNotification from "~/src/ui/notifications/success-notification";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <SWRConfig
        value={{
          revalidateOnFocus: false
        }}
      >
        <NotificationProvider>
          <App />

          <NotificationContainer>
            <SuccessNotification />

            <ErrorNotification />
          </NotificationContainer>
        </NotificationProvider>
      </SWRConfig>
    </HelmetProvider>
  </React.StrictMode>,
  document.querySelector("#root")
);
