import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @param options0 - The root object
 * @param options0.name - The root object
 * @param options0.page - The root object
 * @example
 */
const useUsers = ({ name = "", page = 1 }) => {
  const parameters = `page=${page}&name=${encodeURIComponent(name)}`;

  const {
    data, error, mutate
  } = useSWR(`/companies?${parameters}`, fetcher);

  return {
    companies: data?.payload.companies,
    isError: error,
    isLoading: !error && !data,
    mutate,
    pagination: data?.payload.pagination
  };
};

export default useUsers;
