import useAutomatedProject from "~/src/hooks/use-automated-project";
import dateFormatter from "~/src/modules/date-formatter";
import { marketingPhaseLabels } from "~/src/modules/labels";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import DescriptionTitle from "~/src/ui/titles/description-title";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const Timeline = () => {
  const { id } = useParams();

  const {
    automated_project, isError, isLoading
  } = useAutomatedProject(id);

  return (
    <TabListContainer>

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Timeline" />

        <DescriptionContainer>
          <div className="grid grid-cols-4 gap-x-2 gap-y-4 bg-gray-100 p-2">

            <div className="col-span-2">
              <p className="text-sm font-medium text-gray-700">Erstes KV-Datum</p>

              <p className="text-sm">
                {automated_project?.first_contract_date ? dateFormatter(automated_project?.first_contract_date) : "-"}
              </p>
            </div>

            <div className="col-span-2">
              <p className="text-sm font-medium text-gray-700">Letztes KV-Datum</p>

              <p className="text-sm">
                {automated_project?.last_contract_date ? dateFormatter(automated_project?.last_contract_date) : "-"}
              </p>
            </div>
          </div>
        </DescriptionContainer>

      </div>
    </TabListContainer>
  );
};

export default Timeline;
