import Handler from "~/src/modules/handler.js";

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/energy-classes/:id",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { handleClose, mutate }) => {
    await mutate();
    handleClose();
  },
  transformCreateData: (data, { projectId }) => ({
    energy_class: {
      description: data.description || null,
      fgee_class: data.fgee_class || null,
      fgee_from: data.fgee_from || null,
      fgee_to: data.fgee_range ? data.fgee_to : null,
      gst_numbers: data.gstNumbers.map(({ value }) => value) || null,
      hwb_class: data.hwb_class || null,
      hwb_from: data.hwb_from || null,
      hwb_to: data.hwb_range ? data.hwb_to : null,
      project: {
        id: projectId
      }
    }
  }),
  transformUpdateData: (data) => ({
    energy_class: {
      description: data.description || null,
      fgee_class: data.fgee_class || null,
      fgee_from: data.fgee_from || null,
      fgee_to: data.fgee_range ? data.fgee_to : null,
      gst_numbers: data.gstNumbers.map(({ value }) => value) || null,
      hwb_class: data.hwb_class || null,
      hwb_from: data.hwb_from || null,
      hwb_to: data.hwb_range ? data.hwb_to : null
    }
  })
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
