/* eslint-disable max-lines-per-function */
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import validationResolver from "~/src/modules/validation-resolver";
import Button from "~/src/ui/buttons/button";
import CancelButton from "~/src/ui/buttons/cancel-button";
import CheckboxField from "~/src/ui/forms/checkbox-field";
import ComboField from "~/src/ui/forms/combo-field";
import Field from "~/src/ui/forms/field";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import GoogleMapsIcon from "~/src/ui/icons/google-maps-icon";
import SlideOver from "~/src/ui/slides/container";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";

import { handleCreate, handleUpdate } from "./handlers";
import { formatClipboardText } from "./helpers";
import schema, { generateEditValues, initialValues } from "./schema";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.location - The root object
 * @param props0.projectId - The root object
 * @param props0.visible - The root object
 * @example
 */
const LocationSlide = ({
  hide, location: locationData, projectId, visible
}) => {
  const { mutate } = useProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    control, formState: { errors }, handleSubmit, register, reset, resetField, setValue, watch
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    if (locationData) {
      setIsEdit(true);
      reset(generateEditValues(locationData));
    }
  }, [reset, locationData]);

  const handleClose = () => {
    reset(initialValues);
    hide();
  };

  const handleToGoogleMaps = () => {
    window.open("https://google.com/maps", "_blank", "noopener noreferrer");
  };

  const onSubmit = (data) => {
    const handlerParameters = {
      data,
      handleClose,
      mutate,
      projectId,
      setIsLoading,
      showError,
      showSuccess
    };

    if (locationData) {
      handleUpdate({
        ...handlerParameters,
        id: locationData.id
      });
    }
    else {
      handleCreate(handlerParameters);
    }
  };

  const loadCityOptions = async (inputValue) => {
    const baseUrl = window?.settings?.REACT_APP_API_ENDPOINT;

    const url = `${baseUrl}/cities/search-by-zipcode`;

    const body = {
      q: inputValue
    };

    const response = await fetch(
      url,
      {
        body: JSON.stringify(body),
        credentials: "include",
        headers: new Headers({
          "content-type": "application/json"
        }),
        method: "POST"
      }
    );

    const data = await response.json();

    const {
      payload: {
        cities: citiesPayload
      }
    } = data;

    return citiesPayload.map(({
      id, name, zipcode
    }) => ({
      id,
      label: `${zipcode} ${name}`,
      zipcode
    }));
  };

  const loadStreetOptions = async (inputValue) => {
    const city_id = watch("city.id");

    const baseUrl = window?.settings?.REACT_APP_API_ENDPOINT;

    const url = `${baseUrl}/streets/search-street`;

    const body = {
      city_id,
      q: inputValue
    };

    const response = await fetch(
      url,
      {
        body: JSON.stringify(body),
        credentials: "include",
        headers: new Headers({
          "content-type": "application/json"
        }),
        method: "POST"
      }
    );

    const data = await response.json();

    const {
      payload: {
        streets: streetsPayload
      }
    } = data;

    return streetsPayload.filter(Boolean).map(({
      id, city: { name: cityName, zipcode }, name
    }) => ({
      id,
      label: `${name} (${zipcode} ${cityName})`
    }));
  };

  const currentAddress = formatClipboardText(watch());

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Adresse ${locationData ? "bearbeiten" : "erstellen"}`} />

          <ComboField
            async
            label="PLZ Filter"
            messages={{ queryEmpty: "Postleitzahl oder Ort eingeben..." }}
            name="city"
            options={loadCityOptions}
            {...{
              control,
              isEdit,
              resetField,
              setValue,
              watch
            }}
          />

          <ComboField
            async
            label="Straße *"
            name="street"
            options={loadStreetOptions}
            {...{
              control,
              isEdit,
              resetField,
              setValue,
              watch
            }}
          />

          <Field
            label="Hausnummer"
            name="housenumber"
            {...{
              errors,
              register
            }}
          />

          <Field
            label="Koordinaten *"
            name="coordinates"
            prefix={(
              <CopyToClipboard onCopy={handleToGoogleMaps} text={currentAddress}>
                <Button
                  className="justify-self-start"
                  icon={GoogleMapsIcon}
                  label="Maps"
                />
              </CopyToClipboard>
            )}
            {...{
              errors,
              register
            }}
          />

          <CheckboxField
            label="Hauptadresse"
            name="default_location"
            {...{
              errors,
              register
            }}
          />
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={formIsLoading} onClick={handleClose} />

            <FormSubmitButton isSubmitting={formIsLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default LocationSlide;
