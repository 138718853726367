import { formatEuro } from "~/src/modules/formatters.new";

import customActivity from "./custom-activity";

const priceActivity = (label) => customActivity({
  label,
  value: formatEuro
});

export default priceActivity;
