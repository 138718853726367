import Select from "~/src/ui/forms/select";

import options from "./options";

/**
 *
 * @param props0 - The root object
 * @param props0.defaultValue - The root object
 * @param props0.onChange - The root object
 * @example
 */
const StateSelect = ({ defaultValue, onChange }) => {
  let currentValue;

  if (defaultValue) {
    currentValue = Object
      .keys(options)
      .map((key) => options[key])
      .find((format) => format.value === defaultValue);
  }

  return (
    <Select
      isClearable
      defaultValue={null}
      onChange={onChange}
      options={options}
      placeholder="Bundesland"
      value={currentValue}
    />
  );
};

export default StateSelect;
