import API from "~/src/modules/api";
import { validDate } from "~/src/modules/formatters";

/**
 *
 * @param values
 * @example
 */
const generateCertificateObject = (values) => ({
  assigned_points: values.assigned_points,
  issuer_certificate_id: values.issuer_certificate?.id,
  issuer_id: values.issuer?.id,
  notes: values.notes,
  project_id: values.project_id
});

/**
 *
 * @param options0 - The root object
 * @param options0.data - The root object
 * @param options0.handleClose - The root object
 * @param options0.mutate - The root object
 * @param options0.projectId - The root object
 * @param options0.setIsLoading - The root object
 * @param options0.showError - The root object
 * @param options0.showSuccess - The root object
 * @param callback
 * @example
 */
const handleCreate = ({
  data, handleClose, mutate, projectId, setIsLoading, showError, showSuccess
}, callback) => {
  setIsLoading(true);

  const certificate = generateCertificateObject(data);

  certificate.project_id = projectId;
  API
    .post("/certificates/certificates", { certificate })
    .then((res) => {
      mutate();
      showSuccess();
      handleClose();
    }).catch((error) => {
      showError();
    })
    .finally(() => {
      setIsLoading(false);
    });
};

/**
 *
 * @param options0 - The root object
 * @param options0.certificateId - The root object
 * @param options0.data - The root object
 * @param options0.handleClose - The root object
 * @param options0.mutate - The root object
 * @param options0.projectId - The root object
 * @param options0.setIsLoading - The root object
 * @param options0.showError - The root object
 * @param options0.showSuccess - The root object
 * @param callback
 * @example
 */
const handleUpdate = ({
  certificateId, data, handleClose, mutate, projectId, setIsLoading, showError, showSuccess
}, callback) => {
  setIsLoading(true);

  const certificate = generateCertificateObject(data);

  certificate.project_id = projectId;

  API
    .patch(`/certificates/certificates/${certificateId}`, { certificate })
    .then((res) => {
      mutate();
      showSuccess();
      handleClose();
    }).catch((error) => {
      showError();
    })
    .finally(() => {
      setIsLoading(false);
    });
};

/**
 *
 * @param options0 - The root object
 * @param options0.certificateId - The root object
 * @param options0.hide - The root object
 * @param options0.mutate - The root object
 * @param options0.setIsSubmitting - The root object
 * @param options0.showError - The root object
 * @example
 */
const handleDelete = ({
  certificateId, hide, mutate, setIsSubmitting, showError
}) => {
  API.delete(`/certificates/certificates/${certificateId}`)
    .then((res) => {
      hide();
      mutate();
    })
    .catch((error) => {
      showError();
    })
    .finally(() => {
      setIsSubmitting(false);
    });
};

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
