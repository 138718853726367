import cn from "classnames";

import { input } from "./styles.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.label - The root object
 * @param props0.name - The root object
 * @param props0.prefix - The root object
 * @param props0.register - The root object
 * @example
 */
const CheckboxInput = ({
  autoFocus = false,
  className,
  disabled = false,
  errors,
  label,
  name,
  prefix,
  register,

  id = name
}) => {
  const showError = Boolean(errors[name]);

  return (
    <div className={className}>
      <div className="relative flex">
        <div className="flex h-5 items-center gap-2">
          {prefix}

          <input
            {...register(name)}
            {
              ...{
                id,
                autoFocus,
                disabled,
                type: "checkbox"
              }
            }
            className={cn(
              input,
              {
                "bg-gray-200 cursor-not-allowed": disabled,
                "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError,
                "border-red-500 focus:ring-red-700 focus:border-red-700": showError,
                "cursor-pointer": !disabled
              }
            )}
          />
        </div>
      </div>

      {showError ? <p className="col-span-3 mt-2 text-sm text-red-600">{errors[name].message}</p> : null}
    </div>
  );
};

export default CheckboxInput;
