import {
  hiddenActivity,
  listActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers";

export default {
  ...Object.fromEntries(
    ["id", "project_id"].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["hwb_from", "HWB"],
      ["hwb_to", "HWB bis"],
      ["hwb_class", "HWB Klasse"],
      ["fgee_from", "fGEE"],
      ["fgee_to", "fGEE bis"],
      ["fgee_class", "fGEE Klasse"],
      ["description", "Bereich/Objekt"]
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  gst_numbers: listActivity("GST-Nr.")
};
