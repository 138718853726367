import Handler from "~/src/modules/handler.js";

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/locations/:id",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { handleClose, mutate }) => {
    await mutate();
    handleClose();
  },
  transformCreateData: (data, { projectId }) => {
    const {
      coordinates, default_location, housenumber, street
    } = data;
    const [lat, lng] = coordinates.split(", ");

    return {
      location: {
        default_location,
        housenumber,
        lat,
        lng,
        project_id: projectId,
        street_id: street.id
      }
    };
  },
  transformUpdateData: (data, { projectId }) => {
    const {
      coordinates, default_location, housenumber, street
    } = data;
    const [lat, lng] = coordinates.split(", ");

    return {
      location: {
        default_location,
        housenumber,
        lat,
        lng,
        project_id: projectId,
        street_id: street.id
      }
    };
  }
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
