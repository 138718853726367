import Joi from "joi";

export const initialValues = {
  baurechtsdauer: "",
  baurechtsdauer_date: null,
  bauzins_gross: "",
  bauzins_net: "",
  br_stamm_ez: "",
  brez: "",
  buildArea: "",
  buyer: {
    id: "",
    name: "",
    reg_number: ""
  },
  buyerPrivate: false,
  ezNumbers: [""],
  greenArea: "",
  gstNumbers: [""],
  historic: false,
  katastralgemeinde: {
    id: "",
    bgCode: "",
    bgName: "",
    code: "",
    name: ""
  },
  kvId: "",
  kvUrl: "",
  notes: "",
  propertyArea: "",
  salePriceGross: "",
  salePriceNet: "",
  seller: {
    id: "",
    name: "",
    reg_number: ""
  },
  sellerPrivate: false,
  shares: "",
  sorting: "",
  transactionCategory: {
    id: "",
    code: "",
    name: ""
  },
  transactionDate: "",
  tzNumber: "",
  tzYear: "",
  usable: false,
  usableArea: ""
};

/**
 *
 * @param transaction
 * @example
 */
const generateEditValues = (transaction) => {
  if (transaction) {
    const {
      buyer, katastralgemeinde: kg, seller, transaction_category: tc
    } = transaction;

    return {
      baurechtsdauer: transaction.baurechtsdauer || "",
      baurechtsdauer_date: transaction.baurechtsdauer_date || null,
      bauzins_gross: transaction.bauzins_gross || "",
      bauzins_net: transaction.bauzins_net || "",
      br_stamm_ez: transaction.br_stamm_ez || "",
      brez: transaction.brez || "",
      buildArea: transaction.build_area || "",
      buyer: {
        id: buyer?.id || "",
        name: buyer?.name || "",
        reg_number: buyer?.reg_number || ""
      },
      buyerPrivate: transaction.buyer_private || false,
      ezNumbers: transaction.ez_numbers || [""],
      greenArea: transaction.green_area || "",
      gstNumbers: transaction.gst_numbers || [""],
      historic: transaction.historic || false,
      katastralgemeinde: {
        id: kg.id || "",
        bgCode: kg.bezirksgericht.code || "",
        bgName: kg.bezirksgericht.name || "",
        code: kg.code || "",
        name: kg.name || ""
      },
      kvId: transaction.kv_id || "",
      kvUrl: transaction.kv_url || "",
      notes: transaction.notes || "",
      propertyArea: transaction.property_area || "",
      salePriceGross: transaction.sale_price_gross || "",
      salePriceNet: transaction.sale_price_net || "",
      seller: {
        id: seller?.id || "",
        name: seller?.name || "",
        reg_number: seller?.reg_number || ""
      },
      sellerPrivate: transaction.seller_private || false,
      shares: transaction.shares || "",
      sorting: transaction.sorting || "",
      transactionCategory: {
        id: tc.id || "",
        code: tc.code || "",
        name: tc.name || ""
      },
      transactionDate: transaction.transaction_date || "",
      tzNumber: transaction.tz_number || "",
      tzYear: transaction.tz_year || "",
      usable: transaction.usable || false,
      usableArea: transaction.usable_area || ""
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  historic: Joi.boolean().required(),
  kvId: Joi.string().max(100).empty("")
    .optional(),
  kvUrl: Joi.string().uri().empty("")
    .optional(),
  sorting: Joi.number().empty("")
    .positive()
    .precision(4),

  transactionCategory: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    code: Joi.string().empty("").required(),
    name: Joi.string().empty("").required()
  }),
  transactionDate: Joi.date().empty("").required(),
  tzNumber: Joi.string().empty("").required(),
  tzYear: Joi.date().required(),
  usable: Joi.boolean().required(),

  ezNumbers: Joi
    .when("transactionCategory.code", {
      is: "baurecht",
      otherwise: Joi.array().min(1).items(Joi.string().min(1))
        .required(),
      then: Joi.array().sparse().items(Joi.string().empty(""))
    }),
  katastralgemeinde: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    bgCode: Joi.string().empty("").required(),
    bgName: Joi.string().empty("").required(),
    code: Joi.string().empty("").required(),
    name: Joi.string().empty("").required()
  }),

  buildArea: Joi.number().positive().empty(""),
  greenArea: Joi.number().positive().empty(""),
  gstNumbers: Joi.array().min(1).items(Joi.string().min(1))
    .required(),
  propertyArea: Joi.number().positive().empty("")
    .required(),
  usableArea: Joi.number().positive().empty(""),

  baurechtsdauer: Joi.string().empty("").pattern(/\d$/u),
  baurechtsdauer_date: Joi.date().empty(null),
  bauzins_gross: Joi.string().empty("").optional(),
  bauzins_net: Joi.string().empty("").optional(),
  br_stamm_ez: Joi.string().empty(""),
  brez: Joi.string().empty(""),

  salePriceGross: Joi.number().allow(0).empty("")
    .when("transactionCategory.code", {
      is: "baurecht",
      otherwise: Joi.optional(),
      then: Joi.optional()
    }),
  salePriceNet: Joi.number().positive().allow(0)
    .empty("")
    .when("transactionCategory.code", {
      is: "baurecht",
      otherwise: Joi.required(),
      then: Joi.optional()
    }),

  shares: Joi.string().empty("")
    .when("transactionCategory.code", {
      is: "baurecht",
      otherwise: Joi.required(),
      then: Joi.optional()
    }),

  buyerPrivate: Joi.boolean().required(),
  sellerPrivate: Joi.boolean().required(),

  buyer: Joi.when("buyerPrivate", {
    is: true,
    otherwise: Joi.object({
      id: Joi.number().positive().empty("")
        .required(),
      name: Joi.string().empty("").required(),
      reg_number: Joi.string().empty("").optional()
        .allow(null)
    }),
    then: Joi.optional()
  }),
  notes: Joi.string().empty(""),
  seller: Joi.when("sellerPrivate", {
    is: true,
    otherwise: Joi.object({
      id: Joi.number().positive().empty("")
        .required(),
      name: Joi.string().empty("").required(),
      reg_number: Joi.string().empty("").optional()
        .allow(null)
    }),
    then: Joi.optional()
  })
});
