import { select, label as styles_label } from "./style.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.filter - The root object
 * @param props0.label - The root object
 * @param props0.options - The root object
 * @param props0.setFilter - The root object
 * @param props0.setPage - The root object
 * @example
 */
const Filter = ({
  filter, label, options, setFilter, setPage
}) => {
  /**
   *
   * @param event
   * @example
   */
  function handleChange(event) {
    setPage(1);
    setFilter(event.target.value);
  }

  return (
    <div className="md:w-64">
      <label className={styles_label}>{label}</label>

      <select
        className={select}
        onChange={handleChange}
        value={filter}
      >
        {options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
      </select>
    </div>
  );
};

export default Filter;
