import SearchIcon from "~/src/ui/icons/search-icon";
import { useState } from "react";

/**
 *
 * @param props0 - The root object
 * @param props0.placeholder - The root object
 * @param props0.setName - The root object
 * @param props0.setPage - The root object
 * @example
 */
const CompaniesSearchForm = ({
  placeholder = "Name oder Firmenbuchnr.",
  setName,
  setPage
}) => {
  const [nameValue, setNameValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setName(nameValue);
    setPage(1);
  };

  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="my-12 w-1/2 justify-between md:flex">

        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon styles="h-4 w-4 text-gray-400" />
          </div>

          <input
            autoComplete="off"
            className="block w-full rounded border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder:text-gray-500 focus:border-gray-500 focus:text-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:placeholder:text-gray-400 sm:text-sm"
            name="name"
            onChange={handleNameChange}
            placeholder={placeholder}
            type="search"
          />
        </div>

        <div className="mt-6 md:ml-2 md:mt-0">
          <button className="inline-flex h-10 w-full items-center justify-center rounded border border-gray-600 bg-gray-600 px-12 font-medium text-white hover:bg-gray-700 focus:bg-gray-700 focus:outline-none md:h-full" type="submit">
            Suchen
          </button>
        </div>

      </div>
    </form>
  );
};

export default CompaniesSearchForm;
