import { setValuesOnShowForm } from "./modal/controller.js";
import { generateEditValues } from "./schema.js";

const onClickShowModal = ({
  issuer, issuersTempFrontEndValidation: issuersTemporaryFrontEndValidation = [], modal, reactForm, readOnly
}) => {
  console.log("on-click-show", {
    issuer,
    issuersTempFrontEndValidation: issuersTemporaryFrontEndValidation,
    readOnly
  });
  setValuesOnShowForm({
    issuer,
    reactForm
  });
  const issuerWithDefaults = generateEditValues(issuer);

  modal.show({
    id: issuer?.id,
    issuersTempFrontEndValidation: issuersTemporaryFrontEndValidation,
    readOnly
  });
};

export { onClickShowModal };
