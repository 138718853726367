/* eslint-disable max-lines-per-function */
import useForm from "~/src/hooks/use-form";
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import { formatEuro } from "~/src/modules/formatters.new";
import SlideFormFooter from "~/src/ui/footers/slide-form-footer";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox";
import InputArea from "~/src/ui/forms/input-area";
import NumberInput from "~/src/ui/forms/number-input";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import SlideOver from "~/src/ui/slides/container";
import { sum } from "lodash-es";
import { useState } from "react";

import { handleUpdate } from "./handlers";
import { generateEditValues, schema } from "./schema";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.project - The root object
 * @param props0.projectId - The root object
 * @param props0.visible - The root object
 * @example
 */
const TransactionSettingsSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const initialValues = generateEditValues(project);

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    context: { transactions: project?.transactions },
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: project?.id,
        data: form.values,
        form,
        hide: () => {
          handleHide(form);
        },
        mutate,
        showError
      });
    },
    schema
  });

  const [currentAreaValue, setCurrentAreaValue] = useState(0);

  const handleChange = (event) => {
    const { target: { value } } = event;

    setCurrentAreaValue(Number(value));

    form.handleChange(event);
  };

  const transactionPrices = project?.transactions
    .filter(({
      deleted_at,
      sale_price_net,
      usable
    }) => sale_price_net && usable && !deleted_at)
    .map(({ sale_price_net }) => Number.parseFloat(sale_price_net));

  const transactionTotal = sum(transactionPrices);

  const gka = transactionTotal / currentAreaValue;

  return (
    <SlideOver hide={() => handleHide(form)} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">

          <SlideFormHeader closeDisabled={form.isLoading} handleHide={() => handleHide(form)} title="Einstellungen bearbeiten" />

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="items-center space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="sm:col-span-1">
                <InlineCheckbox
                  enableError
                  error={form.errors.show_gka}
                  id="show_gka"
                  isSubmitting={form.isSubmitting}
                  label="Grundkostenanteil anzeigen"
                  name="show_gka"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.touched.show_gka}
                  value={form.values.show_gka}
                />
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="gka_notes">
                  Bemerkungen
                </label>
              </div>

              <div className="sm:col-span-2">
                <InputArea
                  error={form.errors.gka_notes}
                  id="gka_notes"
                  isSubmitting={form.isSubmitting}
                  name="gka_notes"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Bemerkungen"
                  touched={form.touched.gka_notes}
                  value={form.values.gka_notes}
                />
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="gka_area">
                  Gesamtnutzfläche Wohneinheiten
                </label>
              </div>

              <div className="flex w-full items-center gap-4 sm:col-span-2">
                <NumberInput
                  area
                  error={form.errors.gka_area}
                  id="gka_area"
                  isSubmitting={form.isSubmitting}
                  name="gka_area"
                  onBlur={form.handleBlur}
                  onChange={handleChange}
                  placeholder="Gesamtnutzfläche Wohneinheiten"
                  touched={form.touched.gka_area}
                  value={form.values.gka_area}
                />

                <span className="flex size-full items-center justify-end rounded-sm bg-gray-700 px-3 text-white shadow-sm focus:outline-none">
                  {Number.isFinite(gka) ? `${formatEuro(gka)}  / m²` : " - "}
                </span>
              </div>
            </div>
          </div>

          <SlideFormFooter form={form} handleHide={() => handleHide(form)} />

        </div>
      </form>
    </SlideOver>
  );
};

export default TransactionSettingsSlide;
