import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @example
 */
const useActiveUserAutomatedProjects = () => {
  const {
    data, error, mutate
  } = useSWR("/automated-projects/active-user-projects", fetcher);

  return {
    automated_projects: data?.payload?.automated_projects,
    isError: error,
    isLoading: !error && !data,
    mutate
  };
};

export default useActiveUserAutomatedProjects;
