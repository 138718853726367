import { formatArea } from "~/src/modules/formatters";

/**
 *
 * @param props0 - The root object
 * @param props0.area - The root object
 * @param props0.toArea - The root object
 * @example
 */
const ProjectInfosTotalArea = ({ area, toArea }) => {
  if (area && toArea) {
    return <p>{`${formatArea(area)}-${formatArea(toArea)}`}</p>;
  }

  if (area) {
    return <p>{`${formatArea(area)}`}</p>;
  }

  return <p>-</p>;
};

export default ProjectInfosTotalArea;
