import {
  customActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers";
import { companyRoleLabels } from "~/src/modules/labels";

export default {
  ...Object.fromEntries(
    [
      "created_at",
      "deleted_at",
      "updated_at",
      "id",
      "project_id",
      "company_id",
      "company_slug"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["company", "Name"],
      ["company_reg_number", "FB- oder ZVR-Nr."],
      ["private", "Privat"]
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  name: customActivity({
    label: "Rolle",
    value: (value) => companyRoleLabels.get(value)
  })
};
