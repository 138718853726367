import { formatDistance } from "~/src/modules/formatters.new";

import customActivity from "./custom-activity";

const distanceActivity = (label) => customActivity({
  label,
  value: formatDistance
});

export default distanceActivity;
