import { dateFormatLabels } from "~/src/modules/labels";

import customActivity from "./custom-activity";

const dateFormatActivity = (label) => customActivity({
  label,
  value: (value) => dateFormatLabels.get(value)
});

export default dateFormatActivity;
