.input {
  @apply mt-1 resize-none shadow-sm block w-full focus:ring-gray-700 focus:border-gray-700 sm:text-sm border-gray-300 rounded-sm placeholder-gray-300;
}

.inputReadonly {
  @apply mt-1 resize-none shadow-none block w-full border-none rounded-sm placeholder-gray-300 px-0;
}

.errorIcon {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
}