import Select from "~/src/ui/forms/select";

import options from "./options";

/**
 *
 * @param properties
 * @example
 */
const ReconstructionSelect = (properties) => {
  let defaultValue = options[0];

  if (properties.defaultValue) {
    defaultValue = Object
      .keys(options)
      .map((key) => options[key])
      .find((format) => format.value === properties.defaultValue.value);
  }

  return (
    <Select
      options={options}
      placeholder="Neubau/Sanierung"
      {...properties}
      defaultValue={defaultValue}
    />
  );
};

export default ReconstructionSelect;
