/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
const Content = ({ children }) => (
  <main className="mx-auto max-w-7xl sm:px-4 lg:px-8">
    {children}
  </main>
);

export default Content;
