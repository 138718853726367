import {
  customActivity,
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers";

export default {
  ...Object.fromEntries(
    [
      "id",
      "project_id",
      "city_id",
      "street_id",
      "coordinates",
      "city_gemeindekennziffer",
      "city_state_id",
      "city_state_code",
      "city_state_state_number",
      "street_streetcode",
      "street_city_id"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["housenumber", "Hausnummer"],
      ["default_location", "Hauptadresse"],
      ["lat", "Koordinaten - Breitengrad"],
      ["lng", "Koordinaten - Längengrad"],
      ["city", "Stadt"],
      ["city_zipcode", "PLZ"],
      ["city_state", "Bundesland"],
      ["street", "Straße"]
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  old_default_location: customActivity({
    label: "Alte Hauptadresse",
    value: (data) => {
      if (data) {
        const {
          city, city_state_code, city_zipcode, housenumber, street
        } = data;

        return `${street}${housenumber ? ` ${housenumber}` : ""}, ${city_zipcode} ${city}, ${city_state_code.toLocaleUpperCase()}`;
      }

      return "";
    }
  })
};
