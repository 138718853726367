import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon, ChevronDownIcon, XIcon
} from "@heroicons/react/solid";
import FeedList from "~/src/features/feed-list";
import useFeed from "~/src/hooks/use-feed";
import MainContainer from "~/src/ui/containers/main-container";
import PageHeader from "~/src/ui/headers/page-header";
import Page from "~/src/ui/page";
import cn from "classnames";
import { Fragment, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

/**
 *
 * @example
 */
const FeedPage = () => {
  const [stateCode, setStateCode] = useQueryParam("state", StringParam);

  const {
    isError, isLoading, projects
  } = useFeed({ state: stateCode });

  if (isError) {
    return null;
  }

  const stateOptions = [
    {
      label: "Wien",
      value: "vie"
    },
    {
      label: "Niederösterreich",
      value: "noe"
    },
    {
      label: "Burgenland",
      value: "bgl"
    },
    {
      label: "Kärnten",
      value: "kae"
    },
    {
      label: "Oberösterreich",
      value: "ooe"
    },
    {
      label: "Salzburg",
      value: "sbg"
    },
    {
      label: "Steiermark",
      value: "stk"
    },
    {
      label: "Tirol",
      value: "tir"
    },
    {
      label: "Vorarlberg",
      value: "vbg"
    }
  ];

  return (
    <Page title="KV-Feed">
      <PageHeader>
        <div className="justify-between sm:flex">
          <div>
            <h2 className="text-2xl font-bold leading-7 text-gray-800 sm:truncate sm:text-3xl">
              Feed
            </h2>
          </div>

          <Listbox as="div" className="relative w-56 text-sm" onChange={setStateCode} value={stateCode}>
            <Listbox.Button className="relative flex w-full items-center justify-between rounded border border-gray-300 py-2 pl-3 pr-8 hover:border-gray-400 focus:border-gray-500">
              <span className="truncate text-gray-600">{stateCode ? stateOptions.find(({ value }) => value === stateCode).label : <span className="text-gray-400">Bundesland</span>}</span>

              <ChevronDownIcon className="absolute right-0 h-4 w-8 border-l border-gray-600 text-gray-600" />

              {stateCode
                ? (
                  <XIcon
                    className="absolute right-8 h-4 w-8 text-gray-600"
                    onClick={(e) => {
                      e.preventDefault(); setStateCode(undefined);
                    }}
                  />
                )
                : null}
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options as="div" className="absolute z-10 mt-1 flex max-h-60 w-full cursor-pointer flex-col overflow-hidden rounded border border-gray-300 bg-white shadow-lg">
                <ul className="flex flex-col overflow-auto">
                  {stateOptions.map(({ label, value }) => (
                    <Listbox.Option as={Fragment} key={value} value={value}>
                      {
                        ({ active, selected }) => (
                          <li
                            className={cn("flex relative px-3 py-2", {
                              "bg-gray-600 text-white": active,
                              "bg-white text-gray-600": !selected && !active,
                              "font-base": !selected,
                              "font-medium": selected && active,
                              "font-medium bg-gray-400 text-white": selected && !active
                            })}
                          >
                            <span className="">{label}</span>
                          </li>
                        )
                      }
                    </Listbox.Option>
                  ))}
                </ul>
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>
      </PageHeader>

      <MainContainer>
        <FeedList isLoading={isLoading} projects={projects} />
      </MainContainer>

    </Page>
  );
};

export default FeedPage;
