import ModalCancelButton from "~/src/ui/buttons/modal-cancel-button";
import ModalCloseButton from "~/src/ui/buttons/modal-close-button";
import ModalSubmitButton from "~/src/ui/buttons/modal-submit-button";
import AlertIcon from "~/src/ui/icons/alert-icon";
import Modal from "~/src/ui/modals";
import ProjectUnitBulkEdit from "~/src/ui/project-unit-bulk-edit";
import { camelCase } from "lodash-es";
import { useState } from "react";

import { handleBulkUpdate } from "./handlers";

/**
 *
 * @param props0 - The root object
 * @param props0.changedKeys - The root object
 * @param props0.data - The root object
 * @param props0.hide - The root object
 * @param props0.presetKeys - The root object
 * @param props0.resetSelection - The root object
 * @param props0.setPage - The root object
 * @param props0.show - The root object
 * @param props0.units - The root object
 * @param props0.watchData - The root object
 * @example
 */
const EditBulkModal = ({
  changedKeys, data, hide, presetKeys, resetSelection, setPage, show, units, watchData
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const previewUnits = (units)
    ? units.map((unit) => {
      const previewUnit = { ...unit };

      if (changedKeys.length > 0) {
        for (const key in unit) {
          if (changedKeys.includes(camelCase(key))) {
            previewUnit[key] = watchData.formValues[camelCase(key)];
          }
        }
      }

      return previewUnit;
    })
    : undefined;

  /**
   *
   * @example
   */
  function handleSubmit() {
    setIsSubmitting(true);
    const {
      formValues, handleClose, mutate, offerDataAvailable, projectIds, setIsLoading, showError, showSuccess
    } = data;

    handleBulkUpdate({
      changedKeys,
      formValues,
      handleClose,
      hide,
      mutate,
      projectIds,
      resetSelection,
      setIsLoading,
      setIsSubmitting,
      setPage,
      showError
    });
  }

  return (
    <Modal hide={hide} show={show}>
      <div className="inline-block overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 sm:align-middle">

        <ModalCloseButton hide={hide} />

        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
            <AlertIcon />
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <ProjectUnitBulkEdit units={previewUnits} />
          </div>
        </div>

        <div className="mt-5 gap-x-2 sm:mt-4 sm:flex sm:flex-row-reverse">
          <ModalSubmitButton color="bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-600" isSubmitting={isSubmitting} onClick={handleSubmit}>
            Speichern
          </ModalSubmitButton>

          <ModalCancelButton hide={hide} />
        </div>

      </div>
    </Modal>
  );
};

export default EditBulkModal;
