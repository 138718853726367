import { validDate } from "~/src/modules/formatters";
import Handler from "~/src/modules/handler.js";

/**
 * Generates a Widmung object from form values.
 *
 * @param values - Form values.
 * @returns {object} - Widmung object.
 * @example
 */
const generateWidmungObject = (values) => ({
  additional: values.additional || null,
  bauklasse: values.bauklasse || null,
  bauweise: values.bauweise || null,
  height_limit: values.height_limit || null,
  height_limit_sign: values.height_limit && values.height_limit_sign === "plus" ? true : null,
  height_limit_to: values.height_limit_range ? values.height_limit_to : null,
  plan_number: values.plan_number,
  publish_date: validDate(values.publish_date) || null,
  widmung: values.widmung || null
});

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/widmungen/:id",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (
    responseData,
    {
      handleClose, mutate, showSuccess
    }
  ) => {
    await mutate();
    showSuccess();
    handleClose();
  },
  transformCreateData: (data, { projectId }) => {
    const widmung = generateWidmungObject(data);

    widmung.project = { id: projectId };

    return { widmung };
  },
  transformUpdateData: (data) => {
    const widmung = generateWidmungObject(data);

    return { widmung };
  }
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
