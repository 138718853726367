import AddPublicName from "./add-public-name";

/**
 *
 * @param props0 - The root object
 * @param props0.handleClick - The root object
 * @example
 */
const ProjectDocumentSuggestions = ({ handleClick }) => (
  <>
    <h4 className="text-sm font-medium text-gray-700">Vorschläge:</h4>

    <ul className="mt-1 list-disc pl-8 text-sm">
      <AddPublicName onClick={handleClick} value="Flächenwidmung" />

      <AddPublicName onClick={handleClick} value="Energieausweis" />

      <AddPublicName onClick={handleClick} value="Grundstücke" />

      <AddPublicName onClick={handleClick} value="Exposé" />

      <AddPublicName onClick={handleClick} value="Preisliste" />

      <AddPublicName onClick={handleClick} value="Preisliste (Eigennutzer)" />

      <AddPublicName onClick={handleClick} value="Preisliste (Anleger)" />

      <AddPublicName onClick={handleClick} value="Bau- und Ausstattungsbeschreibung" />

      <AddPublicName onClick={handleClick} value="Projektfolder" />

      <AddPublicName onClick={handleClick} value="Booklet" />

      <AddPublicName onClick={handleClick} value="Projektbeschreibung" />
    </ul>
  </>
);

export default ProjectDocumentSuggestions;
