import Joi from "joi";

const path = {
  issuer_certificates: "certificates/issuer_certificates",
  issuers: "/certificates/issuers"
};

// todo: this can be deduced from a schema by field type

/**
 *
 * @example
 */
const getInitialValues = () => ({
  id: "",
  max_points: null,
  has_certificates: false,
  has_points: false,
  label: "",
  name: "",
  searchable: false
});

/**
 *
 * @example
 */
const getIssuerCertificateInitialValues = () => ({
  id: "",
  fromDb: false,
  issuer_id: "",
  label: "",
  name: "",
  rank: null
});

/**
 *
 * @param issuer
 * @example
 */
const generateEditValues = (issuer = null) => {
  if (!issuer) {
    return getInitialValues();
  }

  return {
    ...issuer,
    label: issuer.name
  };
};

const emptyOrNull = Joi.any().valid(null, "");

const schema = Joi.object({
  id: Joi.number().positive().empty(""),
  max_points: Joi.number().positive().min(1)
    .max(10_000)
    .empty(emptyOrNull),
  has_certificates: Joi.boolean(),
  has_points: Joi.boolean().default(false),
  internal_notes: Joi.string().empty("").allow(null)
    .max(500)
    .default(null),
  label: Joi.string().empty(""),
  name: Joi.string().empty("").required()
    .trim(),
  searchable: Joi.boolean().default(false)
})
  .with(
    "has_points",
    "max_points",
    { isPresent: (resolved) => ![false, undefined].includes(resolved) }
  );

const issuerCertificateSchema = Joi.object({
  id: Joi.number().positive().empty(""),
  fromDb: Joi.boolean().empty("").allow(null),
  issuer_id: Joi.number().positive().empty(""),
  label: Joi.string().empty(""),
  name: Joi.string().empty(""),
  rank: Joi.number().min(1).empty("")
    .allow(null)
});

const expandedSchema = schema.append({
  issuerCertificates: Joi.array().items(issuerCertificateSchema)
});

/**
 *
 * @example
 */
const getInitialValuesForExpandedSchema = () => ({
  ...getInitialValues(),
  issuerCertificates: []
});

export {
  expandedSchema,
  generateEditValues,
  getInitialValues,
  getInitialValuesForExpandedSchema,
  getIssuerCertificateInitialValues,
  path,
  schema
};
