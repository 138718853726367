import useAuth from "~/src/hooks/use-auth";
import useAutomatedProject from "~/src/hooks/use-automated-project";
import useProject from "~/src/hooks/use-project";
import API from "~/src/modules/api";
import LockButton from "~/src/ui/badges/lock-button";
import { SWRConfig } from "swr";

/**
 *
 * @param props0 - The root object
 * @param props0.automated - The root object
 * @param props0.onlyUnit - The root object
 * @param props0.projectId - The root object
 * @param props0.small - The root object
 * @example
 */
const EditProjectEditors = ({
  automated = false, onlyUnit = false, projectId, small = false
}) => {
  const {
    isMod, mutate: mutateUser, user
  } = useAuth();

  const activeEditorKey = automated ? "active_editor" : "active_project_editor";

  let { mutate, project } = {};

  if (automated) {
    ({ automated_project: project, mutate } = useAutomatedProject(projectId));
  }
  else {
    ({ mutate, project } = useProject(projectId));
  }

  const projectLocked = Boolean(project?.[activeEditorKey]);
  const unitsLocked = Boolean(project?.active_unit_editor);
  const isProjectEditor = project?.[activeEditorKey] && project[activeEditorKey].email === user.email;
  const isUnitEditor = project?.active_unit_editor && project.active_unit_editor.email === user.email;
  const numberOfActiveUnitProjects = user?.activeUnitProjects?.length ?? 0;
  const reachedLimit = numberOfActiveUnitProjects >= 3;

  /**
   *
   * @param type
   * @example
   */
  async function handleLock(type) {
    if (type === "project") {
      lockProject(!projectLocked, "project");
    }
    else {
      lockProject(!unitsLocked, "units");
    }
  }

  /**
   *
   * @param lock
   * @param editType
   * @example
   */
  async function lockProject(lock, editType) {
    const route = automated ? "automated-projects" : "projects";

    try {
      await API
        .post(`/${route}/${projectId}/${lock ? "lock" : "unlock"}`, {}, {
          params: {
            editType
          }
        });

      await mutate();
      await mutateUser();
    }
    catch (error) {
      console.log(error);
    }
  };

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: true
      }}
    >
      <div className="space-y-3">

        {
          !onlyUnit && (
            <div className="flex items-center justify-end">

              {isProjectEditor ? <p className="mr-3 text-xs">in eigener Bearbeitung</p> : null}

              {projectLocked && !isProjectEditor ? <p className="mr-3 text-xs">{project?.[activeEditorKey]?.username}</p> : null}

              <LockButton handleClick={() => handleLock("project")} isEditor={isProjectEditor} isLocked={projectLocked} isMod={isMod} label="Projekterfassung" />
            </div>
          )
        }

        {
          !automated && (
            <div className="flex items-center justify-end">

              {(isUnitEditor && !small) ? <p className="mr-3 text-xs">in eigener Bearbeitung</p> : null}

              {unitsLocked && !isUnitEditor ? <p className="mr-3 text-xs">{project?.active_unit_editor?.username}</p> : null}

              <LockButton handleClick={() => handleLock("units")} isEditor={isUnitEditor} isLocked={unitsLocked} isMod={isMod} label="Objekterfassung" reachedLimit={reachedLimit ? !isUnitEditor : null} small={small} />
            </div>
          )
        }

      </div>
    </SWRConfig>
  );
};

export default EditProjectEditors;
