import { useEffect, useState } from "react";

/**
 *
 * @param targetKey
 * @param callback
 * @param enabled
 * @example
 */
const useKeyPress = (targetKey, callback, enabled = true) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    if (keyPressed) {
      callback();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyPressed]);

  useEffect(() => {
    // If pressed key is our target key then set to true

    /**
     *
     * @param options0 - The root object
     * @param options0.key - The root object
     * @example
     */
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }

    // If released key is our target key then set to false

    /**
     *
     * @param options0 - The root object
     * @param options0.key - The root object
     * @example
     */
    function upHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    }

    if (enabled) {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
    }

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return keyPressed;
};

export default useKeyPress;
