/* eslint-disable max-lines-per-function */
import useForm from "~/src/hooks/use-form";
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox";
import NumberInput from "~/src/ui/forms/number-input";
import CloseIcon from "~/src/ui/icons/close-icon";
import UnitCategorySelect from "~/src/ui/selects/unit-category-select";
import Slide from "~/src/ui/slides/container";

import { handleCreate } from "./handlers";
import { initialValues, schema } from "./schema";

const NewQuantitySlide = ({
  hide, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleCreate({
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        projectId,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <Slide hide={() => handleHide(form)} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">

          <div className="bg-gray-800 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2 className="text-lg font-medium text-white">
                  Einheiten bearbeiten
                </h2>
              </div>

              <div className="flex h-7 items-center">
                <button className="rounded-md text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500" onClick={() => handleHide(form)} type="button">
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="unitCategory">
                  Objektkategorie
                </label>
              </div>

              <div className="sm:col-span-2">
                <UnitCategorySelect
                  defaultValue={form.values.unit_category}
                  error={form.errors.unit_category}
                  isSubmitting={form.isSubmitting}
                  onChange={form.handleChange}
                  touched={form.touched.unit_category}
                />
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="total_units">
                  Anzahl Einheiten (Gesamt)
                </label>
              </div>

              <div className="sm:col-span-2">
                <NumberInput
                  int
                  error={form.errors.total_units}
                  id="total_units"
                  isSubmitting={form.isSubmitting}
                  name="total_units"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Anzahl Einheiten"
                  touched={form.touched.total_units}
                  value={form.values.total_units}
                />
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="total_area_range_from">
                  m² Fläche Einheiten (Gesamt)
                </label>
              </div>

              <div className="sm:col-span-1">
                <NumberInput
                  area
                  error={form.errors.total_area_range_from}
                  id="total_area_range_from"
                  isSubmitting={form.isSubmitting}
                  name="total_area_range_from"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Fläche"
                  touched={form.touched.total_area_range_from}
                  value={form.values.total_area_range_from}
                />
              </div>

              <div className="sm:col-span-1">
                <InlineCheckbox
                  error={form.errors.show_total_area}
                  id="area_total_range"
                  isSubmitting={form.isSubmitting}
                  label="von-bis"
                  name="show_total_area"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  touched={form.touched.show_total_area}
                  value={form.values.show_total_area}
                />
              </div>
            </div>
          </div>

          {form.values.show_total_area
            ? (
              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="total_area_range_to">
                      m² Fläche bis
                    </label>
                  </div>

                  <div className="sm:col-span-1">
                    <NumberInput
                      area
                      error={form.errors.total_area_range_to}
                      id="total_area_range_to"
                      isSubmitting={form.isSubmitting}
                      name="total_area_range_to"
                      onBlur={form.handleBlur}
                      onChange={form.handleChange}
                      placeholder="Fläche bis"
                      touched={form.touched.total_area_range_to}
                      value={form.values.total_area_range_to}
                    />
                  </div>
                </div>
              </div>
            )
            : null}

        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <button className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => handleHide(form)} type="button">
              Abbrechen
            </button>

            <FormSubmitButton isSubmitting={form.isLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>

      </form>
    </Slide>
  );
};

export default NewQuantitySlide;
