import Select from "~/src/ui/forms/select";

import roleOptions from "./options";

/**
 *
 * @param properties
 * @example
 */
const RoleSelect = (properties) => (
  <Select
    options={roleOptions}
    placeholder="Rolle"
    {...properties}
  />
);

export default RoleSelect;
