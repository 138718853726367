import { selectByKeys } from "~/src/_common/_exports.js";
import {
  getIssuerCertificateInitialValues,
  getInitialValues as getIssuerInitialValues,
  schema as issuerSchema
} from "~/src/features/certificates/issuers/schema.js";
import Joi from "joi";

const projectCertificateColumnsForForm = [
  "id",
  "assigned_points",
  "notes",
  "issuer",
  "issuer_certificate"
];

const issuerColumnsForForm = [
  "id",
  "name",
  "has_certificates",
  "has_points",
  "max_points",
  "searchable"
];

const issuerCertificateColumnsForForm = [
  "id",
  "issuer_id",
  "name",
  "rank",
  "fromDb"
];

export const initialValuesForForm = {
  assigned_points: "",
  issuer: getIssuerInitialValues(),
  issuer_certificate: getIssuerCertificateInitialValues(),
  notes: ""
};

export const generateEditValues = (certificate = null) => {
  if (!certificate?.id) {
    return JSON.parse(JSON.stringify(initialValuesForForm));
  }

  return createProjectCertificateForForm(
    JSON.parse(JSON.stringify(certificate))
  );
};

const createProjectCertificateForForm = (data) => {
  const certificate = {
    label: data.label,
    ...selectByKeys(data, projectCertificateColumnsForForm)
  };

  certificate.issuer = createIssuerOption(certificate?.issuer);
  certificate.issuer_certificate = createIssuerCertificateOption(
    certificate.issuer_certificate
  );

  return certificate;
};

export const createIssuerCertificateOption = (data) => ({
  label: data?.name,
  ...selectByKeys(data, issuerCertificateColumnsForForm)
});

export const createIssuerOption = (data) => ({
  label: data?.name,
  ...selectByKeys(data, issuerColumnsForForm)
});

const schema = Joi.object({
  id: Joi.number().positive().empty("")
    .allow(null),
  issuer: issuerSchema,
  issuer_certificate: Joi.object({
    id: Joi.number().positive().empty(""),
    fromDb: Joi.boolean().empty("").allow(null),
    issuer_id: Joi.number().positive().empty(""),
    label: Joi.string().empty(""),
    name: Joi.string().empty(""),
    rank: Joi.number().min(1).empty("")
      .allow(null)
  }),
  //TODO validate issuer.has_points
  assigned_points: Joi.number()
    .max(Joi.ref("issuer.max_points")).min(1)
    .empty("")
    .allow(null),
  notes: Joi.string().empty("").max(500)
});

export default schema;

