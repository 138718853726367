import Joi from "joi";

export const initialValues = {
  fileName: "",
  imageLandscape: true,
  showPublic: true
};

/**
 *
 * @param document
 * @example
 */
const generateEditValues = (document) => ({
  fileName: document?.file_name || "",
  imageLandscape: document?.image_landscape === true,
  showPublic: document?.show_public === true
});

export { generateEditValues };

export const schema = Joi.object({
  fileName: Joi.string().empty("").required(),
  imageLandscape: Joi.boolean().required(),
  showPublic: Joi.boolean().required()
});
