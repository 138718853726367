import DeleteUserModal from "~/src/features/modals/delete-user-modal";
import EditUserModal from "~/src/features/modals/edit-user-modal";
import NewUserModal from "~/src/features/modals/new-user-modal";
import UserList from "~/src/features/user-list";
import useModal from "~/src/hooks/use-modal";
import useUsers from "~/src/hooks/use-users";
import MainContainer from "~/src/ui/containers/main-container";
import PageHeader from "~/src/ui/headers/page-header";
import Page from "~/src/ui/page";

/**
 *
 * @example
 */
const UsersPage = () => {
  const { isError, mutate } = useUsers();

  const {
    hide: hideNewModal, show: showNewModal, visible: newModalVisible
  } = useModal();
  const {
    data: editData, hide: hideEditModal, show: showEditModal, visible: editModalVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDeleteModal, show: showDeleteModal, visible: deleteModalVisible
  } = useModal();

  if (isError) {
    return null;
  }

  return (
    <Page title="Benutzerverwaltung">

      <NewUserModal hide={hideNewModal} mutateUsers={mutate} visible={newModalVisible} />

      <EditUserModal hide={hideEditModal} mutateUsers={mutate} user={editData} visible={editModalVisible} />

      <DeleteUserModal hide={hideDeleteModal} user={deleteData} visible={deleteModalVisible} />

      <PageHeader>
        <h2 className="text-2xl font-bold leading-7 text-gray-800 sm:truncate sm:text-3xl">
          Userliste
        </h2>

        <button className="mt-6 block text-sm underline focus:outline-none" onClick={() => showNewModal()} type="button">User hinzufügen</button>
      </PageHeader>

      <MainContainer>
        <UserList showDeleteModal={showDeleteModal} showEditModal={showEditModal} />
      </MainContainer>

    </Page>
  );
};

export default UsersPage;
