/* eslint-disable max-lines-per-function */
import useForm from "~/src/hooks/use-form";
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import SlideFormFooter from "~/src/ui/footers/slide-form-footer";
import DateInput from "~/src/ui/forms/date-input";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import DateFormatSelect from "~/src/ui/selects/date-format-select";
import ReconstructionSelect from "~/src/ui/selects/reconstruction-select";
import SlideOver from "~/src/ui/slides/container";

import { handleUpdate } from "./handlers";
import { generateEditValues, schema } from "./schema";

const ConstructionInfosSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const initialValues = generateEditValues(project);

  const handleHide = (innerForm) => {
    hide();
    innerForm.resetForm();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: project?.construction_infos?.id,
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <SlideOver hide={() => handleHide(form)} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={form.isLoading} handleHide={() => handleHide(form)} title="Bauinformationen bearbeiten" />

          <div className="m-4 space-y-6 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="construction_start_date">
                  Geplanter Baubeginn
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.construction_start_date}
                  id="construction_start_date"
                  isSubmitting={form.isSubmitting}
                  name="construction_start_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Geplanter Baubeginn"
                  touched={form.touched.construction_start_date}
                  value={form.values.construction_start_date ? new Date(form.values.construction_start_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.construction_start_date_format}
                  error={form.errors.construction_start_date_format}
                  isSubmitting={form.isSubmitting}
                  name="construction_start_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.construction_start_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="estimated_finish_date">
                  Geplante Fertigstellung
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.estimated_finish_date}
                  id="estimated_finish_date"
                  isSubmitting={form.isSubmitting}
                  name="estimated_finish_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Geplante Fertigstellung"
                  touched={form.touched.estimated_finish_date}
                  value={form.values.estimated_finish_date ? new Date(form.values.estimated_finish_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.estimated_finish_date_format}
                  error={form.errors.estimated_finish_date_format}
                  isSubmitting={form.isSubmitting}
                  name="estimated_finish_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.estimated_finish_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="construction_year">
                  Baubewilligung
                </label>
              </div>

              <div className="sm:col-span-2">
                <DateInput
                  error={form.errors.construction_year}
                  format="year"
                  id="construction_year"
                  isSubmitting={form.isSubmitting}
                  name="construction_year"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Baubewilligung"
                  touched={form.touched.construction_year}
                  value={form.values.construction_year ? new Date(form.values.construction_year) : null}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="is_new">
                  Auswahl Neubau/Sanierung
                </label>
              </div>

              <div className="sm:col-span-2">
                <ReconstructionSelect
                  defaultValue={form.values.is_new}
                  error={form.errors.is_new}
                  isSubmitting={form.isSubmitting}
                  name="is_new"
                  onChange={form.handleChange}
                  touched={form.touched.is_new}
                />
              </div>
            </div>
          </div>

          {form.values.is_new?.value === false && (
            <div className="m-4 space-y-6 py-6 sm:space-y-0 sm:py-0">
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="reconstruction_year">
                    Sanierungsjahr
                  </label>
                </div>

                <div className="sm:col-span-2">
                  <DateInput
                    error={form.errors.reconstruction_year}
                    format="year"
                    id="reconstruction_year"
                    isSubmitting={form.isSubmitting}
                    name="reconstruction_year"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    placeholder="Sanierungsjahr"
                    touched={form.touched.reconstruction_year}
                    value={form.values.reconstruction_year ? new Date(form.values.reconstruction_year) : null}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <SlideFormFooter form={form} handleHide={() => handleHide(form)} />
      </form>
    </SlideOver>
  );
};

export default ConstructionInfosSlide;
