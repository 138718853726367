import ProjectNotes from "~/src/features/project-overview/project-notes";
import ProjectPublishOverview from "~/src/features/project-overview/project-publish-overview";
import ProjectResearchState from "~/src/features/project-overview/project-research-state";
import ProjectReview from "~/src/features/project-overview/project-review";
import UnitNotes from "~/src/features/project-overview/project-unit-notes";
import ResearchNotes from "~/src/features/project-overview/research-notes";
import useProject from "~/src/hooks/use-project";
import TabHeader from "~/src/ui/headers/tab-header";
import Skeleton from "~/src/ui/loading/item-skeleton";
import { useParams } from "react-router-dom";
// import ProjectTrackingOverview from "~/src/features/project-overview/project-tracking-overview"

/**
 *
 * @example
 */
const OverviewPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return <Skeleton />;
  }

  return (
    <>
      <TabHeader>Übersicht</TabHeader>

      <ul className="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

        <ProjectPublishOverview />

        {/* <ProjectTrackingOverview /> */}

      </ul>

      <div className="mt-12 divide-y divide-gray-200">
        <ProjectResearchState />

        <ProjectReview />

        <ProjectNotes />

        <UnitNotes />

        <ResearchNotes />
      </div>
    </>
  );
};

export default OverviewPage;
