/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @example
 */
const DeleteIcon = ({ className }) => (
  <svg className={`${className} stroke-current`} fill="none" viewBox="0 0 24 24">
    <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
  </svg>
);

export default DeleteIcon;
