import useSignedSource from "~/src/hooks/use-signed-source";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.document - The root object
 * @param props0.styles - The root object
 * @example
 */
const SignedLink = ({
  children, document, styles
}) => {
  const { src } = useSignedSource(document.file_name);

  return (
    <a className={styles} href={src || ""} rel="noreferrer" target="_blank">
      {children}
    </a>
  );
};

export default SignedLink;
