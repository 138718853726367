import { Combobox, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
  XIcon
} from "@heroicons/react/outline";
import innerText from "~/src/modules/inner-text";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { debounce } from "throttle-debounce";

import { findFirstCommonAncestorNode } from "./helpers";
import { chip, input } from "./styles.module.css";

const defaultNothingFoundMessage = "Keine Vorschläge gefunden...";
const defaultQueryEmptyMessage = "Suchanfrage eingeben...";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.async - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.compact - The root object
 * @param props0.control - The root object
 * @param props0.countryCode - The root object
 * @param props0.disabled - The root object
 * @param props0.inline - The root object
 * @param props0.isEdit - The root object
 * @param props0.label - The root object
 * @param props0.loadDefaultComboOptions - The root object
 * @param props0.messages - The root object
 * @param props0.messages.nothingFound - The root object
 * @param props0.messages.queryEmpty - The root object
 * @param props0.name - The root object
 * @param props0.noLabel - The root object
 * @param props0.noPadding - The root object
 * @param props0.onChange - The root object
 * @param props0.options - The root object
 * @param props0.outerPrefix - The root object
 * @param props0.outerSuffix - The root object
 * @param props0.placeholder - The root object
 * @param props0.prefix - The root object
 * @param props0.range - The root object
 * @param props0.reloadFlag - The root object
 * @param props0.resetField - The root object
 * @param props0.setValue - The root object
 * @param props0.suffix - The root object
 * @param props0.type - The root object
 * @example
 */
const ComboField = ({

  async = false,
  autoFocus = false,
  className,
  compact,
  control,
  countryCode,
  disabled = false,
  inline,
  isEdit = false,
  label,
  loadDefaultComboOptions = false,
  messages: {
    nothingFound: nothingFoundMessage = defaultNothingFoundMessage,
    queryEmpty: queryEmptyMessage = defaultQueryEmptyMessage
  } = {
    nothingFound: defaultNothingFoundMessage,
    queryEmpty: defaultQueryEmptyMessage
  },
  name,
  noLabel = false,
  noPadding = false,
  onChange = () => { },
  options,
  outerPrefix,
  outerSuffix,
  prefix,
  range,
  reloadFlag,
  resetField,
  setValue,
  suffix,
  type = "search",

  id = name,
  placeholder = innerText(label)
}) => {
  const [currentOptions, setCurrentOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");

  const {
    field,
    field: { value: fieldValue },
    formState: { errors }
  } = useController({
    control,
    name
  });

  const error = errors[name] || errors[name.split(".")[0]];

  const showError =
    Boolean(error) && error.context.label.replace(/\[(\d+)]./, ".$1.") === name;

  const nothingFound =
    (!currentOptions ||
      currentOptions.length === 0 ||
      (currentOptions.length === 1 && !currentOptions[0].label)) &&
      async;
  const queryEmpty = query === "";

  const hideEntries = (nothingFound || queryEmpty) && async && !loadDefaultComboOptions;

  const optionsEnabled = !(!async && options.length === 0);

  useEffect(() => {
    if (async) {
      if (!queryEmpty || loadDefaultComboOptions) {
        setIsLoading(true);

        const debounced = debounce(500, async (value) => {
          const res = await options(value, name, countryCode);

          setCurrentOptions(res);
          setIsLoading(false);
        });

        const throttled = debounce(500, async (value) => {
          const newOptions = await options(value, name, countryCode);

          setCurrentOptions(newOptions);
          setIsLoading(false);
        });

        const fetchOptions = async (value) => {
          await (value.length < 5 ? throttled(value) : debounced(value));
        };

        fetchOptions(query);
      }
    }
    else {
      let filteredOptions = options;

      if (!queryEmpty) {
        filteredOptions = options.filter(({ label }) => label
          .toLocaleLowerCase()
          .replaceAll(/\s+/gu, "")
          .includes(query.toLowerCase().replaceAll(/\s+/gu, "")));
      }

      setCurrentOptions(filteredOptions);
    }
  }, [
    query,
    queryEmpty,
    options,
    async,
    reloadFlag,
    loadDefaultComboOptions,
    countryCode,
    name
  ]);

  return (
    <Combobox
      as="div"
      className={cn(
        "py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200",
        className
      )}
      {...field}
      onChange={(e) => {
        field.onChange(e);
        onChange(e);
      }}
    >
      {({ open }) => (
        <div
          id="field-container"
          className={cn("sm:grid sm:gap-x-4", {
            "p-0": inline,
            "py-6 px-6": !inline,
            "sm:grid-cols-3": !compact,
            "sm:grid-cols-6": compact
          })}
        >
          {!noLabel && (
            <div className={inline ? "col-span-3" : "col-span-1"}>
              <Combobox.Label
                className={cn("inline-block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2", { "pointer-events-none": disabled })}
                id={id}
                key={`label${id}`}
              >
                <span
                  onClick={() => {
                    setShowOptions(true);
                  }}
                >
                  {label}
                </span>
              </Combobox.Label>
            </div>
          )}

          {outerPrefix}

          <div
            className={cn("relative grid grid-cols-3", {
              "col-span-5": compact,
              "cursor-not-allowed": disabled,
              [inline ? "col-span-2" : "col-span-1"]: prefix && !compact,
              [inline ? "col-span-3" : "col-span-2"]: !prefix && !compact
            })}
          >
            {prefix}

            <div
              id="input-container"
              className={cn(
                "flex items-center justify-between relative w-full",
                {
                  "col-span-2": prefix,
                  "col-span-3": !prefix,
                  "pointer-events-none": disabled
                }
              )}
            >
              <div className="absolute left-0 top-0 flex h-full divide-x py-2">
                <Combobox.Button className="flex items-center px-2 focus:outline-none">
                  <SearchIcon
                    aria-hidden="true"
                    className="size-5 text-gray-400"
                    onClick={() => {
                      setShowOptions(true);
                    }}
                  />
                </Combobox.Button>
              </div>

              {fieldValue?.label ? <div className={cn(chip)}>{fieldValue.label}</div> : null}

              <Combobox.Input
                autoComplete="off"
                autoFocus={autoFocus}
                displayValue={(option) => option?.label || query || ""}
                id={id}
                placeholder={placeholder}
                role="presentation"
                tabIndex={0}
                type="search"
                className={cn(input, {
                  "bg-gray-200": disabled,
                  "border-gray-300 focus:ring-gray-700 focus:border-gray-700":
                    !showError,
                  "border-red-500 focus:ring-red-700 focus:border-red-700":
                    showError
                })}
                onBlur={({ relatedTarget, target }) => {
                  let shouldHideOptions = true;

                  if (relatedTarget !== null) {
                    const clickedOnLabelOrButton = ["BUTTON", "LABEL"].includes(
                      relatedTarget.tagName
                    );

                    const firstCommonAncestorNode = findFirstCommonAncestorNode(
                      target,
                      relatedTarget
                    );

                    const isChildOfId = ["field-container", "input-container"].includes(firstCommonAncestorNode.id);

                    if (
                      (clickedOnLabelOrButton && isChildOfId) ||
                      relatedTarget.getAttribute("role") === "option"
                    ) {
                      shouldHideOptions = false;
                    }
                  }
                  else if (!fieldValue) {
                    resetField(name);
                    setQuery("");
                    target.value = "";
                  }

                  if (shouldHideOptions) {
                    setShowOptions(false);
                  }
                }}
                onChange={({ nativeEvent: { data }, target: { value } }) => {
                  const fieldIsSet =
                    fieldValue?.label && fieldValue?.label !== "";

                  if (fieldIsSet) {
                    if (isEdit) {
                      if (queryEmpty) {
                        setValue(name, {
                          id: "",
                          label: ""
                        });
                        setQuery(data || "");
                      }
                      else {
                        setQuery(value);
                      }
                    }
                    else {
                      resetField(name);
                      setQuery(data || "");
                    }
                  }
                  else {
                    setQuery(value);
                  }
                }}
                onClick={() => {
                  setShowOptions(true);
                }}
              />

              <div className="absolute right-0 top-0 flex h-full divide-x py-2">
                {fieldValue?.label === ""
                  ? isLoading && (
                    <div className="flex items-center px-2 focus:outline-none">
                      <svg
                        className="-ml-1 mr-3 size-5 animate-spin text-gray-800"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx={12}
                          cy={12}
                          r={10}
                          stroke="currentColor"
                          strokeWidth={4}
                        />

                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  )
                  : fieldValue && (
                    <Combobox.Button className="flex items-center px-2 focus:outline-none">
                      <XIcon
                        aria-hidden="true"
                        className="size-5 text-gray-400"
                        onClick={() => {
                          if (isEdit) {
                            setValue(name, null);
                          }
                          else {
                            resetField(name);
                            onChange(null);
                          }
                        }}
                      />
                    </Combobox.Button>
                  )}

                <Combobox.Button className="flex items-center px-2 focus:outline-none">
                  {showOptions
                    ? (
                      <ChevronUpIcon
                        aria-hidden="true"
                        className="size-5 text-gray-400"
                        onClick={() => {
                          setShowOptions(false);
                        }}
                      />
                    )
                    : (
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="size-5 text-gray-400"
                        onClick={() => {
                          setShowOptions(true);
                        }}
                      />
                    )}
                </Combobox.Button>
              </div>
            </div>

            {suffix}

            <Transition
              as="div"
              className="z-20"
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={showOptions}
              afterLeave={() => {
                setQuery("");
              }}
            >
              {optionsEnabled
                ? (
                  <div className="absolute z-20 mt-2 max-h-60 w-full overflow-hidden rounded border border-gray-300 text-base shadow-lg">
                    <Combobox.Options
                      static
                      className="max-h-60 w-full overflow-auto rounded bg-white py-1 text-base"
                    >
                      {hideEntries
                        ? (
                          <div className="relative cursor-default select-none px-4 py-2 text-center text-gray-400">
                            {nothingFound ? nothingFoundMessage : queryEmptyMessage}
                          </div>
                        )
                        : (
                          currentOptions
                            ?.filter(
                              ({ id }, index) => (id &&
                                !currentOptions
                                  .slice(0, index)
                                  .find(({ innerId }) => id === innerId)) ||
                                  !id
                            )
                            .map((option) => (
                              <Combobox.Option
                                value={option}
                                className={({ active }) => {
                                  const selected =
                                  fieldValue?.label === option?.label &&
                                  fieldValue?.label !== "";

                                  return cn(
                                    "cursor-default select-none relative py-2 px-4",
                                    {
                                      "text-white bg-gray-600":
                                      active && !selected,
                                      "text-white bg-gray-800": selected
                                    }
                                  );
                                }}
                                key={
                                  option.id ||
                                  (typeof option.value === "string"
                                    ? option.value
                                    : JSON.stringify(option.value))
                                }
                                onMouseUp={() => {
                                  setShowOptions(false);
                                }}
                              >
                                {({ active }) => (
                                  <span
                                    className={`block truncate ${fieldValue?.label === ""
                                      ? "font-normal"
                                      : "font-medium"
                                    }`}
                                  >
                                    {option.label}
                                  </span>
                                )}
                              </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                  </div>
                )
                : null}
            </Transition>

            {showError
              ? (
                <p className="col-span-3 mt-2 text-sm text-red-600">
                  {error.message}
                </p>
              )
              : null}
          </div>

          {outerSuffix}
        </div>
      )}
    </Combobox >
  );
};

export default ComboField;
