/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
const PageHeader = ({ children }) => (
  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    {children}
  </div>
);

export default PageHeader;
