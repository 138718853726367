import useAuth from "~/src/hooks/use-auth";
import useAutomatedProject from "~/src/hooks/use-automated-project";
import useEdit from "~/src/hooks/use-edit";
import EditButton from "~/src/ui/buttons/edit-project-button";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import DescriptionTitle from "~/src/ui/titles/description-title";
import { useParams } from "react-router-dom";

import AutomatedProjectNameForm from "./form";

/**
 *
 * @example
 */
const AutomatedProjectName = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    automated_project: project, isError, isLoading
  } = useAutomatedProject(id);
  const {
    editPossible, editing, setEditing
  } = useEdit({
    automated: true,
    project,
    user
  });

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Projektname" />

        <DescriptionContainer>
          {editing
            ? <AutomatedProjectNameForm projectId={id} setEditing={setEditing} />
            : <span>{project?.name}</span>}
        </DescriptionContainer>

        <EditButton
          editPossible={editPossible}
          editing={editing}
          onClick={() => setEditing((previous) => !previous)}
        />

      </div>

    </TabListContainer>
  );
};

export default AutomatedProjectName;
