import API from "~/src/modules/api";
import AsyncSelect from "~/src/ui/selects/custom-async-select";

/**
 *
 * @param props0 - The root object
 * @param props0.error - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.value - The root object
 * @example
 */
const CompanySelect = ({
  error, isSubmitting, name, onChange, value
}) => {
  const showError = error && isSubmitting;

  /**
   *
   * @param inputValue
   * @param callback
   * @example
   */
  async function loadOptions(inputValue, callback) {
    API.post("/search/autocomplete-company", {
      q: inputValue
    }).then((res) => {
      const options = [];

      for (const company of res.data.payload.companies) {
        if (company) {
          options.push({
            body: {
              id: company.id,
              name: company.name,
              reg_number: company.reg_number
            },
            label: company.reg_number ? `${company.reg_number} - ${company.name}` : company.name,
            value: company.id
          });
        }
      }

      callback(options);
    }).catch((error_) => {
      console.log(error_);
    });
  }

  return (
    <div className="relative">
      <AsyncSelect
        customValueKey="body"
        error={showError}
        loadOptions={loadOptions}
        name={name}
        noOptions="Name oder Firmenbuch-Nr. eingeben..."
        onChange={onChange}
        placeholder="Unternehmen"
        defaultValue={{
          label: value?.reg_number ? `${value.reg_number} - ${value.name}` : value?.name,
          value: value?.id || ""
        }}
      />

      {showError ? <p className="mt-2 text-sm text-red-600">{error.id?.message}</p> : null}
    </div>
  );
};

export default CompanySelect;
