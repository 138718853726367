/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { selectByKeys } from "~/src/_common/object-operations";
import useAuth from "~/src/hooks/use-auth";
import useEdit from "~/src/hooks/use-edit";
import useModal from "~/src/hooks/use-modal";
import useProject from "~/src/hooks/use-project";
import validationResolver from "~/src/modules/validation-resolver";
import SubmitButton from "~/src/ui/buttons/submit-button";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import DeleteIcon from "~/src/ui/icons/delete-icon";
import EditIcon from "~/src/ui/icons/edit-icon";
import PlusIcon from "~/src/ui/icons/plus-icon";
import DescriptionTitle from "~/src/ui/titles/description-title";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import IssuerModal from "../../certificates/issuers/modal";
import {
  expandedSchema,
  getInitialValuesForExpandedSchema
} from "../../certificates/issuers/schema.js";

import CertificateSlide from "./certificate-slide";
import DeleteCertificateModal from "./delete-certificate-modal";
import schema, {
  generateEditValues, initialValuesForForm
} from "./schema";

/**
 *
 * @example
 */
const ProjectCertificates = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  const completeReactFormApi = useForm({
    defaultValues: initialValuesForForm,
    resolver: validationResolver(schema)
  });

  const reactFormApi = selectByKeys(
    completeReactFormApi,
    [
      "control",
      "register",
      "reset",
      "resetField",
      "handleSubmit",
      "setValue",
      "watch"
    ]
  );

  reactFormApi.errors = completeReactFormApi.formState.errors;

  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();
  const addIssuerModal = useModal();

  const completeNewIssuerForm = useForm({
    defaultValues: getInitialValuesForExpandedSchema(),
    resolver: validationResolver(expandedSchema)
  });

  const newIssuerForm = selectByKeys(
    completeNewIssuerForm,
    [
      "control", // for NumberField
      "errors",
      "register",
      "reset", // possibly not used
      "clearErrors",
      "resetField", // for AreaField
      "handleSubmit",
      "setError",
      "setValue",
      "getValues",
      "watch"
    ]
  );

  newIssuerForm.formState = selectByKeys(completeNewIssuerForm.formState, ["errors"]);

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  const onClickEdit = (certificate) => {
    showSlide(generateEditValues(certificate));
  };

  const reloadFlag = { value: 0 };

  return (

    <TabListContainer>
      <IssuerModal
        {...{
          modal: addIssuerModal,
          mutateIssuers: () => {
            newIssuerForm.reset(); reloadFlag.value = ~reloadFlag.value;
          },
          reactForm: newIssuerForm
        }}
      />

      <CertificateSlide
        {...{
          certificate: slideData,
          hide: hideSlide,
          projectId: id,
          reactFormApi,
          reloadFlag,
          show: showSlide,
          showAddIssuer: addIssuerModal.show,
          visible: slideVisible
        }}
      />

      <DeleteCertificateModal certificate={deleteData} hide={hideDelete} show={deleteVisible} />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Gebäudezertifikate" />

        <DescriptionContainer>

          {project.certificates?.map((certificate, index) => (
            <div
              key={`certificate_${certificate.id}`}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0
              })}
            >

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Aussteller</p>

                <p className="">{certificate.issuer.name}</p>
              </div>

              {certificate.issuer?.has_certificates
                ? (
                  <div className="col-span-1">
                    <p className="text-sm font-medium text-gray-700">Zertifikat</p>

                    <p className="">{certificate.issuer_certificate?.name ?? "-"}</p>
                  </div>
                )
                : null}

              {certificate.issuer?.has_points
                ? (
                  <div className="col-span-1">
                    <p className="text-sm font-medium text-gray-700">Punktesystem</p>

                    <p>{`${certificate.assigned_points ?? "-"} von ${certificate.issuer?.max_points ?? "-"} Punkten`}</p>
                  </div>
                )
                : null}

              {showEditActions
                ? (
                  <div className="col-span-1 col-end-6 flex items-center justify-end">
                    <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-none" onClick={onClickEdit.bind(this, certificate)} type="button">
                      <EditIcon className="size-6" />
                    </button>

                    <button className="ml-6 inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-none" onClick={() => showDelete(certificate)} type="button">
                      <DeleteIcon className="size-6" />
                    </button>
                  </div>
                )
                : null}

              {!showEditActions &&
                <div className="col-span-2" />}

              <div className="col-span-4">
                <p className="text-sm font-medium text-gray-700">Sonstige Bemerkungen</p>

                <p>{certificate.notes ?? "-"}</p>
              </div>

            </div>
          ))}
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={onClickEdit}>
                  <PlusIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectCertificates;
