import { PencilIcon } from "@heroicons/react/solid";
import {
  colStyle, headerItem, tableHeaderContainer
} from "~/src/features/company-list/styles.module.css";

import { DetailButton, EditButton } from "./entry/_exports.js";

const Header = () => (
  <div className={tableHeaderContainer}>
    <div className={`${headerItem} col-span-1 md:col-span-full`}>
      <p className="ml-16">Name</p>
    </div>
  </div>
);

const Entry = ({
  isAdmin, issuer, issuersTempFrontEndValidation, modal, reactForm
}) => (
  <div className="col-span-1 grid md:grid-cols-4" key={`issuer_${issuer.id}`}>

    <div className="col-span-1 flex items-start truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-3">

      <DetailButton
        {...{
          issuer,
          issuersTempFrontEndValidation,
          modal,
          reactForm
        }}
      />

    </div>

    <div className={`${colStyle} flex items-center justify-between font-medium`}>

      <div className="ml-4 w-12">

        {
          (isAdmin)
            ? (
              <EditButton
                {...{
                  issuer,
                  issuersTempFrontEndValidation,
                  modal,
                  reactForm
                }}
              />
            )
            : null
        }

      </div>

    </div>
  </div>
);

export { Entry, Header };
