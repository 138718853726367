import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @param options0 - The root object
 * @param options0.cityId - The root object
 * @param options0.include_disabled - The root object
 * @param options0.name - The root object
 * @param options0.page - The root object
 * @param options0.sort - The root object
 * @param options0.state - The root object
 * @param options0.status - The root object
 * @param options0.street - The root object
 * @example
 */
const useAutomatedProjects = ({
  cityId = "", include_disabled = 0, name = "", page = 1, sort, state = "", status, street = ""
}) => {
  const parameters = new URLSearchParams({
    cityId,
    include_disabled,
    name,
    page,
    sort,
    state,
    status,
    street
  });

  const { data, error } = useSWR(`/automated-projects?${parameters}`, fetcher);

  return {
    automated_projects: data?.payload.automated_projects,
    isError: error,
    isLoading: !error && !data,
    pagination: data?.payload.pagination
  };
};

export default useAutomatedProjects;
