import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @param options0 - The root object
 * @param options0.filterSeller - The root object
 * @param options0.projectId - The root object
 * @param options0.residential - The root object
 * @example
 */
const useAutomatedProjectContracts = ({
  filterSeller = false,
  projectId,
  residential = false
}) => {
  const urlSearchParameters = new URLSearchParams({
    filter_seller: Number(filterSeller),
    residential: Number(residential)
  });

  const {
    data, error, mutate
  } = useSWR(
    `/automated-projects/${projectId}/contracts?${urlSearchParameters}`,
    fetcher
  );

  return {
    automated_contracts: data?.payload?.automated_contracts,
    isError: error,
    isLoading: !error && !data,
    mutate
  };
};

export default useAutomatedProjectContracts;
