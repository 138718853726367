import React from "react";

const Badge = ({ badgeFor = "intern", text }) => (
  <p className={`inline-flex h-5 items-center rounded-full px-2 text-xs font-medium ${badgeStyle(badgeFor)}`}>
    {text}
  </p>
);

const badgeStyle = (name) => {
  let color = "";

  switch (name) {
    case "internal":
      color = " bg-amber-200 text-amber-800";
      break;
    case "public":
      color = " bg-emerald-200 text-emerald-800";
      break;
    case "changes":
      color = " bg-red-200 text-red-800";
    default:
      break;
  }

  return color;
};

export default Badge;
