import Pagination from "~/src/features/pagination";
import ProjectList from "~/src/features/project-list";
import ProjectsSearchForm from "~/src/features/projects-search-form";
import useActiveUserAutomatedProjects from "~/src/hooks/use-active-user-automated-projects";
import useAutomatedProjectQueryParams from "~/src/hooks/use-automated-project-query-params";
import useAutomatedProjects from "~/src/hooks/use-automated-projects";
import useStore from "~/src/hooks/use-store";
import {
  sortFilterLabels, statusFilterLabels, synchronizedFilterLabels
} from "~/src/modules/labels";
import ActiveProjectLink from "~/src/ui/active-project-link";
import MainContainer from "~/src/ui/containers/main-container";
import CheckboxField from "~/src/ui/forms/checkbox-field";
import PageHeader from "~/src/ui/headers/page-header";
import Page from "~/src/ui/page";
import FilterEvent from "~/src/ui/search/filter-event";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

/**
 *
 * @example
 */
const AutomatedProjectsPage = () => {
  const { query, setQuery } = useAutomatedProjectQueryParams();
  const setSavedQueryAutomated = useStore((state) => state.setSavedQueryAutomated);

  const {
    cityId, includeDisabled, name, page, sortFilter, state, statusFilter, street, synchronizedFilter
  } = query;
  const {
    automated_projects: projects, isError, isLoading, pagination
  } = useAutomatedProjects({
    cityId,
    include_disabled: Number(includeDisabled),
    name,
    page,
    sort: sortFilter,
    state,
    status: statusFilter,
    street,
    synchronized: synchronizedFilter
  });
  const { automated_projects: activeUserProjects } = useActiveUserAutomatedProjects();

  const {
    control, formState: { errors }, register, watch
  } = useForm({
    defaultValues: {
      includeDisabled
    }
  });

  const handlePageChange = (value) => {
    setQuery({ page: value });
  };

  const resetFilter = () => {
    setQuery({}, "replace");
  };

  const includeDisabledValue = useWatch({
    control,
    name: "includeDisabled"
  });

  useEffect(() => {
    handleValueUpdate("includeDisabledChange", includeDisabledValue);
  }, [includeDisabledValue]);

  useEffect(() => {
    setSavedQueryAutomated(query);
  }, [query]);

  const handleValueUpdate = (filter, value) => {
    switch (filter) {
      case "nameChange":
        if (value) {
          setQuery({ name: value });
        }
        else {
          setQuery({ name: undefined });
        }
        break;

      case "streetChange":
        if (value) {
          setQuery({ street: value });
        }
        else {
          setQuery({ street: undefined });
        }
        break;

      case "cityIdChange":
        if (value) {
          setQuery({ cityId: value.id }); //query.zipcode - value is object with zipcode + name
        }
        else {
          setQuery({ cityId: undefined });
        }
        break;

      case "stateChange":
        if (value) {
          setQuery({ state: value }); //query.state
        }
        else {
          setQuery({ state: undefined }); //query.state
        }
        break;

      case "statusChange":
        setQuery({ statusFilter: value }); //query.statusFilter
        break;

      case "synchronizeChange":
        setQuery({ synchronizedFilter: value }); //query.synchronizedFilter
        break;

      case "sortChange":
        setQuery({ sortFilter: value }); //query.sortFilter
        break;

      case "includeDisabledChange":
        setQuery({ includeDisabled: value });

      default:
        break;
    }
  };

  if (isError) {
    return null;
  }

  return (
    <Page title="Automatisierte Projekte">

      <PageHeader>
        <div className="justify-between sm:flex">
          <div>
            <h2 className="text-2xl font-bold leading-7 text-gray-800 sm:truncate sm:text-3xl">
              Automatisierte Projekte
            </h2>
          </div>

          <div className="mt-12 sm:mt-0 sm:text-right">
            {activeUserProjects?.length > 0 &&
              <h3 className="mb-2 text-sm font-medium text-gray-700">Meine aktiven Projekte</h3>}

            {activeUserProjects?.map((project) => (
              <div className="mb-3" key={`active_projects_${project.id}`}>
                <ActiveProjectLink automated project={project} />
              </div>
            ))}
          </div>
        </div>
      </PageHeader>

      <MainContainer>

        <ProjectsSearchForm cityId={cityId} handleUpdate={handleValueUpdate} name={name} resetFilter={resetFilter} state={state} street={street} />

        <div className="mt-12 grid grid-cols-2 items-center justify-between gap-4 md:flex">
          <div className="col-span-1">
            <CheckboxField
              innerClassName="flex py-0 gap-4 items-center"
              label="Deaktivierte Projekte anzeigen"
              name="includeDisabled"
              {...{
                errors,
                register
              }}
            />
          </div>

          <div className="gap-4 md:flex">
            <FilterEvent
              eventName="sortChange"
              filter={sortFilter}
              handleUpdate={handleValueUpdate}
              label="Sortieren nach"
              options={sortFilterLabels.historic}
            />

            <FilterEvent
              eventName="statusChange"
              filter={statusFilter}
              handleUpdate={handleValueUpdate}
              label="Anzeigestatus"
              options={statusFilterLabels}
            />

            <FilterEvent
              eventName="synchronizeChange"
              filter={synchronizedFilter}
              handleUpdate={handleValueUpdate}
              label="Synchronisert"
              options={synchronizedFilterLabels}
            />
          </div>

        </div>

        <div className="mt-4">
          <Pagination labels={["Projekt", "Projekte"]} page={page} pagination={pagination} setPage={handlePageChange} />
        </div>

        <ProjectList automated isLoading={isLoading} projects={projects} />

        <Pagination labels={["Projekt", "Projekte"]} page={page} pagination={pagination} setPage={handlePageChange} />

      </MainContainer>

    </Page>
  );
};

export default AutomatedProjectsPage;
