import useAutomatedProject from "~/src/hooks/use-automated-project";
import Skeleton from "~/src/ui/loading/item-skeleton";
import {
  Route, Switch, useParams
} from "react-router-dom";

import AutomatedProjectUnitsGeneralPage from "./automated-project-units-list-page";

/**
 *
 * @example
 */
const AutomatedProjectUnitsPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useAutomatedProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <div className="-mt-24 pt-6 md:px-0">
      <Switch>
        <Route exact path="/automated-projects/:id/project-units">
          <AutomatedProjectUnitsGeneralPage />
        </Route>
      </Switch>
    </div>
  );
};

export default AutomatedProjectUnitsPage;
