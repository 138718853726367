import useAuth from "~/src/hooks/use-auth";
import useEdit from "~/src/hooks/use-edit";
import useModal from "~/src/hooks/use-modal";
import useProject from "~/src/hooks/use-project";
import { formatArea } from "~/src/modules/formatters";
import SubmitButton from "~/src/ui/buttons/submit-button";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import ProjectInfosTotalArea from "~/src/ui/displays/project-infos-total-area";
import EditIcon from "~/src/ui/icons/edit-icon";
import DescriptionTitle from "~/src/ui/titles/description-title";
import { useParams } from "react-router-dom";

import ConstructionTypesSlide from "./construction-types-slide";

/**
 *
 * @example
 */
const ProjectConstructionTypes = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  const construction_types = project?.construction_types;

  return (
    <TabListContainer>

      <ConstructionTypesSlide hide={hideSlide} project={slideData} projectId={id} visible={slideVisible} />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Bautypen" />

        <DescriptionContainer>

          {construction_types?.neubau
            ? (
              <div className="mb-2 grid grid-cols-5 gap-2 bg-gray-100 p-2">
                <div className="col-span-2">
                  <p className="text-sm font-bold text-gray-700">Neubau</p>

                  <p>{construction_types.neubau_area_total ? `${formatArea(construction_types.neubau_area_total)} (Gesamt inkl. Gewerbe)` : null}</p>

                  <p>{construction_types.neubau_unit_area_total ? `${formatArea(construction_types.neubau_unit_area_total)} (Wohnfläche)` : null}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">Wohneinheiten</p>

                  <p>{construction_types.neubau_count ? `${construction_types.neubau_count}` : "Anzahl unbekannt"}</p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm font-medium text-gray-700">Fläche (Wohneinheiten)</p>

                  <ProjectInfosTotalArea area={construction_types.neubau_area_range_from} toArea={construction_types.neubau_area_range_to} />
                </div>
              </div>
            )
            : null}

          {construction_types?.dg_ausbau
            ? (
              <div className="mb-2 grid grid-cols-5 gap-2 bg-gray-100 p-2">
                <div className="col-span-2">
                  <p className="text-sm font-bold text-gray-700">DG-Ausbau</p>

                  <p>{construction_types.dg_ausbau_area_total ? `${formatArea(construction_types.dg_ausbau_area_total)} (Gesamt inkl. Gewerbe)` : null}</p>

                  <p>{construction_types.dg_ausbau_unit_area_total ? `${formatArea(construction_types.dg_ausbau_unit_area_total)} (Wohnfläche)` : null}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">Wohneinheiten</p>

                  <p>{construction_types.dg_ausbau_count ? `${construction_types.dg_ausbau_count}` : "Anzahl unbekannt"}</p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm font-medium text-gray-700">Fläche (Wohneinheiten)</p>

                  <ProjectInfosTotalArea area={construction_types.dg_ausbau_area_range_from} toArea={construction_types.dg_ausbau_area_range_to} />
                </div>
              </div>
            )
            : null}

          {construction_types?.sanierung
            ? (
              <div className="mb-2 grid grid-cols-5 gap-2 bg-gray-100 p-2">
                <div className="col-span-2">
                  <p className="text-sm font-bold text-gray-700">Sanierung altbestand</p>

                  <p>{construction_types.sanierung_area_total ? `${formatArea(construction_types.sanierung_area_total)} (Gesamt inkl. Gewerbe)` : null}</p>

                  <p>{construction_types.sanierung_unit_area_total ? `${formatArea(construction_types.sanierung_unit_area_total)} (Wohnfläche)` : null}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">Wohneinheiten</p>

                  <p>{construction_types.sanierung_count ? `${construction_types.sanierung_count}` : "Anzahl unbekannt"}</p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm font-medium text-gray-700">Fläche (Wohneinheiten)</p>

                  <ProjectInfosTotalArea area={construction_types.sanierung_area_range_from} toArea={construction_types.sanierung_area_range_to} />
                </div>
              </div>
            )
            : null}

          {construction_types?.unsaniert
            ? (
              <div className="mb-2 grid grid-cols-5 gap-2 bg-gray-100 p-2">
                <div className="col-span-2">
                  <p className="text-sm font-bold text-gray-700">unsaniert</p>

                  <p>{construction_types.unsaniert_area_total ? `${formatArea(construction_types.unsaniert_area_total)} (Gesamt inkl. Gewerbe)` : null}</p>

                  <p>{construction_types.unsaniert_unit_area_total ? `${formatArea(construction_types.unsaniert_unit_area_total)} (Wohnfläche)` : null}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">Wohneinheiten</p>

                  <p>{construction_types.unsaniert_count ? `${construction_types.unsaniert_count}` : "Anzahl unbekannt"}</p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm font-medium text-gray-700">Fläche (Wohneinheiten)</p>

                  <ProjectInfosTotalArea area={construction_types.unsaniert_area_range_from} toArea={construction_types.unsaniert_area_range_to} />
                </div>
              </div>
            )
            : null}

          {construction_types?.fremdbestand
            ? (
              <div className="mb-2 grid grid-cols-5 gap-2 bg-gray-100 p-2">
                <div className="col-span-2">
                  <p className="text-sm font-bold text-gray-700">fremdbestand</p>

                  <p>{construction_types.fremdbestand_area_total ? `${formatArea(construction_types.fremdbestand_area_total)} (Gesamt inkl. Gewerbe)` : null}</p>

                  <p>{construction_types.fremdbestand_unit_area_total ? `${formatArea(construction_types.fremdbestand_unit_area_total)} (Wohnfläche)` : null}</p>
                </div>

                <div className="col-span-1">
                  <p className="text-sm font-medium text-gray-700">Wohneinheiten</p>

                  <p>{construction_types.fremdbestand_count ? `${construction_types.fremdbestand_count}` : "Anzahl unbekannt"}</p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm font-medium text-gray-700">Fläche (Wohneinheiten)</p>

                  <ProjectInfosTotalArea area={construction_types.fremdbestand_area_range_from} toArea={construction_types.fremdbestand_area_range_to} />
                </div>
              </div>
            )
            : null}

        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide(project)}>
                  <EditIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectConstructionTypes;
