import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @param options0 - The root object
 * @param options0.bauteil - The root object
 * @param options0.category - The root object
 * @param options0.hidden - The root object
 * @param options0.name - The root object
 * @param options0.page - The root object
 * @param options0.projectId - The root object
 * @param options0.sort - The root object
 * @param options0.tz - The root object
 * @example
 */
const useUnits = ({
  bauteil = "", category = "", hidden = false, name = "", page = 1, projectId, sort = "", tz = ""
}) => {
  let parameters = `page=${page}&name=${encodeURIComponent(name)}&tz=${encodeURIComponent(tz)}&sort=${sort}&category=${category}&bauteil=${bauteil}`;

  if (hidden) {
    parameters += "&hidden=true";
  }

  const {
    data, error, mutate
  } = useSWR(`/projects/${projectId}/units?${parameters}`, fetcher);

  return {
    categories: data?.payload.categories,
    isError: error,
    isLoading: !error && !data,
    mutate,
    pagination: data?.payload.pagination,
    unitCount: data?.payload.unitCount,
    unitStats: data?.payload.unitStats,
    units: data?.payload.units
  };
};

export default useUnits;
