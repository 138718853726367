import useAuth from "~/src/hooks/use-auth";
import useAutomatedProject from "~/src/hooks/use-automated-project";
import { formatArea } from "~/src/modules/formatters";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import DescriptionTitle from "~/src/ui/titles/description-title";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const AutomatedProjectEinlagen = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    automated_project: project,
    isError,
    isLoading
  } = useAutomatedProject(id);

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const {
    area, ez, gst_numbers, katastralgemeinde
  } = project;

  return (
    <TabListContainer>
      <div className="sm:grid sm:grid-cols-8 sm:gap-4">
        <DescriptionTitle title="Projektliegenschaften" />

        <DescriptionContainer>
          <div className="grid grid-cols-4 gap-2 bg-gray-100 p-2">
            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">BG</p>

              <p className="">
                {katastralgemeinde.bezirksgericht.code}

                {" "}

                {katastralgemeinde.bezirksgericht.name}
              </p>
            </div>

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">KG</p>

              <p className="">
                {katastralgemeinde.code} {katastralgemeinde.name}
              </p>
            </div>

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Fläche</p>

              <p>{area ? formatArea(area) : "-"}</p>
            </div>

            <div className="col-span-1" />

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">EZ</p>

              <p className="">{ez}</p>
            </div>

            <div className="col-span-3">
              <p className="text-sm font-medium text-gray-700">GST-Nr.</p>

              <p className="">{gst_numbers.join(", ")}</p>
            </div>
          </div>
        </DescriptionContainer>
      </div>
    </TabListContainer>
  );
};

export default AutomatedProjectEinlagen;
