import SearchIcon from "~/src/ui/icons/search-icon";
import { useState } from "react";

/**
 *
 * @param props0 - The root object
 * @param props0.setName - The root object
 * @param props0.setPage - The root object
 * @param props0.setTz - The root object
 * @example
 */
const SearchUnits = ({
  setName, setPage, setTz
}) => {
  const [nameValue, setNameValue] = useState("");
  const [tzValue, setTzValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setName(nameValue);
    setTz(tzValue);
    setPage(1);
  };

  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  };
  const handleTzChange = (event) => {
    setTzValue(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-14 space-x-2 md:flex md:w-1/2">

        <div className="relative col-span-1 w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon styles="h-4 w-4 text-gray-400" />
          </div>

          <input
            autoComplete="off"
            className="block w-full rounded border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder:text-gray-500 focus:border-gray-500 focus:text-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:placeholder:text-gray-400 sm:text-sm"
            name="name"
            onChange={handleNameChange}
            placeholder="Objektname"
            type="search"
          />
        </div>

        <div className="relative col-span-1 w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon styles="h-4 w-4 text-gray-400" />
          </div>

          <input
            autoComplete="off"
            className="block w-full rounded border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder:text-gray-500 focus:border-gray-500 focus:text-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:placeholder:text-gray-400 sm:text-sm"
            name="name"
            onChange={handleTzChange}
            placeholder="TZ"
            type="search"
          />
        </div>

        <button className="mt-6 inline-flex items-center rounded border border-gray-600 bg-gray-600 px-12 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:bg-gray-700 focus:outline-none md:ml-2 md:mt-0" type="submit">
          Suchen
        </button>

      </div>
    </form>
  );
};

export default SearchUnits;
