import cn from "classnames";
import { diffChars } from "diff";

const displayDiff = (value, otherValue, side, type = "normal") => (
  <span>
    {
      diffChars(String((side === "before" ? value : otherValue) ?? ""), String((side === "before" ? otherValue : value) ?? ""))
        .filter(({ added, removed }) => (side === "before" ? !added : !removed))
        .map((part, index) => (
          <span
            key={index}
            className={cn({
              "bg-green-100": part.added && type === "normal",
              "bg-purple-200": (part.added || part.removed) && type === "neutral",
              "bg-red-100": part.removed && type === "normal"
            })}
          >
            {part.value}
          </span>
        ))
    }
  </span>
);

const DiffDisplay = ({
  before,
  after,
  side
}) => displayDiff(before, after, side);

export { DiffDisplay };

export default displayDiff;
