.buttonStyle > svg:first-child {
	@apply -ml-1;
}

.buttonStyle > svg:last-child {
	@apply -mr-1;
}

.buttonStyle > svg:first-child:last-child {
	@apply -mx-1;
}