import Activity from "~/src/features/activity";
import useAuth from "~/src/hooks/use-auth";
import arraysAreEqualSets from "~/src/modules/arrays-are-equal-sets";
import { activityTypeLabels } from "~/src/modules/labels";
import Skeleton from "~/src/ui/loading/skeleton";
import cn from "classnames";
import dayjs from "dayjs";
import { groupBy } from "lodash-es";
import {
  Fragment, useEffect, useState
} from "react";

/**
 *
 * @param props0 - The root object
 * @param props0.activities - The root object
 * @param props0.activityTypes - The root object
 * @param props0.isLoading - The root object
 * @param props0.setActivityTypes - The root object
 * @example
 */
const ActivityList = ({
  activities,
  activityTypes,
  isLoading,
  setActivityTypes
}) => {
  const { user } = useAuth();

  const projectsActivityTypes = [...activityTypeLabels.keys()].filter(
    (key) => !["unit", "unit-document"].includes(key)
  );

  const unitsActivityTypes = ["unit", "unit-document"];

  const onlyProjectsActivityTypesSelected = arraysAreEqualSets(
    activityTypes,
    projectsActivityTypes
  );
  const onlyUnitsActivityTypesSelected = arraysAreEqualSets(
    activityTypes,
    unitsActivityTypes
  );

  const [sideSelected, setSideSelected] = useState(null);
  const [sideActive, setSideActive] = useState("none");

  useEffect(() => {
    switch (sideSelected) {
      case "both":
        setActivityTypes([]);
        break;
      case "projects":
        setActivityTypes(projectsActivityTypes);
        break;
      case "units":
        setActivityTypes(unitsActivityTypes);
        break;
      default:
        // no default
        break;
    }
  }, [sideSelected]);

  useEffect(() => {
    if (onlyProjectsActivityTypesSelected && !onlyUnitsActivityTypesSelected) {
      setSideSelected("projects");
      setSideActive("projects");
    }
    else if (
      !onlyProjectsActivityTypesSelected &&
      onlyUnitsActivityTypesSelected
    ) {
      setSideSelected("units");
      setSideActive("units");
    }
    else {
      setSideActive("none");
    }
  }, [onlyProjectsActivityTypesSelected, onlyUnitsActivityTypesSelected]);

  const columnTitles = [
    "Bereich",
    "Art",
    "Projekt",
    "Editor",
    "Zeitpunkt",
    "Änderungen"
  ];

  if (activities?.length === 0) {
    return (
      <div className="flex min-h-screen justify-center bg-gray-50">
        <span className="my-8 text-gray-600">Keine Aktivitäten gefunden</span>
      </div>
    );
  }

  return (
    <div className="grid min-h-screen grid-cols-12 content-start justify-items-center gap-2 rounded bg-gray-50 p-4 text-sm text-gray-600">
      <h2
        className={cn(
          "text-lg font-medium col-span-7 mb-4 cursor-pointer border border-gray-300 px-2 py-1  rounded-sm",
          {
            "bg-gray-50 text-gray-900 hover:bg-gray-100": sideActive !== "projects",
            "bg-gray-500 text-white hover:bg-gray-400": sideActive === "projects"
          }
        )}
        onClick={() => {
          setSideSelected(sideSelected === "projects" ? "both" : "projects");
        }}
      >
        Projekte
      </h2>

      <h2
        className={cn(
          "text-lg font-medium col-span-3 mb-4 cursor-pointer border border-gray-300 px-2 py-1 rounded-sm",
          {
            "bg-gray-50 text-gray-900 hover:bg-gray-100": sideActive !== "units",
            "bg-gray-500 text-white hover:bg-gray-400": sideActive === "units"
          }
        )}
        onClick={() => {
          setSideSelected(sideSelected === "units" ? "both" : "units");
        }}
      >
        Objekte
      </h2>

      {isLoading ? <Skeleton className="col-span-12" /> : null}

      {!isLoading &&
        Object.entries(
          groupBy(activities, ({ created_at }) => dayjs(created_at).format("DD.MM.YYYY"))
        ).map(([day, activities], dayIndex) => (
          <Fragment key={dayIndex}>
            <span className="relative col-span-12 mt-4 flex w-full justify-center">
              <div className="absolute -top-5 left-0 h-[calc(100%_+_1.5rem)] w-[calc((((100%_/_12)_*_7)_/_2)_-_0.125rem)] border-r border-dashed border-gray-300" />

              <span className="rounded border border-gray-200 bg-white p-2 shadow-md">
                {day}
              </span>

              <div className="absolute -top-5 right-0 h-[calc(100%_+_1.5rem)] w-[calc((((100%_/_12)_*_7)_/_2)_-_0.125rem)] border-l border-dashed border-gray-300" />
            </span>

            {activities.map((activity, activityIndex) => (
              <Activity {...{ activity }} key={activityIndex} />
            ))}
          </Fragment>
        ))}
    </div>
  );
};

export default ActivityList;
