import useProject from "~/src/hooks/use-project";
import Skeleton from "~/src/ui/loading/item-skeleton";
import {
  Route, Switch, useParams
} from "react-router-dom";

import ProjectUnitsGeneralPage from "./project-units-list-page";
import ProjectUnitsStatisticsPage from "./project-units-statistics-page";

/**
 *
 * @example
 */
const ProjectUnitsPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <div className="-mt-24 pt-6 md:px-0">
      <Switch>
        <Route exact path="/projects/:id/project-units">
          <ProjectUnitsGeneralPage />
        </Route>

        <Route exact path="/projects/:id/project-units/statistics">
          <ProjectUnitsStatisticsPage />
        </Route>
      </Switch>
    </div>

  );
};

export default ProjectUnitsPage;
