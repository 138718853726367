/* eslint-disable max-lines-per-function */
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import validationResolver from "~/src/modules/validation-resolver";
import CancelButton from "~/src/ui/buttons/cancel-button";
import CollectionField from "~/src/ui/forms/collection-field";
import Field from "~/src/ui/forms/field";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import RangeField from "~/src/ui/forms/range-field";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import SlideOver from "~/src/ui/slides/container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { handleCreate, handleUpdate } from "./handlers";
import schema, { generateEditValues, initialValues } from "./schema";

/**
 *
 * @param props0 - The root object
 * @param props0.energy_class - The root object
 * @param props0.hide - The root object
 * @param props0.projectId - The root object
 * @param props0.visible - The root object
 * @example
 */
const EnergyClassSlide = ({
  energy_class, hide, projectId, visible
}) => {
  const { mutate } = useProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    control, formState: { errors }, handleSubmit, register, reset, watch
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    if (energy_class) {
      reset(generateEditValues(energy_class));
    }
  }, [reset, energy_class]);

  const handleClose = () => {
    reset(initialValues);
    hide();
  };

  const onSubmit = (data) => {
    const commonProperties = {
      data,
      handleClose,
      mutate,
      setIsLoading,
      showError
    };

    if (energy_class) {
      handleUpdate({
        ...commonProperties,
        id: energy_class.id
      });
    }
    else {
      handleCreate({
        ...commonProperties,
        projectId
      });
    }
  };

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">

          <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Energieausweis ${energy_class ? "bearbeiten" : "erstellen"}`} />

          <RangeField
            int
            optional
            label="HWB"
            name="hwb_from"
            scale={2}
            labels={{
              checkbox: "von-bis",
              to: "HWB bis"
            }}
            {...{
              control,
              errors,
              register,
              watch
            }}
          />

          <Field
            label="HWB Klasse"
            name="hwb_class"
            {...{
              errors,
              register
            }}
          />

          <RangeField
            int
            optional
            label="fGEE"
            name="fgee_from"
            scale={3}
            labels={{
              checkbox: "von-bis",
              to: "fGEE bis"
            }}
            {...{
              control,
              errors,
              register,
              watch
            }}
          />

          <Field
            label="fGEE Klasse"
            name="fgee_class"
            {...{
              errors,
              register
            }}
          />

          <Field
            label="Bereich/Objekt"
            name="description"
            {...{
              errors,
              register
            }}
          />

          <CollectionField
            label="GST-Nr."
            name="gstNumbers"
            {...{
              control,
              errors,
              register
            }}
          />
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={formIsLoading} onClick={handleClose} />

            <FormSubmitButton isSubmitting={formIsLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>

      </form>
    </SlideOver>
  );
};

export default EnergyClassSlide;
