/* eslint-disable react-hooks/exhaustive-deps */
import useAuth from "~/src/hooks/use-auth";
import { useEffect } from "react";
import {
  Redirect, Route, useHistory
} from "react-router-dom";

const ProtectedRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const {
    error, isLoading, loggedOut, user
  } = useAuth();

  useEffect(() => {
    if (error || loggedOut) {
      history.replace("/login");
    }
  }, [error, loggedOut]);

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
