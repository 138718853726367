import dateFormatter from "~/src/modules/date-formatter";

import customActivity from "./custom-activity";

const dateActivity = (label) => customActivity({
  label,
  value: dateFormatter
});

export default dateActivity;
