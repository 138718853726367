/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import useAuth from "~/src/hooks/use-auth";
import Button from "~/src/ui/buttons/button";
import CancelButton from "~/src/ui/buttons/cancel-button";
import AreaField from "~/src/ui/forms/area-field";
import ComboField from "~/src/ui/forms/combo-field";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import NumberField from "~/src/ui/forms/number-field";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import PlusIcon from "~/src/ui/icons/plus-icon";
import { useEffect } from "react";

const CertificateForm = ({
  certificateJSON,
  fetchIssuers,
  formIsLoading,
  handleClose,
  loadIssuerCertificates,
  onChangeIssuer,
  onChangeIssuerCertificate,
  onSubmit,
  reactFormApi: {
    control,
    errors,
    handleSubmit,
    register,
    resetField,
    setValue,
    watch
  },
  reloadFlag,
  showAddIssuer
}) => {
  const { isAdmin } = useAuth();

  const items = [];

  const certificate = JSON.parse(certificateJSON);

  const issuerSelected = Boolean(certificate?.issuer?.id);

  const issuerHasPoints = certificate?.issuer?.has_points;

  const hasCertificates = certificate?.issuer?.has_certificates;

  const hasOnlyPoints = certificate?.issuer?.has_points &&
    !certificate?.issuer?.has_certificates;

  const noIssuerCertificateSelected =
    certificate?.issuer?.has_certificates &&
    !certificate?.issuer_certificate?.id;

  const issuerCertificateWithPointsSelected =
    !noIssuerCertificateSelected && issuerHasPoints;

  const projectCertificateHasPoints =
    issuerCertificateWithPointsSelected || hasOnlyPoints;

  items.push(
    <ComboField
      key="certificates-issuer"
      label="Aussteller"
      name="issuer"
      onChange={onChangeIssuer}
      options={fetchIssuers}
      reloadFlag={reloadFlag}
      messages={{
        queryEmpty: "Aussteller..."
      }}
      {... {
        control,
        resetField,
        setValue,
        watch
      }}
      async
      loadDefaultComboOptions
      className="col-span-6"
    />
  );

  if (isAdmin) {
    items.push(
      <Button
        {...{
          className: "w-10 ml-10 mt-0",
          icon: PlusIcon,
          iconSize: 5,
          onClick: showAddIssuer
        }}
      />
    );
  }

  if (issuerSelected && hasCertificates) {
    items.push(
      <ComboField
        key="certificates-issuer-certificate"
        label="Zertifizierung"
        name="issuer_certificate"
        onChange={onChangeIssuerCertificate}
        options={loadIssuerCertificates}
        messages={{
          queryEmpty: "Zertifizierung..."
        }}
        {... {
          control,
          resetField,
          setValue,
          watch
        }}
        async
        loadDefaultComboOptions
        className="col-span-6"
      />
    );
  }

  if (issuerSelected && projectCertificateHasPoints) {
    items.push(
      <div className="col-span-6 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-x-4 sm:space-y-0 sm:px-6 sm:py-5" key="assigned-points-grid">
          <div className="relative col-span-1 col-start-1">
            <label className="absolute top-[30%] text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
              Punktesystem
            </label>
          </div>

          <div className="col-span-1 col-start-2">
            <NumberField
              int
              allowNegative={false}
              hasLabel={false}
              name="assigned_points"
              {...{
                control,
                errors,
                register,
                setValue,
                watch
              }}
            />
          </div>

          <div className="relative col-span-1 col-start-3 text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            <div className="absolute -left-[40%] top-[35%]">
              {`von ${certificate.issuer.max_points} Punkten`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (issuerSelected) {
    items.push(
      <AreaField
        className="col-span-6 text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
        label="Sonstige Bemerkung"
        name="notes"
        {...{
          errors,
          register,
          resetField,
          setValue,
          watch
        }}
        key="certificates-notes"
      />
    );
  }

  useEffect(() => {
    const certificate = JSON.parse(certificateJSON);

    setValue("issuer", { ...certificate?.issuer });
    setValue("issuer_certificate", { ...certificate?.issuer_certificate });
    setValue("assigned_points", certificate?.assigned_points);
    setValue("notes", certificate?.notes ?? "");
  }, [certificateJSON]);

  return (
    <form
      autoComplete="off"
      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-1 flex-col" key="certificates-main-col">
        <SlideFormHeader
          closeDisabled={formIsLoading}
          handleHide={handleClose}
          key="certificates-slide-header"
          title={certificate?.id ? "Gebäudezertifikat bearbeiten" : "Gebäudezertifikat erstellen"}
        />

        <div
          className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
          key="certificates-input-fields"
        >
          {items}
        </div>
      </div>

      <div
        className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6"
        key="certificates-buttons"
      >
        <div className="flex justify-end space-x-3">
          <CancelButton onClick={handleClose} />

          <FormSubmitButton isSubmitting={formIsLoading}>
            Speichern
          </FormSubmitButton>
        </div>
      </div>
    </form>
  );
};

export default CertificateForm;
