import SearchIcon from "~/src/ui/icons/search-icon";
import { components } from "react-select";
import { default as Select } from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

/*
import useCityId from "~/src/hooks/use-city-id";

import { useState, useEffect } from 'react'
*/

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.cacheOptions - The root object
 * @param props0.customValueKey - The root object
 * @param props0.defaultOptions - The root object
 * @param props0.defaultValue - The root object
 * @param props0.error - The root object
 * @param props0.loadOptions - The root object
 * @param props0.name - The root object
 * @param props0.noOptions - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.value - The root object
 * @example
 */
const AsyncSelect = ({
  id,
  cacheOptions = true,
  customValueKey = "value",
  defaultOptions = false,
  defaultValue = "",
  error,
  loadOptions,
  name,
  noOptions,
  onChange,
  placeholder,
  value
}) => {
  const customStyles = {
    control: (styles, state) => {
      let borderColor = "#cbd5e0";

      if (error) {
        borderColor = "rgba(239, 68, 68, 1)";
      }
      if (state.isFocused) {
        borderColor = "rgba(17, 24, 39, 1)";
      }

      return ({
        ...styles,
        borderColor,
        borderRadius: "3px",
        height: "20px"
      });
    },
    menu: (provided) => ({
      ...provided,
      zIndex: "9999 !important"
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9_999
    }),
    placeholder: (styles, state) => ({
      ...styles,
      color: "rgba(107, 114, 128,1.0)",
      fontSize: "14px"
    }),
    singleValue: (base) => ({
      ...base,
      background: "rgba(67, 56, 202, 1)",
      borderRadius: "5px",
      color: "white",
      padding: "0 6px"
    })
  };

  /**
   *
   * @param option
   * @example
   */
  function handleChange(option) {
    if (option) {
      const event = {
        target: {
          name,
          type: "custom-select",
          value: (option && option[customValueKey]) || defaultValue
        }
      };

      onChange(event);
    }
    else {
      const event = {
        target: {
          name,
          type: "custom-select",
          value: null
        }
      };

      onChange(event);
    }
  }

  const loadOptionsDebounced = debounce(500, loadOptions);
  const loadOptionsThrottled = throttle(500, loadOptions);

  /**
   *
   * @param inputValue
   * @example
   */
  function handleLoadOptions(inputValue) {
    return new Promise((resolve, reject) => {
      if (inputValue.length < 5) {
        loadOptionsThrottled(inputValue, (options) => {
          resolve(options);
        });
      }
      else {
        loadOptionsDebounced(inputValue, (options) => {
          resolve(options);
        });
      }
    });
  }

  /**
   *
   * @param data
   * @example
   */
  function noOptionsMessage(data) {
    if (data.inputValue.length > 0) {
      return "Keine Vorschläge gefunden...";
    }

    return noOptions;
  }

  return (
    <Select
      blurInputOnSelect
      isClearable
      cacheOptions={cacheOptions}
      className="react-select z-20"
      components={{ Control }}
      defaultOptions={defaultOptions}
      defaultValue={defaultValue}
      id={id}
      inputProps={{ autoComplete: "nope" }}
      instanceId={name}
      loadOptions={handleLoadOptions}
      menuPortalTarget={document.querySelector("body")}
      menuPosition="fixed"
      name={name}
      noOptionsMessage={noOptionsMessage}
      onChange={handleChange}
      placeholder={placeholder}
      styles={customStyles}
      theme={theme}
      {...(value === undefined ? { value: defaultValue } : { value })}
    />
  );
};

export default AsyncSelect;

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
const Control = ({ children, ...rest }) => (
  <components.Control {...rest} className="pl-2">
    <SearchIcon className="text-gray-400" /> {children}
  </components.Control>
);

const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "rgba(107, 114, 128, 1)"
  }
});
