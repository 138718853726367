import DeleteIcon from "~/src/ui/icons/delete-icon";
import EditIcon from "~/src/ui/icons/edit-icon";
import SignedLink from "~/src/ui/links/signed-link";

/**
 *
 * @param props0 - The root object
 * @param props0.document - The root object
 * @param props0.showDeleteDocument - The root object
 * @param props0.showEditActions - The root object
 * @param props0.showEditDocument - The root object
 * @example
 */
const UnitDocumentItem = ({
  document, showDeleteDocument, showEditActions, showEditDocument
}) => (
  <li className="col-span-1 flex rounded-md shadow-sm" key={`document_unit_${document.id}`}>

    {showEditActions
      ? (
        <div className="flex w-16 shrink-0 items-center justify-center rounded-l-md bg-white text-sm font-medium text-gray-500">
          <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-none" onClick={() => showEditDocument(document)} type="button">
            <EditIcon className="size-6" />
          </button>
        </div>
      )
      : null}

    <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-y border-r border-gray-200 bg-white">

      <div className="flex-1 truncate px-4 py-2 text-sm">
        <SignedLink document={document} styles="text-gray-900 font-medium hover:text-gray-600">
          {document.public_name ? document.public_name : "Kein Name"}
        </SignedLink>

        <p className="text-gray-500">{document.show_public ? "öffentlich" : "privat"}</p>
      </div>

      {showEditActions
        ? (
          <div className="shrink-0 pr-2">
            <button className="inline-flex items-center text-gray-500 hover:text-gray-400 focus:outline-none" onClick={() => showDeleteDocument(document)} type="button">
              <DeleteIcon className="size-6" />
            </button>
          </div>
        )
        : null}
    </div>
  </li>
);

export default UnitDocumentItem;
