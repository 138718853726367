.inputStyle {
  @apply block w-full border rounded-sm shadow-sm h-10 px-3 focus:outline-none sm:text-sm placeholder-gray-300;
}

.inputError {
  @apply border-red-300 text-red-900;
}

.errorIcon {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
}

.clear {
  padding: 0px;
  position: absolute;
  height: 1rem;
  width: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
  cursor: pointer;
  @apply bg-gray-800;
}

.clear::before,
.clear::after {
  all: unset;
  position: absolute;
  content: "";
  width: 61%;
  height: 14%;
  background-color: white;
}

.clear::before {
  transform: rotate(45deg);
}

.clear::after {
  transform: rotate(-45deg);
}