import useAutomatedProject from "~/src/hooks/use-automated-project";
import useAutomatedUnits from "~/src/hooks/use-automated-units";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import ProjectInfosTotalArea from "~/src/ui/displays/project-infos-total-area";
import DescriptionTitle from "~/src/ui/titles/description-title";
import cn from "classnames";
import { groupBy } from "lodash-es";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const AutomatedProjectQuantities = () => {
  const { id } = useParams();
  const {
    automated_project: project,
    isError,
    isLoading
  } = useAutomatedProject(id);

  const { automatedUnits } = useAutomatedUnits({
    pagination: false,
    projectId: id
  });

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const quantities = Object.entries(groupBy(automatedUnits, (unit) => unit.unit_category.name))
    .map(([categoryName, groupedUnits], index) => {
      const groupedUnitsSortedByArea = [...groupedUnits]
        .filter(({ sale_area }) => sale_area !== null)
        .sort(({ sale_area: areaA }, { sale_area: areaB }) => Number(areaA) - Number(areaB));

      const smallestArea = groupedUnitsSortedByArea.at(0) && Number(groupedUnitsSortedByArea.at(0).sale_area);
      const biggestArea = groupedUnitsSortedByArea.at(-1) && Number(groupedUnitsSortedByArea.at(-1).sale_area);

      return {
        id: index,
        category: {
          name: categoryName
        },
        total_area_range_from: smallestArea,
        total_area_range_to: smallestArea === biggestArea ? null : biggestArea,
        total_units: groupedUnits.length
      };
    });

  return (
    <TabListContainer>
      <div className="sm:grid sm:grid-cols-8 sm:gap-4">
        <DescriptionTitle title="Einheiten" />

        <DescriptionContainer>
          {quantities?.map((quantity, index) => (
            <div
              key={`quantity_${quantity.id}`}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0
              })}
            >
              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">
                  Objektkategorie
                </p>

                <p className="">{quantity.category.name}</p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Einheiten</p>

                <p className="">
                  {quantity.total_units ? quantity.total_units : "-"}
                </p>
              </div>

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">Fläche</p>

                <ProjectInfosTotalArea
                  area={quantity.total_area_range_from}
                  toArea={quantity.total_area_range_to}
                />
              </div>
            </div>
          ))}
        </DescriptionContainer>
      </div>
    </TabListContainer>
  );
};

export default AutomatedProjectQuantities;
