import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

const useUnitBuyerDifferences = (query) => {
  const {
    data, error, mutate
  } = useSWR(
    `/unit-buyer-differences?${new URLSearchParams(Object.fromEntries(Object.entries(query).filter(([key, value]) => value !== undefined)))}`,
    fetcher
  );

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    pagination: data?.payload?.pagination,
    unitBuyerDifferences: data?.payload?.unitBuyerDifferences
  };
};

export default useUnitBuyerDifferences;
