import Handler from "~/src/modules/handler.js";

const {
  handleCreate,
  handleDelete,
  handleUpdate
} = new Handler({
  endpoint: "/einlagen/:id",

  afterEvery: ({ setIsLoading }) => {
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { handleClose, mutate }) => {
    await mutate();
    handleClose();
  },
  transformCreateData: (data, { projectId }) => ({
    einlage: {
      area: data.area,
      baurecht: data.baurecht || null || false,
      br_kg_id: data.baurechtKg?.id ?? null,
      brez: data.brezNumber ?? null,
      ez: data.ezNumber,
      gst_numbers: data.gstNumbers.map(({ value }) => value) || null,
      kg_id: data.kg.id,
      project_id: projectId
    }
  }),
  transformUpdateData: (data) => ({
    einlage: {
      area: data.area,
      baurecht: data.baurecht,
      br_kg_id: data.baurechtKg?.id ?? null,
      brez: data.brezNumber ?? null,
      ez: data.ezNumber,
      gst_numbers: data.gstNumbers.map(({ value }) => value) || null,
      kg_id: data.kg.id
    }
  })
});

export {
  handleCreate,
  handleDelete,
  handleUpdate
};
