import API from "~/src/modules/api";

/**
 *
 * @param form
 * @param handleSuccess
 * @param handleError
 * @example
 */
const handleSubmit = async (form, handleSuccess, handleError) => {
  const data = {
    auth: {
      email: form.values.email,
      password: form.values.password
    }
  };

  form.setIsLoading(true);

  API.post("/auth/login", data).then((res) => {
    handleSuccess(res.data);
  }).catch((error) => {
    handleError(error);
  })
    .finally(() => {
      form.setIsSubmitting(false);
      form.setIsLoading(false);
    });
};

export { handleSubmit };
