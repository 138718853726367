import AutomatedProjectContractsPage from "~/src/pages/automated-projects/[id]/automated-project-contracts-page";
import AutomatedProjectOverviewPage from "~/src/pages/automated-projects/[id]/automated-project-overview-page";
import AutomatedProjectSettingsPage from "~/src/pages/automated-projects/[id]/automated-project-settings-page";
import AutomatedProjectUnitsPage from "~/src/pages/automated-projects/[id]/automated-project-units-page";
import ProjectAttributes from "~/src/pages/projects/[id]/project-attributes-page";
import ProjectCompanies from "~/src/pages/projects/[id]/project-companies-page";
import ProjectContractsPage from "~/src/pages/projects/[id]/project-contracts-page";
import ProjectDocuments from "~/src/pages/projects/[id]/project-documents-page";
import ProjectInfos from "~/src/pages/projects/[id]/project-infos-page";
import ProjectOverview from "~/src/pages/projects/[id]/project-overview-page";
import ProjectSettings from "~/src/pages/projects/[id]/project-settings-page";
import ProjectTimeline from "~/src/pages/projects/[id]/project-timeline-page";
import ProjectTransactions from "~/src/pages/projects/[id]/project-transactions-page";
import ProjectUnits from "~/src/pages/projects/[id]/project-units-page";
import { Route, Switch } from "react-router-dom";

/**
 *
 * @example
 */
const Tabs = () => (
  <Switch>
    <Route exact path="/projects/:id">
      <ProjectOverview />
    </Route>

    <Route path="/projects/:id/project-infos">
      <ProjectInfos />
    </Route>

    <Route path="/projects/:id/project-attributes">
      <ProjectAttributes />
    </Route>

    <Route path="/projects/:id/project-timeline">
      <ProjectTimeline />
    </Route>

    <Route path="/projects/:id/project-documents">
      <ProjectDocuments />
    </Route>

    <Route path="/projects/:id/project-transactions">
      <ProjectTransactions />
    </Route>

    <Route path="/projects/:id/project-companies">
      <ProjectCompanies />
    </Route>

    <Route path="/projects/:id/project-units">
      <ProjectUnits />
    </Route>

    <Route path="/projects/:id/project-settings">
      <ProjectSettings />
    </Route>

    <Route path="/projects/:id/project-contracts">
      <ProjectContractsPage />
    </Route>

    <Route exact path="/automated-projects/:id">
      <AutomatedProjectOverviewPage />
    </Route>

    <Route path="/automated-projects/:id/project-units">
      <AutomatedProjectUnitsPage />
    </Route>

    <Route path="/automated-projects/:id/project-settings">
      <AutomatedProjectSettingsPage />
    </Route>

    <Route path="/automated-projects/:id/project-contracts">
      <AutomatedProjectContractsPage />
    </Route>

  </Switch>
);

export default Tabs;
