import { formatNumber } from "~/src/modules/formatters";
import React from "react";

const UnitTd = ({
  area = false, hideDash = false, val
}) => {
  if (area) {
    return (
      <span className={`${val ? "font-bold text-gray-500" : ""}`}>
        {val ? `${formatNumber(val)} m²` : <span className={`${hideDash && "text-white"}`}>-</span>}
      </span>
    );
  }

  return (
    <span className={`${val ? "font-bold text-gray-500" : ""}`}>
      {val || <span className={`${hideDash && "text-white"}`}>-</span>}
    </span>
  );
};

export default UnitTd;
