/* eslint-disable max-lines-per-function */
import useForm from "~/src/hooks/use-form";
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import { readFileAsync } from "~/src/modules/upload-helpers";
import CancelButton from "~/src/ui/buttons/cancel-button";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import UploadIcon from "~/src/ui/icons/upload-icon";
import SlideOver from "~/src/ui/slides/container";
import SingleFileUploadField from "~/src/ui/upload/single-file-upload-field";
import {
  useCallback, useEffect, useState
} from "react";
import { useDropzone } from "react-dropzone";
import sanitize from "sanitize-filename";
import { v4 as uuidv4 } from "uuid";

import { handleCreate } from "./handlers";
import { initialValues, schema } from "./schema";
import { active, dropzone } from "./styles.module.css";

/**
 *
 * @param root0 - The root object
 * @param root0.projectId - The root object
 * @param root0.visible - The root object
 * @param root0.hide - The root object
 * @example
 */
const NewDocumentsSlide = ({
  hide, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();
  const [files, setFiles] = useState([]);

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    setFiles([]);
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleCreate({
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        projectId,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    const filesToRead = acceptedFiles.map((file) => readFileAsync(file));
    const readFiles = await Promise.all(filesToRead);

    const updatedFiles = readFiles.map((file) => {
      const fileName = file.type === "application/pdf" ? file.name : `${file.name.replace(/\.[^./]+$/u, "")}.jpg`;

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        fileName: `${window?.settings?.REACT_APP_HOST_ENV}/projects/${uuidv4()}-${sanitize(fileName).replaceAll(" ", "-")}`
      });
    });

    setFiles((previous) => [...previous, ...updatedFiles]);
  }, []);

  const {
    getInputProps, getRootProps, isDragActive
  } = useDropzone({
    maxSize: 1_024 * 1_024 * 50,
    accept: "image/*, application/pdf",
    multiple: true,
    onDropAccepted
  });

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    for (const file of files) {
      URL.revokeObjectURL(file.preview);
    }
  }, [files]);

  useEffect(() => {
    if (files.length > 0) {
      form.updateProperties({ files });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  /**
   *
   * @param file
   * @example
   */
  function onUpload(file) {
    setFiles((previous) => previous.map((previousFile) => {
      if (previousFile.fileName === file.fileName) {
        return Object.assign(previousFile, {
          publicName: "",
          showPublic: false,
          uploaded: true
        });
      }

      return previousFile;
    }));
  }

  return (
    <SlideOver
      visible={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">
          <SlideFormHeader
            closeDisabled={form.isLoading}
            title="Dokumente hinzufügen"
            handleHide={() => {
              handleHide(form);
            }}
          />

          <div className="mt-12">
            <div {...getRootProps()} className={`${dropzone} ${isDragActive ? active : null}`}>
              <input {...getInputProps()} />

              <UploadIcon />

              <span className="ml-4">Dokumente hinzufügen</span>
            </div>

            <div className="mt-20 divide-y divide-gray-300">
              {files.map((file, index) => (
                <SingleFileUploadField
                  projectDocuments
                  file={file}
                  form={form}
                  index={index}
                  key={file.name + index}
                  onUpload={onUpload}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton
              disabled={form.isLoading}
              onClick={() => {
                handleHide(form);
              }}
            />

            <FormSubmitButton isSubmitting={form.isLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default NewDocumentsSlide;
