import {
  hiddenActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers";
import { fundingLabels } from "~/src/modules/labels";

export default {
  ...Object.fromEntries(
    ["id", "project_id"].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [...fundingLabels].map(([key, label]) => [[key, normalActivity(label)], [`${key}_total`, normalActivity(`${label} - Anzahl Einheiten`)]])
  )
};
