export const labelsToFiltersLabels = (labels) => Object.entries({
  all: "Alle",
  ...labels
}).map(
  ([value, label]) => ({
    label,
    value
  })
);

/**
 *
 * @param object
 * @param filter
 * @example
 */
const filterObject = (object, filter) => {
  const notObjectOrNull = typeof (object) !== "object" || object === null;

  if (notObjectOrNull) {
    return;
  }

  return Object.fromEntries(
    Object.entries(object)
      .filter(filter)
  );
};

export { filterObject };
