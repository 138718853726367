import { EyeOffIcon, PencilIcon } from "@heroicons/react/solid";
import Skeleton from "~/src/ui/loading/item-skeleton";

import {
  colStyle, headerItem, tableBody, tableContainer, tableHeaderContainer
} from "./styles.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.companies - The root object
 * @param props0.isLoading - The root object
 * @param props0.showDetailsModal - The root object
 * @param props0.showEditModal - The root object
 * @example
 */
const CompanyList = ({
  companies, isLoading, showDetailsModal, showEditModal
}) => {
  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className={tableContainer}>

      <div className={tableHeaderContainer}>

        <div className={`${headerItem} col-span-1 md:col-span-3`}>
          <p className="ml-16">Name</p>
        </div>

        <div className={`${headerItem} col-span-1`}>
          <p>Firmenbuch-Nr.</p>
        </div>

      </div>

      <div className={tableBody}>
        {companies?.map((company) => (
          <div className="col-span-1 grid md:grid-cols-4" key={`company_${company.id}`}>

            <div className="col-span-1 flex items-start truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-3">
              <button className="flex items-center font-medium text-gray-500 underline hover:text-gray-600 focus:outline-none" onClick={() => showDetailsModal(company)} type="button">
                {!company.searchable &&
                  <EyeOffIcon className="mr-2 size-5 text-red-500 hover:text-red-400" />}

                {company.name}
              </button>
            </div>

            <div className={`${colStyle} flex items-center justify-between font-medium`}>
              <span>
                {company.reg_number
                  ? (
                    <button className="font-medium text-gray-500 underline hover:text-gray-600 focus:outline-none" onClick={() => showDetailsModal(company)} type="button">
                      {company.reg_number}
                    </button>
                  )
                  : null}
              </span>

              <div className="ml-4 w-12">
                <button className="focus:outline-none" onClick={() => showEditModal(company)} type="button">
                  <PencilIcon className="size-5 text-gray-500 hover:text-gray-600" />
                </button>
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyList;
