import cn from "classnames";

import { input } from "./styles.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.label - The root object
 * @param props0.name - The root object
 * @param props0.register - The root object
 * @example
 */
const InlineCheckbox = ({
  autoFocus = false,
  disabled = false,
  errors,
  label,
  name,
  register,

  id = name
}) => {
  const showError = Boolean(errors[name]);

  return (
    <>
      <div className="relative flex h-full items-end">
        <div className="flex h-5 items-center">
          <input
            {...register(name)}
            {
              ...{
                id,
                autoFocus,
                disabled,
                type: "checkbox"
              }
            }
            className={cn(input, {
              "bg-gray-200": disabled,
              "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError,
              "border-red-500 focus:ring-red-700 focus:border-red-700": showError
            })}
          />
        </div>

        <div className="ml-3 text-sm">
          <label className="cursor-pointer font-medium text-gray-700" htmlFor={id}>
            {label}
          </label>
        </div>
      </div>

      {showError ? <p className="col-span-3 mt-2 text-sm text-red-600">{errors[name].message}</p> : null}
    </>
  );
};

export default InlineCheckbox;
