import {
  areaActivity,
  hiddenActivity,
  listActivity,
  normalActivity
} from "~/src/modules/format-activity-change/helpers";

export default {
  ...Object.fromEntries(
    [
      "id",
      "project_id",
      "katastralgemeinde_id",
      "katastralgemeinde_bezirksgericht_id",
      "katastralgemeinde_bezirksgericht_state_id"
    ].map((key) => [key, hiddenActivity])
  ),

  ...Object.fromEntries(
    [
      ["ez_number", "EZ"],
      ["katastralgemeinde", "KG"],
      ["katastralgemeinde_code", "KG-Nr."],
      ["katastralgemeinde_bezirksgericht_code", "BG-Nr."],
      ["katastralgemeinde_bezirksgericht", "BG"]
    ].map(([key, label]) => [key, normalActivity(label)])
  ),

  gst_numbers: listActivity("GST-Nr."),

  area: areaActivity("Fläche")
};
