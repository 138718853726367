import Joi from "joi";

export const initialValues = {
  neubau: false,
  neubau_area_range_from: "",
  neubau_area_range_to: "",
  neubau_area_total: "",
  neubau_count: "",
  neubau_unit_area_total: "",
  show_neubau_range: false,

  dg_ausbau: false,
  dg_ausbau_area_range_from: "",
  dg_ausbau_area_range_to: "",
  dg_ausbau_area_total: "",
  dg_ausbau_count: "",
  dg_ausbau_unit_area_total: "",
  show_dg_ausbau_range: false,

  sanierung: false,
  sanierung_area_range_from: "",
  sanierung_area_range_to: "",
  sanierung_area_total: "",
  sanierung_count: "",
  sanierung_unit_area_total: "",
  show_sanierung_range: false,

  show_unsaniert_range: false,
  unsaniert: false,
  unsaniert_area_range_to: "",
  unsaniert_area_total: "",
  unsaniert_count: "",
  unsaniert_unit_area_total: "",

  fremdbestand: false,
  fremdbestand_area_range_to: "",
  fremdbestand_area_total: "",
  fremdbestand_count: "",
  fremdbestand_unit_area_total: "",
  show_fremdbestand_range: false
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    const { construction_types } = project;

    return {
      neubau: construction_types?.neubau === true,
      neubau_area_range_from: construction_types?.neubau_area_range_from || "",
      neubau_area_range_to: construction_types?.neubau_area_range_to || "",
      neubau_area_total: construction_types?.neubau_area_total || "",
      neubau_count: construction_types?.neubau_count || "",
      neubau_unit_area_total: construction_types?.neubau_unit_area_total || "",
      show_neubau_range: Boolean(construction_types?.neubau_area_range_to),

      dg_ausbau: construction_types?.dg_ausbau === true,
      dg_ausbau_area_range_from: construction_types?.dg_ausbau_area_range_from || "",
      dg_ausbau_area_range_to: construction_types?.dg_ausbau_area_range_to || "",
      dg_ausbau_area_total: construction_types?.dg_ausbau_area_total || "",
      dg_ausbau_count: construction_types?.dg_ausbau_count || "",
      dg_ausbau_unit_area_total: construction_types?.dg_ausbau_unit_area_total || "",
      show_dg_ausbau_range: Boolean(construction_types?.dg_ausbau_area_range_to),

      sanierung: construction_types?.sanierung === true,
      sanierung_area_range_from: construction_types?.sanierung_area_range_from || "",
      sanierung_area_range_to: construction_types?.sanierung_area_range_to || "",
      sanierung_area_total: construction_types?.sanierung_area_total || "",
      sanierung_count: construction_types?.sanierung_count || "",
      sanierung_unit_area_total: construction_types?.sanierung_unit_area_total || "",
      show_sanierung_range: Boolean(construction_types?.sanierung_area_range_to),

      show_unsaniert_range: Boolean(construction_types?.unsaniert_area_range_to),
      unsaniert: construction_types?.unsaniert === true,
      unsaniert_area_range_from: construction_types?.unsaniert_area_range_from || "",
      unsaniert_area_range_to: construction_types?.unsaniert_area_range_to || "",
      unsaniert_area_total: construction_types?.unsaniert_area_total || "",
      unsaniert_count: construction_types?.unsaniert_count || "",
      unsaniert_unit_area_total: construction_types?.unsaniert_unit_area_total || "",

      fremdbestand: construction_types?.fremdbestand === true,
      fremdbestand_area_range_from: construction_types?.fremdbestand_area_range_from || "",
      fremdbestand_area_range_to: construction_types?.fremdbestand_area_range_to || "",
      fremdbestand_area_total: construction_types?.fremdbestand_area_total || "",
      fremdbestand_count: construction_types?.fremdbestand_count || "",
      fremdbestand_unit_area_total: construction_types?.fremdbestand_unit_area_total || "",
      show_fremdbestand_range: Boolean(construction_types?.fremdbestand_area_range_to)
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  neubau: Joi.boolean().required(),
  neubau_area_range_from: Joi.number().positive().empty(""),
  neubau_area_range_to: Joi.number().positive().empty(""),
  neubau_area_total: Joi.number().positive().empty(""),
  neubau_count: Joi.number().positive().empty(""),
  neubau_unit_area_total: Joi.number().positive().empty(""),
  show_neubau_range: Joi.boolean().required(),

  dg_ausbau: Joi.boolean().required(),
  dg_ausbau_area_range_from: Joi.number().positive().empty(""),
  dg_ausbau_area_range_to: Joi.number().positive().empty(""),
  dg_ausbau_area_total: Joi.number().positive().empty(""),
  dg_ausbau_count: Joi.number().positive().empty(""),
  dg_ausbau_unit_area_total: Joi.number().positive().empty(""),
  show_dg_ausbau_range: Joi.boolean().required(),

  sanierung: Joi.boolean().required(),
  sanierung_area_range_from: Joi.number().positive().empty(""),
  sanierung_area_range_to: Joi.number().positive().empty(""),
  sanierung_area_total: Joi.number().positive().empty(""),
  sanierung_count: Joi.number().positive().empty(""),
  sanierung_unit_area_total: Joi.number().positive().empty(""),
  show_sanierung_range: Joi.boolean().required(),

  show_unsaniert_range: Joi.boolean().required(),
  unsaniert: Joi.boolean().required(),
  unsaniert_area_range_from: Joi.number().positive().empty(""),
  unsaniert_area_range_to: Joi.number().positive().empty(""),
  unsaniert_area_total: Joi.number().positive().empty(""),
  unsaniert_count: Joi.number().positive().empty(""),
  unsaniert_unit_area_total: Joi.number().positive().empty(""),

  fremdbestand: Joi.boolean().required(),
  fremdbestand_area_range_from: Joi.number().positive().empty(""),
  fremdbestand_area_range_to: Joi.number().positive().empty(""),
  fremdbestand_area_total: Joi.number().positive().empty(""),
  fremdbestand_count: Joi.number().positive().empty(""),
  fremdbestand_unit_area_total: Joi.number().positive().empty(""),
  show_fremdbestand_range: Joi.boolean().required()
});
