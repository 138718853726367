import Joi from "joi";

import { coordinatesValidation } from "./helpers";

export const initialValues = {
  city: {
    id: "",
    label: "",
    zipcode: ""
  },
  coordinates: "",
  default_location: false,
  housenumber: "",
  street: {
    id: "",
    city: {
      name: "",
      zipcode: ""
    },
    label: ""
  }
};

/**
 *
 * @param locationData
 * @example
 */
const generateEditValues = (locationData) => {
  if (locationData) {
    const {
      street: {
        id: streetId,
        city: {
          id: cityId,
          name: cityName,
          zipcode
        },
        name: streetName
      }
    } = locationData;

    return {
      city: {
        id: cityId,
        label: `${zipcode} ${cityName}`,
        zipcode
      },
      coordinates: locationData ? `${locationData.lng}, ${locationData.lat}` : "",
      default_location: Boolean(locationData?.default_location),
      housenumber: locationData?.housenumber || "",
      street: {
        id: streetId,
        label: `${streetName} (${zipcode} ${cityName})`
      }
    };
  }

  return initialValues;
};

export { generateEditValues };

const schema = Joi.object({
  city: Joi.object({
    id: Joi.number().positive().empty(""),
    label: Joi.string().empty(""),
    zipcode: Joi.string().empty("")
  }),
  coordinates: Joi.string().empty("").custom(coordinatesValidation, "custom validation")
    .required(),
  default_location: Joi.boolean().required(),
  housenumber: Joi.string().empty(""),
  street: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    label: Joi.string().empty("")
  })
});

export default schema;
