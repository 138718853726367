import { validDate } from "~/src/modules/formatters";
import Handler from "~/src/modules/handler.js";

const { handleUpdate } = new Handler({
  endpoint: "/construction-infos/:id",

  afterEvery: ({ setIsLoading, setIsSubmitting }) => {
    setIsSubmitting(false);
    setIsLoading(false);
  },
  afterEveryError: (error, { showError }) => {
    showError(error);
  },
  afterEverySuccess: async (responseData, { hide, mutate }) => {
    await mutate();
    hide();
  },
  transformUpdateData: (data) => ({
    construction_info: {
      construction_start_date: validDate(data.construction_start_date) || null,
      construction_start_date_format: data.construction_start_date_format?.value,
      construction_year: validDate(data.construction_year) || null,
      estimated_finish_date: validDate(data.estimated_finish_date) || null,
      estimated_finish_date_format: data.estimated_finish_date_format?.value,
      is_new: data.is_new?.value,
      reconstruction_year: data.is_new?.value === false ? validDate(data.reconstruction_year) : null
    }
  })
});

export {
  handleUpdate
};
