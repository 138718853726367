import useNotification from "~/src/hooks/use-notification";
import API from "~/src/modules/api";
import validationResolver from "~/src/modules/validation-resolver";
import CancelButton from "~/src/ui/buttons/cancel-button";
import CheckboxField from "~/src/ui/forms/checkbox-field";
import ComboField from "~/src/ui/forms/combo-field";
import Field from "~/src/ui/forms/field";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import NumberField from "~/src/ui/forms/number-field";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import UnitDocumentItemSmall from "~/src/ui/project-unit/unit-document-item-small";
import SlideOver from "~/src/ui/slides/container";
import cn from "classnames";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import OfferForm from "./forms/offer-form";
import SaleForm from "./forms/sale-form";
import { handleUpdate } from "./handlers";
import schema, { generateEditValues, initialValues } from "./schema";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.mutateUnits - The root object
 * @param props0.projectId - The root object
 * @param props0.unit - The root object
 * @param props0.visible - The root object
 * @example
 */
const EditUnitSlide = ({
  hide, mutateUnits, projectId, unit, visible
}) => {
  const [formIsLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { showError, showSuccess } = useNotification();

  const form = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  const {
    control, formState: { errors, isDirty }, handleSubmit, register, reset, resetField, setValue, watch
  } = form;

  useEffect(() => {
    if (unit) {
      setIsEdit(true);
      generateEditValues(unit)
        .then((editValues) => {
          reset(editValues);
        });
    }
  }, [reset, unit]);

  const handleClose = () => {
    reset(initialValues);
    hide();
  };

  const onSubmit = (data) => {
    if (isDirty) {
      handleUpdate({
        data,
        handleClose,
        mutate: mutateUnits,
        setIsLoading,
        showError,
        showSuccess,
        unitId: unit.id
      });
    }
    else {
      handleClose();
    }
  };

  const loadUnitCategoryOptions = async (inputValue) => {
    const { data: { payload: { quantities } } } = await API.get(`/projects/${projectId}/quantities`);

    const quantityCategories = quantities.map(({ unit_category_id }) => unit_category_id);

    return API.get("/unit-categories")
      .then((res) => {
        let options = res.data.payload.unit_categories;

        if (quantityCategories.length > 0) {
          options = options.filter((category) => quantityCategories.includes(category.id));
        }

        options = options
          .map((category) => ({
            id: category.id,
            code: category.code,
            label: category.name
          }))
          .filter((category) => category.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) || inputValue.toLocaleLowerCase().includes(category.label.toLocaleLowerCase()));

        return options;
      }).catch((error) => {
        console.log(error);
      });
  };

  const offerDataAvailable = watch("offerDataAvailable");

  const saleDataAvailable = watch("saleDataAvailable");

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <FormProvider {...form}>
        <form autoComplete="off" className="relative flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value="hidden" />

          <div className="flex flex-1 flex-col">

            <SlideFormHeader handleHide={handleClose} title={`Objekt ${unit ? "bearbeiten" : "erstellen"}`} />

            <CheckboxField
              label="Objekt im Produkt anzeigen"
              name="show"
              {...{
                errors,
                register
              }}
            />

            <div className="m-4 grid grid-cols-6 gap-x-4 bg-indigo-100 px-3 py-5">
              <div className="col-span-6">
                <p className="text-sm font-bold text-indigo-600">Interne Sortierung</p>
              </div>

              <ComboField
                loadDefaultComboOptions
                label="Bauteil"
                name="buildingPart"
                options={[]}
                messages={{
                  queryEmpty: "Bauteil..."
                }}
                {...{
                  control,
                  isEdit,
                  resetField,
                  setValue,
                  watch
                }}
                compact
                disabled
                className="col-span-6"
              />

              <NumberField
                noprefix
                label="Stiege"
                name="positionStaircase"
                scale="none"
                {...{
                  control,
                  errors,
                  register
                }}
                compact
                disabled
                className="col-span-3"
                innerClassName="pr-0"
              />

              <NumberField
                noprefix
                label="Nummer"
                name="position"
                scale="none"
                {...{
                  control,
                  errors,
                  register
                }}
                compact
                disabled
                className="col-span-3 ml-8"
                innerClassName="pl-0"
              />

            </div>

            <ComboField
              async
              loadDefaultComboOptions
              label="Objektkategorie"
              name="unit_category"
              options={loadUnitCategoryOptions}
              messages={{
                queryEmpty: "Objektkategorie..."
              }}
              {...{
                control,
                isEdit,
                resetField,
                setValue,
                watch
              }}
              disabled
              className="col-span-6"
            />

            <Field
              label="Name"
              name="name"
              {...{
                errors,
                register
              }}
              disabled
            />

            <Field
              label="Stiege"
              name="staircase"
              {...{
                errors,
                register
              }}
              disabled
            />

            <Field
              label="Geschoß"
              name="floor"
              {...{
                errors,
                register
              }}
              disabled
            />

            <CheckboxField
              disabled
              label="Maisonettewohnung"
              name="maisonette"
              {...{
                errors,
                register
              }}
            />

            <div
              className={cn("m-4 transition-all rounded", {
                "bg-primary-brown-light": offerDataAvailable,
                "bg-white": !offerDataAvailable
              })}
            >
              <CheckboxField
                disabled
                label="Angebotsdaten vorhanden"
                name="offerDataAvailable"
                className={cn("transition-all transform", {
                  "-mx-4": !offerDataAvailable,
                  "mx-0": offerDataAvailable
                })}
                {...{
                  errors,
                  register
                }}
              />

              {offerDataAvailable
                ? (
                  <OfferForm
                    {...{
                      isEdit,
                      onSubmit
                    }}
                  />
                )
                : null}
            </div>

            <div
              className={cn("mx-4 mb-4 transition-all rounded", {
                "bg-primary-green-light": saleDataAvailable,
                "bg-white": !saleDataAvailable
              })}
            >
              <CheckboxField
                disabled
                label="Objekt verkauft"
                name="saleDataAvailable"
                className={cn("transition-all transform", {
                  "-mx-4": !saleDataAvailable,
                  "mx-0": saleDataAvailable
                })}
                {...{
                  errors,
                  register
                }}
              />

              {saleDataAvailable
                ? (
                  <SaleForm
                    {...{
                      isEdit,
                      onSubmit,
                      unit
                    }}
                  />
                )
                : null}
            </div>
          </div>

          <div className="sticky bottom-0 flex shrink-0 items-center justify-between gap-4 border-t border-gray-200 bg-white px-4 py-5 sm:px-6">
            {
              unit?.documents && unit.documents.length > 0
                ? (
                  <ul className="flex flex-wrap gap-2">
                    {
                      unit.documents.map((document) => (
                        <UnitDocumentItemSmall
                          document={document}
                          key={`document_unit_${document.id}`}
                        />
                      ))
                    }
                  </ul>
                )
                : null
            }

            <div className="ml-auto flex items-center justify-end gap-4">
              <CancelButton onClick={handleClose} />

              <FormSubmitButton isSubmitting={formIsLoading}>
                Speichern
              </FormSubmitButton>
            </div>
          </div>

        </form>

      </FormProvider>
    </SlideOver>
  );
};

export default EditUnitSlide;
