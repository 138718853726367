import useAuth from "~/src/hooks/use-auth";
import useEdit from "~/src/hooks/use-edit";
import useModal from "~/src/hooks/use-modal";
import useProject from "~/src/hooks/use-project";
import SubmitButton from "~/src/ui/buttons/submit-button";
import PlusIcon from "~/src/ui/icons/plus-icon";
import DeleteModal from "~/src/ui/modals/delete-modal.jsx";
import ProjectDocumentListItem from "~/src/ui/project-document-list-item";
import { useParams } from "react-router-dom";

import EditDocumentSlide from "./edit-document-slide";
import { handleDelete } from "./handlers.js";
import NewDocumentsSlide from "./new-documents-slide";

/**
 *
 * @example
 */
const ProjectDocuments = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, mutate, project
  } = useProject(id);
  const { editPossible } = useEdit({
    project,
    user
  });

  const {
    hide: hideNew, show: showNew, visible: newVisible
  } = useModal();
  const {
    data: editData, hide: hideEdit, show: showEdit, visible: editVisible
  } = useModal();
  const {
    data: deleteData, hide: hideDelete, show: showDelete, visible: deleteVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible;

  const publicDocuments = project.documents.filter((document_) => document_.show_public);
  const privateDocuments = project.documents.filter((document_) => !document_.show_public);
  const hasPublicDocuments = publicDocuments.length > 0;
  const hasPrivateDocuments = privateDocuments.length > 0;

  const showPublicDocuments = publicDocuments.map((document) => (
    <ProjectDocumentListItem document={document} handleDelete={showDelete} handleEdit={showEdit} key={`public-project-documents-${document.id}`} showEditActions={showEditActions} />
  ));

  const showPrivateDocuments = privateDocuments.map((document) => (
    <ProjectDocumentListItem document={document} handleDelete={showDelete} handleEdit={showEdit} key={`private-project-documents-${document.id}`} showEditActions={showEditActions} />
  ));

  return (
    <div>
      <NewDocumentsSlide hide={hideNew} projectId={id} visible={newVisible} />

      <EditDocumentSlide document={editData} hide={hideEdit} projectId={id} visible={editVisible} />

      <DeleteModal
        {...{
          id: deleteData?.id,
          handleDelete,
          hide: hideDelete,
          mutate,
          show: deleteVisible
        }}
      />

      <div className="mb-8 flex justify-between">
        <h2 className="mb-4 text-xl font-medium text-gray-800">Dokumente</h2>

        {showEditActions
          ? (
            <div>
              <SubmitButton onClick={showNew}>
                <PlusIcon className="size-5" />
              </SubmitButton>
            </div>
          )
          : null}
      </div>

      <h3 className="mt-12 font-semibold text-gray-500">Veröffentlichte Dokumente</h3>

      {hasPublicDocuments
        ? (
          <ul className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {showPublicDocuments}
          </ul>
        )
        : null}

      {!hasPublicDocuments &&
        <p className="mt-2">Keine öffentlichen Dokumente</p>}

      <h3 className="mt-20 font-semibold text-gray-500">Private Dokumente</h3>

      {hasPrivateDocuments
        ? (
          <ul className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {showPrivateDocuments}
          </ul>
        )
        : null}

      {!hasPrivateDocuments &&
        <p className="mt-2">Keine privaten Dokumente</p>}

    </div>
  );
};

export default ProjectDocuments;
