import ProjectCertificates from "~/src/features/project-infos/project-certificates";
import ProjectDescription from "~/src/features/project-infos/project-description";
import ProjectEinlagen from "~/src/features/project-infos/project-einlagen";
import ProjectEnergyClasses from "~/src/features/project-infos/project-energy-classes";
import ProjectImage from "~/src/features/project-infos/project-image";
import ProjectLocations from "~/src/features/project-infos/project-locations";
import ProjectName from "~/src/features/project-infos/project-name";
import ProjectNameAdditional from "~/src/features/project-infos/project-name-additional";
import ProjectWidmungen from "~/src/features/project-infos/project-widmungen";
import PublicProjectNotes from "~/src/features/project-infos/public-project-notes";
import useProject from "~/src/hooks/use-project";
import TabHeader from "~/src/ui/headers/tab-header";
import Skeleton from "~/src/ui/loading/item-skeleton";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const ProjectInfosPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  // TODO: Switch to slide for every form

  return (
    <>
      <TabHeader>Allgemeine Projektdaten</TabHeader>

      <div className="divide-y divide-gray-200">
        <ProjectName />

        <ProjectNameAdditional />

        <ProjectDescription />

        <ProjectImage />

        <PublicProjectNotes />

        <ProjectLocations />

        <ProjectEinlagen />

        <ProjectWidmungen />

        <ProjectCertificates />

        <ProjectEnergyClasses />
      </div>
    </>
  );
};

export default ProjectInfosPage;
