import Nav from "~/src/features/nav";
import ActivitiesPage from "~/src/pages/activities";
import AutomatedProjectsPage from "~/src/pages/automated-projects";
import AutomatedProjectPage from "~/src/pages/automated-projects/[id]";
import CompaniesPage from "~/src/pages/companies";
import ContractsPage from "~/src/pages/contracts";
import ErrorPage from "~/src/pages/error";
import FeedPage from "~/src/pages/feed";
import Login from "~/src/pages/login";
import NotFoundPage from "~/src/pages/not-found";
import ProjectsPage from "~/src/pages/projects";
import ProjectPage from "~/src/pages/projects/[id]";
import Users from "~/src/pages/users-page";
import ProtectedRoute from "~/src/ui/routes/protected-route";
import {
  Redirect, Route, BrowserRouter as Router, Switch
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import CertificatesPage from "./pages/certificates";
import UnitBuyerDifferencesPage from "./pages/unit-buyer-differences.jsx";

/**
 *
 * @example
 */
const App = () => (
  <Router>
    <QueryParamProvider ReactRouterRoute={Route}>
      <div>
        <Nav />

        <Switch>

          <ProtectedRoute exact path="/">
            <Redirect to="projects" />
          </ProtectedRoute>

          <Route path="/login">
            <Login />
          </Route>

          <ProtectedRoute exact path="/projects">
            <ProjectsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/projects/:id">
            <ProjectPage />
          </ProtectedRoute>

          <ProtectedRoute path="/users">
            <Users />
          </ProtectedRoute>

          <ProtectedRoute exact path="/feed">
            <FeedPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/unit-buyer-differences">
            <UnitBuyerDifferencesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/contracts">
            <ContractsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/companies">
            <CompaniesPage />
          </ProtectedRoute>

          <ProtectedRoute path="/certificates">
            <CertificatesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/activities">
            <ActivitiesPage />
          </ProtectedRoute>

          <ProtectedRoute path="/companies">
            <CompaniesPage />
          </ProtectedRoute>

          <ProtectedRoute exact path="/automated-projects">
            <AutomatedProjectsPage />
          </ProtectedRoute>

          <ProtectedRoute path="/automated-projects/:id">
            <AutomatedProjectPage />
          </ProtectedRoute>

          <Route path="/error">
            <ErrorPage />
          </Route>

          <Route path="*">
            <NotFoundPage />
          </Route>

        </Switch>
      </div>
    </QueryParamProvider>
  </Router>
);

export default App;
