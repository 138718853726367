import { Menu } from "@headlessui/react";
import useAuth from "~/src/hooks/use-auth";
import API from "~/src/modules/api";
import DropdownTransition from "~/src/ui/transitions/dropdown-transition";
import { Link } from "react-router-dom";

const SettingsDropdown = () => {
  const {
    isAdmin, isMod, mutate, user
  } = useAuth();

  // TODO [2024-05-01]: replace this with better role
  const isChristoph = user?.email === "mayer@rsgroup.at";

  const handleLogout = async () => {
    await API.post("/auth/logout");
    await mutate();
    window.location.replace("/login");
  };

  return (
    <div className="relative z-10 ml-3 flex items-center">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:text-gray-200 focus:text-gray-100 focus:outline-none">
              <span className="sr-only">Open main menu</span>

              <svg aria-hidden="true" className="block size-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path d="M4 6h16M4 12h16M4 18h16" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
              </svg>
            </Menu.Button>

            <DropdownTransition open={open}>

              <Menu.Items static>
                <div aria-labelledby="user-menu" aria-orientation="vertical" className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5" role="menu">
                  <div className="border-b border-gray-300 px-4 pb-2 pt-1">
                    <p className="text-base font-medium text-gray-800">Benutzer</p>

                    <p className="text-sm font-medium text-gray-500">{user?.username}</p>
                  </div>

                  <div className="border-b border-gray-300 pb-1 xl:hidden">

                    <Menu.Item>
                      <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/automated-projects">
                        Automatisierte Projekte
                      </Link>
                    </Menu.Item>

                    <Menu.Item>
                      <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/projects">
                        Projekte
                      </Link>
                    </Menu.Item>

                    <Menu.Item>
                      <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/feed">
                        KV-Feed
                      </Link>
                    </Menu.Item>

                    <Menu.Item>
                      <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/companies">
                        Unternehmen
                      </Link>
                    </Menu.Item>

                    <Menu.Item>
                      <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/activities">
                        Aktivitäten
                      </Link>
                    </Menu.Item>

                    <Menu.Item>
                      <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/contracts">
                        KV-Suche
                      </Link>
                    </Menu.Item>

                    {isChristoph
                      ? (
                        <Menu.Item>
                          <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/unit-buyer-differences">
                            Käufer-Differenzen
                          </Link>
                        </Menu.Item>
                      )
                      : null}
                  </div>

                  {isMod
                    ? (
                      <Menu.Item>
                        <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" role="menuitem" to="/users">
                          Benutzerverwaltung
                        </Link>
                      </Menu.Item>
                    )
                    : null}

                  <Menu.Item>
                    <Link className="mt-1 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none sm:hidden" role="menuitem" to="/companies">
                      Unternehmen
                    </Link>
                  </Menu.Item>

                  <Menu.Item>
                    <button className="flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none" onClick={handleLogout} role="menuitem">
                      Logout
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </DropdownTransition>

          </>
        )}
      </Menu>
    </div>
  );
};

export default SettingsDropdown;
