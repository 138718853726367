import useAuth from "~/src/hooks/use-auth";
import { formatDate } from "~/src/modules/formatters";
import LockBadgeSmall from "~/src/ui/badges/lock-badge-small";
import Badge from "~/src/ui/badges/status-badge";
import Skeleton from "~/src/ui/loading/item-skeleton";
import ProjectLink from "~/src/ui/project-link";
import ProjectLocation from "~/src/ui/project-location";
import cn from "classnames";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import {
  colBadge, colDateEdited, colId, colProjectLocations, headerItem, tableBody, tableContainer
} from "./styles.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.automated - The root object
 * @param props0.isLoading - The root object
 * @param props0.projects - The root object
 * @example
 */
const ProjectList = ({
  automated = false, isLoading, projects
}) => {
  const { user } = useAuth();

  if (isLoading) {
    return <Skeleton />;
  }

  const route = automated ? "automated-projects" : "projects";

  return (
    <div className={tableContainer}>

      <div className="hidden grid-cols-16 border-b-2 border-gray-200 md:grid">

        <div className={cn(headerItem, "px-4 py-3 col-span-1 md:col-span-6 grid grid-cols-8")}>
          <div className={cn(headerItem, "col-span-2 xl:col-span-1 pr-1.5")}>
            <p>ID</p>
          </div>

          <p>Name</p>
        </div>

        <div className={cn(headerItem, "px-4 py-3 col-span-1 md:col-span-5 xl:col-span-6")}>
          <p>Adresse</p>
        </div>

        <div className={cn(headerItem, "px-4 py-3 col-span-3 xl:col-span-2")}>
          <p>Bearbeitet</p>
        </div>

        <div className={cn(headerItem, "px-4 py-3 col-span-2")}>
          <p>Status</p>
        </div>

      </div>

      <div className={tableBody}>
        {projects?.map((project) => {
          const isProjectEditor = project?.active_project_editor && project.active_project_editor.email === user?.email;
          const isProjectLocked = Boolean(project?.active_project_editor);
          const isUnitEditor = project?.active_unit_editor && project.active_unit_editor.email === user?.email;
          const isUnitLocked = Boolean(project?.active_unit_editor);

          return (
            <div className="col-span-1 flex flex-col truncate md:grid md:grid-cols-16" key={`projects_${project.id}`}>
              <div className="relative col-span-1 grid grid-cols-8 truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-6">
                <div className={colId}>
                  {project?.id}
                </div>

                <div>
                  <ProjectLink
                    {...{
                      automated,
                      project
                    }}
                  />

                  {isProjectLocked
                    ? (
                      <div className="mt-1 flex items-center">
                        <LockBadgeSmall isEditor={isProjectEditor} isLocked={isProjectLocked} />

                        <span className="ml-1">{project?.active_project_editor?.username} (Projekterfassung)</span>
                      </div>
                    )
                    : null}

                  {isUnitLocked
                    ? (
                      <div className="mt-1 flex items-center">
                        <LockBadgeSmall isEditor={isUnitEditor} isLocked={isUnitLocked} />

                        <span className="ml-1">{project?.active_unit_editor?.username} (Objekterfassung)</span>
                      </div>
                    )
                    : null}
                </div>
              </div>

              <div className={colProjectLocations}>
                {
                  [...project?.locations?.slice(0, 3)]
                    .sort(({ default_location: defaultA }, { default_location: defaultB }) => (defaultA ? -1 : 0))
                    .map((location, index, array) => (
                      <Fragment key={`location_${location.id}`}>
                        <ProjectLocation location={location} />

                        {(index === array.length - 1 && project?.locations.length > array.length) && (
                          <Link className="underline" to={`/${route}/${project.id}/project-infos`}>...</Link>
                        )}
                      </Fragment>
                    ))
                }
              </div>

              <div className={colDateEdited}>
                <p>
                  {formatDate({
                    date: project.updated_at,
                    withTime: true
                  })}
                </p>

                {project?.last_review_should_filter
                  ? (
                    <div className="mt-2">
                      <p className="text-xs font-medium text-gray-500">Letzte Projektkontrolle</p>

                      <p>
                        {project.last_review_date
                          ? formatDate({
                            date: project.last_review_date,
                            withTime: true
                          })
                          : "-"}
                      </p>
                    </div>
                  )
                  : null}

                {project?.last_unit_review_should_filter
                  ? (
                    <div className="mt-2">
                      <p className="text-xs font-medium text-gray-500">Letzte Objektkontrolle</p>

                      <p>
                        {project.last_unit_offer_review_date
                          ? formatDate({
                            date: project.last_unit_offer_review_date,
                            withTime: true
                          })
                          : "-"}
                      </p>
                    </div>
                  )
                  : null}

              </div>

              <div className={colBadge}>
                {
                  project.published
                    ? <Badge badgeFor="public" text="Öffentlich" />
                    : <Badge badgeFor="internal" text="Intern" />
                }

                {(project.published && project.unpublished_changes) ? <Badge badgeFor="changes" text="Offene Änderungen" /> : null}
              </div>

            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectList;
