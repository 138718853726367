import { Transition } from "@headlessui/react";
import SlidePortal from "~/src/ui/slides/portal";
import React from "react";
// import useKeyPress from "~/src/hooks/use-key-press"

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @param props0.hide - The root object
 * @param props0.keyboardEnabled - The root object
 * @param props0.visible - The root object
 * @example
 */
const SlidesContainer = ({
  children, hide = () => { }, keyboardEnabled = true, visible
}) =>
// useKeyPress("Escape", hide, keyboardEnabled)

  (
    <SlidePortal>

      <div className={`${visible ? "pointer-events-auto" : "pointer-events-none"} fixed inset-0 z-40 overflow-hidden`}>
        <div className="absolute inset-0 overflow-hidden">
          <section className="absolute inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">

            <Transition
              as={React.Fragment}
              enter="transform transition ease-in-out"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-100"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              show={visible}
            >

              <div data-slide className="w-screen max-w-3xl">
                {children}
              </div>

            </Transition>
          </section>
        </div>
      </div>

    </SlidePortal>
  );

export default SlidesContainer;
