import useAutomatedProject from "~/src/hooks/use-automated-project";
import useNotification from "~/src/hooks/use-notification";
import validationResolver from "~/src/modules/validation-resolver";
import CancelFormButton from "~/src/ui/buttons/cancel-form-button";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import InlineInput from "~/src/ui/forms/inline-input/index.new";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { handleUpdate } from "./handlers";
import schema, { generateEditValues, initialValues } from "./schema";

/**
 *
 * @param props0 - The root object
 * @param props0.projectId - The root object
 * @param props0.setEditing - The root object
 * @example
 */
const AutomatedProjectNameForm = ({ projectId, setEditing }) => {
  const { automated_project: project, mutate } = useAutomatedProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    formState: { errors }, handleSubmit, register, reset
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    if (project) {
      const { name } = project;

      reset(generateEditValues({ name }));
    }
  }, [reset, project]);

  const handleClose = () => {
    reset();
    setEditing(false);
  };

  const onSubmit = (data) => {
    handleUpdate({
      data,
      handleClose,
      mutate,
      projectId: project.id,
      setIsLoading,
      showError,
      showSuccess
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

      <InlineInput
        name="name"
        {...{
          errors,
          register
        }}
      />

      <div className="mt-8">
        <FormSubmitButton isSubmitting={formIsLoading}>
          Speichern
        </FormSubmitButton>

        <CancelFormButton onClick={handleClose} />
      </div>

    </form>
  );
};

export default AutomatedProjectNameForm;
