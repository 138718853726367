import API from "~/src/modules/api";
import AsyncSelect from "~/src/ui/selects/custom-async-select";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.error - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @param props0.value - The root object
 * @example
 */
const KGSelect = ({
  id = "kgNumber",
  error,
  isSubmitting,
  onChange,
  placeholder = "",
  value
}) => {
  const showError = error && isSubmitting;

  return (
    <div>
      <AsyncSelect
        customValueKey="body"
        error={showError}
        id={id}
        loadOptions={loadOptions}
        name="katastralgemeinde"
        noOptions="KG eingeben..."
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={{
          label: value?.id !== "" && value?.id !== undefined ? `${value.code} ${value.name} (${value.bgCode} ${value.bgName})` : "",
          value: value?.id || ""
        }}
      />

      {showError ? <p className="mt-2 text-sm text-red-600">{error.id?.message}</p> : null}
    </div>
  );
};

export default KGSelect;

/**
 *
 * @param inputValue
 * @param callback
 * @example
 */
async function loadOptions(inputValue, callback) {
  API.post("/katastralgemeinden/search", {
    q: inputValue
  }).then((res) => {
    const options = res.data.payload.katastralgemeinden.map((kg) => ({
      body: {
        id: kg.id,
        bgCode: kg.bezirksgericht.code,
        bgName: kg.bezirksgericht.name,
        code: kg.code,
        name: kg.name
      },
      label: `${kg.code} ${kg.name} (BG ${kg.bezirksgericht.code} ${kg.bezirksgericht.name})`,
      value: kg.id
    }));

    callback(options);
  }).catch((error) => {
    console.log(error);
  });
}
