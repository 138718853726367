import API from "~/src/modules/api";
import { formatYear, validDate } from "~/src/modules/formatters";

/**
 *
 * @param options0 - The root object
 * @param options0.data - The root object
 * @param options0.handleClose - The root object
 * @param options0.mutate - The root object
 * @param options0.setIsLoading - The root object
 * @param options0.showError - The root object
 * @param options0.unitId - The root object
 * @example
 */
const handleUpdate = ({
  data, handleClose, mutate, setIsLoading, showError, unitId
}) => {
  const automated_unit = generateUnitObject(data);

  setIsLoading(true);

  API.patch(`/automated-units/${unitId}`, { automated_unit })
    .then((res) => {
      mutate();
      handleClose();
    }).catch((error) => {
      showError(error);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

/**
 *
 * @param unitId
 * @param setIsSubmitting
 * @param mutate
 * @param handleClose
 * @param showError
 * @example
 */
const handleDelete = (unitId, setIsSubmitting, mutate, handleClose, showError) => {
  setIsSubmitting(true);
  API.delete(`/automated_units/${unitId}`)
    .then((res) => {
      setIsSubmitting(false);
      handleClose();
      mutate();
    })
    .catch((error) => {
      setIsSubmitting(false);
      showError();
    });
};

/**
 *
 * @param object
 * @example
 */
function removeEmptyValues(object) {
  return Object.fromEntries(Object.entries(object).filter(([key, value]) => value !== "" && value !== null));
}

/**
 *
 * @param values
 * @example
 */
function generateUnitObject(values) {
  return { show: values.show || false };
}

export {
  handleDelete,
  handleUpdate
};
