import Joi from "joi";

export const initialValues = {
  current_construction_phase: {
    label: "",
    value: "unknown"
  },
  current_marketing_phase: {
    label: "",
    value: "unknown"
  },

  construction_phase_purchase_date: "",
  construction_phase_purchase_date_format: {
    label: "",
    value: ""
  },

  construction_phase_active_date: "",
  construction_phase_active_date_format: {
    label: "",
    value: ""
  },

  construction_phase_completed_date: "",
  construction_phase_completed_date_format: {
    label: "",
    value: ""
  },

  marketing_phase_initial_date: "",
  marketing_phase_initial_date_format: {
    label: "",
    value: ""
  },

  marketing_phase_coming_soon_date: "",
  marketing_phase_coming_soon_date_format: {
    label: "",
    value: ""
  },

  marketing_phase_active_date: "",
  marketing_phase_active_date_format: {
    label: "",
    value: ""
  },

  marketing_phase_completed_apartments_date: "",
  marketing_phase_completed_apartments_date_format: {
    label: "",
    value: ""
  },

  marketing_phase_completed_all_date: "",
  marketing_phase_completed_all_date_format: {
    label: "",
    value: ""
  },

  marketing_phase_project_finished_date: "",
  marketing_phase_project_finished_date_format: {
    label: "",
    value: ""
  }

};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project?.timeline) {
    const { timeline } = project;

    return {
      current_construction_phase: {
        value: timeline.current_construction_phase || "unknown"
      },
      current_marketing_phase: {
        value: timeline.current_marketing_phase || "unknown"
      },

      construction_phase_purchase_date: timeline.construction_phase_purchase_date,
      construction_phase_purchase_date_format: {
        value: timeline.construction_phase_purchase_date_format || "month"
      },

      construction_phase_active_date: timeline.construction_phase_active_date,
      construction_phase_active_date_format: {
        value: timeline.construction_phase_active_date_format || "month"
      },

      construction_phase_completed_date: timeline.construction_phase_completed_date,
      construction_phase_completed_date_format: {
        value: timeline.construction_phase_completed_date_format || "month"
      },

      marketing_phase_initial_date: timeline.marketing_phase_initial_date,
      marketing_phase_initial_date_format: {
        value: timeline.marketing_phase_initial_date_format || "month"
      },

      marketing_phase_coming_soon_date: timeline.marketing_phase_coming_soon_date,
      marketing_phase_coming_soon_date_format: {
        value: timeline.marketing_phase_coming_soon_date_format || "month"
      },

      marketing_phase_active_date: timeline.marketing_phase_active_date,
      marketing_phase_active_date_format: {
        value: timeline.marketing_phase_active_date_format || "month"
      },

      marketing_phase_completed_apartments_date: timeline.marketing_phase_completed_apartments_date,
      marketing_phase_completed_apartments_date_format: {
        value: timeline.marketing_phase_completed_apartments_date_format || "month"
      },

      marketing_phase_completed_all_date: timeline.marketing_phase_completed_all_date,
      marketing_phase_completed_all_date_format: {
        value: timeline.marketing_phase_completed_all_date_format || "month"
      },

      marketing_phase_project_finished_date: timeline.marketing_phase_project_finished_date,
      marketing_phase_project_finished_date_format: {
        value: timeline.marketing_phase_project_finished_date_format || "month"
      }

    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  current_construction_phase: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("").required()
  }),
  current_marketing_phase: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("").required()
  }),

  construction_phase_purchase_date: Joi.date().empty("").allow(null),
  construction_phase_purchase_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),

  construction_phase_active_date: Joi.date().empty("").allow(null),
  construction_phase_active_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),

  construction_phase_completed_date: Joi.date().empty("").allow(null),
  construction_phase_completed_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),

  marketing_phase_initial_date: Joi.date().empty("").allow(null),
  marketing_phase_initial_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),

  marketing_phase_coming_soon_date: Joi.date().empty("").allow(null),
  marketing_phase_coming_soon_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),

  marketing_phase_active_date: Joi.date().empty("").allow(null),
  marketing_phase_active_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),

  marketing_phase_completed_all_date: Joi.date().empty("").allow(null),
  marketing_phase_completed_all_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),
  marketing_phase_completed_apartments_date: Joi.date().empty("").allow(null),
  marketing_phase_completed_apartments_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }),
  marketing_phase_project_finished_date: Joi.date().empty("").allow(null),
  marketing_phase_project_finished_date_format: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  })

});
