import AutomatedProjectEinlagen from "~/src/features/automated-project-overview/automated-project-einlagen";
import AutomatedProjectEnabled from "~/src/features/automated-project-overview/automated-project-enabled";
import AutomatedProjectLocations from "~/src/features/automated-project-overview/automated-project-locations";
import AutomatedProjectName from "~/src/features/automated-project-overview/automated-project-name";
import AutomatedProjectNameAdditional from "~/src/features/automated-project-overview/automated-project-name-additional";
import AutomatedProjectNotes from "~/src/features/automated-project-overview/automated-project-notes";
import AutomatedProjectOwners from "~/src/features/automated-project-overview/automated-project-owners";
import AutomatedProjectPublishOverview from "~/src/features/automated-project-overview/automated-project-publish-overview";
import AutomatedProjectQuantities from "~/src/features/automated-project-overview/automated-project-quantities";
import AutomatedProjectRechtsform from "~/src/features/automated-project-overview/automated-project-rechtsform";
import AutomatedProjectTimeline from "~/src/features/automated-project-overview/automated-project-timeline";
import AutomatedPublicProjectNotes from "~/src/features/automated-project-overview/automated-public-project-notes";
import useAutomatedProject from "~/src/hooks/use-automated-project";
import TabHeader from "~/src/ui/headers/tab-header";
import Skeleton from "~/src/ui/loading/item-skeleton";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const AutomatedProjectOverviewPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useAutomatedProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return <Skeleton />;
  }

  return (
    <>
      <TabHeader>Übersicht</TabHeader>

      <ul className="mt-12 grid grid-cols-12 gap-2">
        <AutomatedProjectPublishOverview />
      </ul>

      <div className="mt-12 divide-y divide-gray-200">
        <AutomatedProjectEnabled />

        <AutomatedProjectName />

        <AutomatedProjectNameAdditional />

        <AutomatedProjectNotes />

        <AutomatedProjectLocations />

        <AutomatedProjectOwners />

        <AutomatedProjectEinlagen />

        <AutomatedProjectRechtsform />

        <AutomatedProjectQuantities />

        <AutomatedProjectTimeline />
      </div>
    </>
  );
};

export default AutomatedProjectOverviewPage;
