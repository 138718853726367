import cn from "classnames";
import NumberFormat from "react-number-format";

import { errorIcon, input } from "./styles.module.css";

/**
 *
 * @param root0 - The root object
 * @param root0.id - The root object
 * @param root0.name - The root object
 * @param root0.value - The root object
 * @param root0.touched - The root object
 * @param root0.error - The root object
 * @param root0.onChange - The root object
 * @param root0.onBlur - The root object
 * @param root0.isSubmitting - The root object
 * @param root0.placeholder - The root object
 * @param root0.autoFocus - The root object
 * @param root0.area - The root object
 * @param root0.int - The root object
 * @param root0.scale - The root object
 * @param root0.meter - The root object
 * @param root0.noprefix - The root object
 * @param root0.thousandSeparator - The root object
 * @example
 */
export default function NumberInput({
  id,
  area = false,
  autoFocus = false,
  error,
  int = false,
  isSubmitting,
  meter = false,
  name,
  noprefix = false,
  onBlur,
  onChange,
  placeholder,
  scale = null,
  thousandSeparator = ".",
  touched,
  value
}) {
  const touchedAndNotEmpty = (Boolean(touched) === true) && value !== "";
  const showError = error && (isSubmitting || touchedAndNotEmpty);

  /**
   *
   * @param values
   * @example
   */
  function handleChange(values) {
    const event = {
      target: {
        name,
        type: "custom-select",
        value: values.value
      }
    };

    onChange(event);
  }

  let decimalScale = int ? 0 : 2;

  if (scale) {
    decimalScale = scale;
  }

  if (scale === "none") {
    decimalScale = undefined;
  }

  let prefix = "€ ";

  if (noprefix || int || area || meter) {
    prefix = "";
  }

  let suffix = "";

  if (area) {
    suffix = " m²";
  }
  if (meter) {
    suffix = " m";
  }

  return (
    <>
      <div className="relative">
        <NumberFormat
          fixedDecimalScale
          isNumericString
          autoFocus={autoFocus}
          decimalScale={decimalScale}
          decimalSeparator=","
          id={id}
          name={name}
          onBlur={onBlur}
          onValueChange={handleChange}
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          thousandSeparator={thousandSeparator}
          value={value}
          className={cn(input, {
            "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError,
            "border-red-500": showError
          })}
        />

        {showError
          ? (
            <div className={errorIcon}>
              <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
              </svg>
            </div>
          )
          : null}
      </div>

      {showError ? <p className="mt-2 text-sm text-red-600">{error.message}</p> : null}
    </>
  );
}
