import { camelCase } from "lodash-es";

import {
  automatedProject,
  automatedUnit,
  buildingPart,
  companyRole,
  constructionInfo,
  constructionType,
  einlage,
  energyClass,
  funding,
  location,
  project,
  projectCertificate,
  projectContractRegion,
  projectDocument,
  projectQuantity,
  timeline,
  transaction,
  unit,
  unitDocument,
  widmung
} from "./types";

const activityTransforms = {
  automatedProject,
  automatedUnit,
  buildingPart,
  companyRole,
  constructionInfo,
  constructionType,
  einlage,
  energyClass,
  funding,
  location,
  project,
  projectCertificate,
  projectContractRegion,
  projectDocument,
  projectQuantity,
  timeline,
  transaction,
  unit,
  unitDocument,
  widmung
};

const formatActivityChange = ({
  activityType, key, otherValue, side, value
}) => {
  const formatter = activityTransforms?.[camelCase(activityType)]?.[key];

  return formatter
    ? {
      key,
      unitOffer: false,
      unitSale: false,
      ...formatter(value, otherValue, side)
    }
    : {
      key,
      label: key,
      unitOffer: false,
      unitSale: false,
      value: String(value)
    };
};

export default formatActivityChange;
