import { validationErrors } from "./errors";

const validationResolver = (validationSchema) => async (data, context) => {
  const { error, value: values } = validationSchema.validate(data, {
    abortEarly: false
  });

  if (!error) {
    return {
      errors: {},
      values
    };
  }
  console.log("Form validation", { error });

  return {
    errors: Object.fromEntries(error.details.map(
      (currentError) => [
        currentError.path[0] ?? currentError.context.peer,
        {
          ...currentError,
          message: validationErrors(currentError.type)
        }
      ]
    )),
    values: {}
  };
};

export default validationResolver;
