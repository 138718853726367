/* eslint-disable max-lines-per-function */
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import validationResolver from "~/src/modules/validation-resolver";
import CancelButton from "~/src/ui/buttons/cancel-button";
import DateField from "~/src/ui/forms/date-field";
import Field from "~/src/ui/forms/field";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import RangeField from "~/src/ui/forms/range-field";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import SlideOver from "~/src/ui/slides/container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { handleCreate, handleUpdate } from "./handlers";
import schema, { generateEditValues, initialValues } from "./schema";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.projectId - The root object
 * @param props0.visible - The root object
 * @param props0.widmung - The root object
 * @example
 */
const NewWidmungSlide = ({
  hide, projectId, visible, widmung
}) => {
  const { mutate } = useProject(projectId);

  const [formIsLoading, setIsLoading] = useState(false);

  const { showError, showSuccess } = useNotification();

  const {
    control, formState: { errors }, handleSubmit, register, reset, watch
  } = useForm({
    defaultValues: initialValues,
    resolver: validationResolver(schema)
  });

  useEffect(() => {
    if (widmung) {
      reset(generateEditValues(widmung));
    }
  }, [reset, widmung]);

  const handleClose = () => {
    reset(initialValues);
    hide();
  };

  const onSubmit = (data) => {
    if (widmung) {
      handleUpdate({
        id: widmung.id,
        data,
        handleClose,
        mutate,
        setIsLoading,
        showError,
        showSuccess
      });
    }
    else {
      handleCreate({
        data,
        handleClose,
        mutate,
        projectId,
        setIsLoading,
        showError,
        showSuccess
      });
    }
  };

  return (
    <SlideOver hide={handleClose} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={formIsLoading} handleHide={handleClose} title={`Flächenwidmung ${widmung ? "bearbeiten" : "erstellen"}`} />

          <Field
            label="Plandokument-Nr. *"
            name="plan_number"
            {...{
              errors,
              register
            }}
          />

          <DateField
            label="Datum Kundmachung *"
            name="publish_date"
            {...{
              control,
              errors,
              register
            }}
          />

          <Field
            label="Flächenwidmung"
            name="widmung"
            {...{
              errors,
              register
            }}
          />

          <Field
            label="Bauklasse"
            name="bauklasse"
            {...{
              errors,
              register
            }}
          />

          <RangeField
            meter
            optional
            label="Höhenbeschränkung"
            name="height_limit"
            labels={{
              checkbox: "von-bis",
              to: "Höhenbeschränkung bis"
            }}
            prefix={(
              <select
                className="mr-1 block h-full w-16 rounded-sm border-gray-300 text-sm focus:border-gray-300 focus:outline-none focus:ring-0 sm:text-xs"
                {...register("height_limit_sign")}
              >
                <option value="" />

                <option value="plus">+</option>
              </select>
            )}
            {...{
              control,
              errors,
              register,
              watch
            }}
          />

          <Field
            label="Bauweise"
            name="bauweise"
            {...{
              errors,
              register
            }}
          />

          <Field
            label="Zusätzliche Bestimmungen"
            name="additional"
            {...{
              errors,
              register
            }}
          />
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={formIsLoading} onClick={handleClose} />

            <FormSubmitButton isSubmitting={formIsLoading}>
              Speichern
            </FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default NewWidmungSlide;
