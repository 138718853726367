import Joi from "joi";

export const initialValues = {
  gka_area: "",
  gka_notes: "",
  show_gka: false
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    const {
      gka_area, gka_notes, show_gka
    } = project;

    return {
      gka_area: gka_area || "",
      gka_notes: gka_notes || "",
      show_gka
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  gka_area: Joi.number().positive().empty("")
    .optional(),
  gka_notes: Joi.string().empty("").optional(),
  show_gka: Joi.boolean().required().when(Joi.ref("$transactions", {
    adjust: (value) => !value || value.every(({ usable }) => !usable)
  }), {
    is: true,
    otherwise: Joi.boolean().required(),
    then: Joi.boolean().valid(false).error((errors) => {
      const error = errors.find(({ path }) => path[0] === "show_gka");

      if (error) {
        error.code = "boolean.show_gka.no_active_transactions";
      }

      return error;
    })
  })
});
