import ProjectSettings from "~/src/features/project-settings";
import DeleteProjectModal from "~/src/features/project-settings/delete-project-modal";
import useProject from "~/src/hooks/use-project";
import TabHeader from "~/src/ui/headers/tab-header";
import Skeleton from "~/src/ui/loading/item-skeleton";
import { useState } from "react";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const SettingsPage = () => {
  const { id } = useParams();
  const {
    isError, isLoading, project
  } = useProject(id);
  const [showModal, setShowModal] = useState(false);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return <Skeleton />;
  }

  return (
    <div>
      <DeleteProjectModal hide={() => setShowModal(false)} project={project} show={showModal} />

      <TabHeader>Einstellungen</TabHeader>

      <div>
        <ProjectSettings />
      </div>

    </div>
  );
};

export default SettingsPage;
