/* eslint-disable max-lines-per-function */
import useForm from "~/src/hooks/use-form";
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import CancelButton from "~/src/ui/buttons/cancel-button";
import FormSubmitButton from "~/src/ui/forms/form-submit-button";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox";
import InlineInput from "~/src/ui/forms/inline-input";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import SlideOver from "~/src/ui/slides/container";
import AddPublicName from "~/src/ui/upload/single-file-upload-field/add-public-name";

import { handleUpdate } from "./handlers";
import { generateEditValues, singleSchema } from "./schema";

const EditDocumentSlide = ({
  document, hide, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const initialValues = generateEditValues(document);

  const handleHide = (innerForm) => {
    hide();
    innerForm.resetForm();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: document.id,
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema: singleSchema
  });

  const fillPublicName = (value) => {
    form.updateProperties({
      publicName: value
    });
  };

  return (
    <SlideOver hide={() => handleHide(form)} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={form.isLoading} handleHide={() => handleHide(form)} title="Dokument bearbeiten" />

          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="showPublic">
                Sichtbarkeit
              </label>
            </div>

            <div className="sm:col-span-2">
              <InlineCheckbox
                error={form.errors.showPublic}
                id="showPublic"
                isSubmitting={form.isSubmitting}
                label="öffentlich anzeigen"
                name="showPublic"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                touched={form.touched.showPublic}
                value={form.values.showPublic}
              />
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="publicName">
                  Öffentlicher Dateiname &#42;
                </label>
              </div>

              <div className="sm:col-span-2">
                <InlineInput
                  error={form.errors.publicName}
                  id="publicName"
                  isSubmitting={form.isSubmitting}
                  name="publicName"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Dateiname"
                  touched={form.touched.publicName}
                  value={form.values.publicName}
                />
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="hidden sm:col-span-1 sm:block" />

              <div className="sm:col-span-2">
                <h4 className="text-sm font-medium text-gray-700">Vorschläge:</h4>

                <ul className="mt-1 list-disc pl-8 text-sm">
                  <AddPublicName onClick={fillPublicName} value="Flächenwidmung" />

                  <AddPublicName onClick={fillPublicName} value="Energieausweis" />

                  <AddPublicName onClick={fillPublicName} value="Grundstücke" />

                  <AddPublicName onClick={fillPublicName} value="Exposé" />

                  <AddPublicName onClick={fillPublicName} value="Preisliste" />

                  <AddPublicName onClick={fillPublicName} value="Preisliste (Eigennutzer)" />

                  <AddPublicName onClick={fillPublicName} value="Preisliste (Anleger)" />
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <CancelButton disabled={form.isLoading} onClick={() => handleHide(form)} />

            <FormSubmitButton isSubmitting={form.isLoading}>Speichern</FormSubmitButton>
          </div>
        </div>
      </form>
    </SlideOver>
  );
};

export default EditDocumentSlide;
