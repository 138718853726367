import API from "~/src/modules/api";
import AsyncSelect from "~/src/ui/selects/custom-async-select";

/**
 *
 * @param props0 - The root object
 * @param props0.defaultValue - The root object
 * @param props0.error - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.onChange - The root object
 * @example
 */
const UnitCategorySelect = ({
  defaultValue, error, isSubmitting, onChange
}) => {
  const showError = error && isSubmitting;

  /**
   *
   * @param inputValue
   * @param callback
   * @example
   */
  async function loadOptions(inputValue, callback) {
    API.get("/unit-categories")
      .then((res) => {
        const options = [];

        for (const category of res.data.payload.unit_categories) {
          if (category) {
            options.push({
              body: {
                id: category.id,
                code: category.code,
                name: category.name
              },
              label: category.name,
              value: category.id
            });
          }
        }

        callback(options);
      }).catch((error_) => {
        console.log(error_);
      });
  }

  return (
    <div>
      <AsyncSelect
        defaultOptions
        customValueKey="body"
        error={showError}
        loadOptions={loadOptions}
        name="unit_category"
        noOptions="Keine Optionen geladen"
        onChange={onChange}
        placeholder="Objektkategorie..."
        defaultValue={defaultValue?.id
          ? {
            label: defaultValue?.name || "",
            value: defaultValue?.id || ""
          }
          : null}
      />

      {showError ? <p className="mt-2 text-sm text-red-600">{error.id?.message}</p> : null}
    </div>
  );
};

export default UnitCategorySelect;
