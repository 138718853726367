.input {
  @apply block w-full border rounded-sm shadow-sm h-10 pl-9 focus:outline-none sm:text-sm placeholder-gray-300;
  padding-right: 72px;
}

.inputError {
  @apply border-red-300 text-red-900;
}

.errorIcon {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
}

.chip {
  @apply bg-gray-800 text-white rounded overflow-hidden text-ellipsis whitespace-nowrap absolute mx-3 px-1 ml-9 pointer-events-none;
  max-width: calc(100% - (36px * 3));
}