import dateFormatter from "~/src/modules/date-formatter";
import { marketingPhaseLabels } from "~/src/modules/labels";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import DescriptionTitle from "~/src/ui/titles/description-title";

/**
 *
 * @param props0 - The root object
 * @param props0.project - The root object
 * @example
 */
const MarketingTimeline = ({ project }) => (
  <TabListContainer>

    <div className="sm:grid sm:grid-cols-8 sm:gap-4">

      <DescriptionTitle title="Vermarktungs-Timeline" />

      <DescriptionContainer>
        <div className="grid grid-cols-4 gap-x-2 gap-y-4 bg-gray-100 p-2">

          <div className="col-span-4 border-b border-gray-300 pb-2">
            <p className="text-base font-medium text-gray-700">Aktueller Vermarktungsstatus</p>

            <p className="text-base">
              {project?.timeline?.current_marketing_phase ? marketingPhaseLabels[project?.timeline?.current_marketing_phase] : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">Coming soon</p>

            <p>
              {project?.timeline?.marketing_phase_coming_soon_date ? dateFormatter(project?.timeline?.marketing_phase_coming_soon_date, project?.timeline?.marketing_phase_coming_soon_date_format) : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">In Vermarktung</p>

            <p>
              {project?.timeline?.marketing_phase_active_date ? dateFormatter(project?.timeline?.marketing_phase_active_date, project?.timeline?.marketing_phase_active_date_format) : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">Wohneinheiten vermarktet</p>

            <p>
              {project?.timeline?.marketing_phase_completed_apartments_date ? dateFormatter(project?.timeline?.marketing_phase_completed_apartments_date, project?.timeline?.marketing_phase_completed_apartments_date_format) : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">Alle Einheiten vermarktet</p>

            <p>
              {project?.timeline?.marketing_phase_completed_all_date ? dateFormatter(project?.timeline?.marketing_phase_completed_all_date, project?.timeline?.marketing_phase_completed_all_date_format) : "-"}
            </p>
          </div>

          <div className="col-span-2">
            <p className="text-sm font-medium text-gray-700">Projekt abgeschlossen</p>

            <p>
              {project?.timeline?.marketing_phase_project_finished_date ? dateFormatter(project?.timeline?.marketing_phase_project_finished_date, project?.timeline?.marketing_phase_project_finished_date_format) : "-"}
            </p>
          </div>
        </div>
      </DescriptionContainer>

    </div>
  </TabListContainer>
);

export default MarketingTimeline;
