import { XIcon } from "@heroicons/react/solid";
import SubmitButton from "~/src/ui/buttons/submit-button";
import EditIcon from "~/src/ui/icons/edit-icon";

/**
 *
 * @param props0 - The root object
 * @param props0.editPossible - The root object
 * @param props0.editing - The root object
 * @param props0.onClick - The root object
 * @example
 */
const EditButton = ({
  editPossible, editing, onClick
}) => {
  if (editPossible) {
    return (
      <div className="mt-3 flex items-start justify-end sm:col-span-1 sm:mt-0">
        {
          editing
            ? (
              <SubmitButton onClick={onClick}>
                <XIcon className="size-5" />
              </SubmitButton>
            )
            : (
              <SubmitButton onClick={onClick}>
                <EditIcon className="size-5" />
              </SubmitButton>
            )
        }
      </div>
    );
  }

  return null;
};

export default EditButton;
