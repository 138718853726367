import { tableBody, tableContainer } from "~/src/features/company-list/styles.module.css";
import useAuth from "~/src/hooks/use-auth.js";
import Skeleton from "~/src/ui/loading/skeleton";

import { Entry, Header } from "./list/_exports.js";

const IssuersList = ({
  isLoading, issuers, modal, reactForm
}) => {
  if (isLoading) {
    return <Skeleton />;
  }

  const { isAdmin, user } = useAuth();

  return (
    <div className={tableContainer}>
      <Header />

      <div className={tableBody}>
        {issuers?.map((issuer) => (
          <Entry
            {...{
              isAdmin,
              issuer,
              issuersTempFrontEndValidation: issuers,
              modal,
              reactForm
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default IssuersList;
