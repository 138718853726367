import isEmpty from "./is-empty";
import normalActivity from "./normal-activity";

const collectionActivity = ({ innerLabels, label }) => (collectionJson, otherCollectionJson, side) => {
  const collection = typeof collectionJson === "string" ? JSON.parse(collectionJson) : collectionJson;
  const otherCollection = typeof otherCollectionJson === "string" ? JSON.parse(otherCollectionJson) : otherCollectionJson;

  const list = collection?.map((object, index) => innerLabels
    .filter(([key]) => object[key] !== undefined || otherCollection?.[index]?.[key] !== undefined)
    .filter(([key]) => object[key] !== otherCollection?.[index]?.[key])
    .map(([
      key,
      innerLabel,
      activityFunction = normalActivity
    ]) => {
      const display = activityFunction(innerLabel)(object[key], otherCollection?.[index]?.[key], side);

      return [
        key,
        innerLabel,
        display
      ];
    })
    .filter(([
      key,
      innerLabel,
      display
    ]) => !display.empty)
    .map(([
      key,
      innerLabel,
      display
    ]) => (
      <li key={key}>
        {innerLabel}: {display.value}
      </li>
    )));

  return {
    empty: isEmpty(collection) || list.every((item) => item.length === 0),
    label,
    value: (
      <ul className="flex flex-col gap-4 p-1 text-start">
        {
          collection?.map((object, index) => (
            <li className="relative bg-gray-100" key={index}>
              <span className="absolute right-1 top-1 text-gray-400">#{index}</span>

              <ul className="flex flex-col gap-1 py-2 pl-2 pr-4">
                {
                  innerLabels
                    .filter(([key]) => object[key] !== undefined || otherCollection?.[index]?.[key] !== undefined)
                    .filter(([key]) => ["name", "surname"].includes(key) || object[key] !== otherCollection?.[index]?.[key])
                    .map(([
                      key,
                      innerLabel,
                      activityFunction = normalActivity
                    ]) => {
                      const display = activityFunction(innerLabel)(object[key], otherCollection?.[index]?.[key], side);

                      return [
                        key,
                        innerLabel,
                        display
                      ];
                    })
                    .filter(([
                      key,
                      innerLabel,
                      display
                    ]) => ["name", "surname"].includes(key) || !display.empty)
                    .map(([
                      key,
                      innerLabel,
                      display
                    ]) => (
                      <li key={key}>
                        {innerLabel}: {display.value}
                      </li>
                    ))
                }
              </ul>
            </li>
          ))
        }

      </ul>
    )
  };
};

export default collectionActivity;
