import { formatArea } from "~/src/modules/formatters.new";

import customActivity from "./custom-activity";

const areaActivity = (label) => customActivity({
  label,
  value: formatArea
});

export default areaActivity;
