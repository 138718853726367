import API from "~/src/modules/api";
import { formatBytes } from "~/src/modules/formatters";
import InlineCheckbox from "~/src/ui/forms/inline-checkbox";
import axios from "axios";
import cn from "classnames";
import {
  forwardRef, useEffect, useImperativeHandle, useRef, useState
} from "react";
import AvatarEditor from "react-avatar-editor";

/**
 *
 * @param props0 - The root object
 * @param props0.blob - The root object
 * @param props0.fileName - The root object
 * @param props0.type - The root object
 * @example
 */
async function getSignedUrl({
  blob, fileName, type
}) {
  const file = {
    name: fileName,
    type: blob ? blob.type : type
  };

  return new Promise((resolve, reject) => {
    API.post("/files/sign-upload", { file })
      .then((res) => {
        resolve(res.data.payload.url);
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });
  });
}

const uploadFile = async (url, file, onProgress) => {
  const instance = axios.create();

  const formData = new FormData();

  formData.append("image", file.blob);

  const { data: newBlob } = await API.post(
    "/image/compress",
    formData,
    {
      responseType: "blob"
    }
  );

  await instance.put(url, newBlob, {
    headers: {
      "Content-Type": "image/png"
    },
    onUploadProgress: (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;

        onProgress(Math.round(percentage));
      }
    }
  });
};

/**
 *
 * @param props0 - The root object
 * @param props0.file - The root object
 * @param props0.onUpload - The root object
 * @param props0.setProgress - The root object
 * @example
 */
async function upload({
  file, onUpload, setProgress
}) {
  const signedUrl = await getSignedUrl(file);

  await uploadFile(signedUrl, file, setProgress);
  onUpload(file);
}

export default forwardRef(({
  file, form, index, onUpload
}, reference) => {
  const [progress, setProgress] = useState(0);
  const [scale, setScale] = useState(1);

  const imageReference = useRef(null);

  const editorReference = useRef(null);

  const wheelTimeout = useRef(null);

  const onWheel = ({ deltaY }) => {
    const newScale = Math.max(deltaY > 0 ? scale * 0.95 : scale * 1.05, 1);

    setScale(newScale);

    clearTimeout(wheelTimeout.current);

    wheelTimeout.current = setTimeout(() => {
      wheelTimeout.current = false;
    }, 300);
  };

  useEffect(() => {
    const cancelWheel = (e) => wheelTimeout.current && e.preventDefault();

    document.body.addEventListener("wheel", cancelWheel, { passive: false });

    return () => document.body.removeEventListener("wheel", cancelWheel);
  }, []);

  useImperativeHandle(reference, () => ({
    preSubmit: async () => {
      const croppedImageBlob = await new Promise((resolve, reject) => {
        editorReference.current?.getImageScaledToCanvas().toBlob((blob) => {
          resolve(blob);
        });
      });

      const croppedImageFile = {
        ...file,
        blob: croppedImageBlob
      };

      await upload({
        file: croppedImageFile,
        onUpload,
        setProgress
      });
    }
  }));

  const onImageLoad = (event) => {
    const { height, width } = event?.currentTarget;

    setImageSize({
      height,
      width
    });
  };

  const enableScroll = (event) => {
    document.removeEventListener(
      "wheel",
      () => {
        const currentEvent = event || window.event;

        if (currentEvent.preventDefault) {
          currentEvent.preventDefault();
        }
        currentEvent.returnValue = false;
      },
      false
    );
  };

  const disableScroll = (event) => {
    document.addEventListener(
      "wheel",
      () => {
        const currentEvent = event || window.event;

        if (currentEvent.preventDefault) {
          currentEvent.preventDefault();
        }
        currentEvent.returnValue = false;
      },
      { passive: false }
    );
  };

  return (
    <div
      className={cn("grid grid-cols-2 gap-x-8 p-4", {
        "bg-gray-50": index % 2 === 0,
        "border-t border-gray-300": index === 0
      })}
    >
      <div className="grid-cols-1">
        <div className="w-full">
          <AvatarEditor
            border={25}
            height={form.values.imageLandscape ? 160 : 256}
            image={file.preview}
            onMouseEnter={disableScroll}
            onMouseLeave={enableScroll}
            onWheel={onWheel}
            ref={editorReference}
            rotate={0}
            scale={scale}
            width={256}
            color={[
              255,
              255,
              255,
              0.8
            ]} // RGBA
          />
        </div>

        <div className="text-sm text-gray-700">
          <p>{file.name}</p>

          <p>{formatBytes(file.blob ? file.blob.size : file.size)}</p>

          <div className="mt-2 bg-gray-200 shadow">
            <div className="flex h-8 items-center justify-center bg-indigo-500 py-1 text-xs leading-none text-white" style={{ width: `${progress}%` }}>
              <span>
                {progress > 10 ? `${progress} %` : " "}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid items-center text-gray-700">
        <div>
          <div>
            <InlineCheckbox
              error={form.values.showPublic}
              id="showPublic"
              isSubmitting={form.isSubmitting}
              label="Projektbild öffentlich anzeigen"
              name="showPublic"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              touched={form.values.showPublic}
              value={form.values.showPublic || false}
            />
          </div>

          <div>
            <InlineCheckbox
              error={form.values.imageLandscape}
              id="imageLandscape"
              isSubmitting={form.isSubmitting}
              label="Bild im Querformat"
              name="imageLandscape"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              touched={form.values.imageLandscape}
              value={form.values.imageLandscape || false}
            />
          </div>
        </div>
      </div>

    </div>
  );
});
