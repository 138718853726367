import { Link } from "react-router-dom";

import { link } from "./style.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.automated - The root object
 * @param props0.project - The root object
 * @example
 */
const ProjectLink = ({ automated = false, project }) => {
  const route = automated ? "automated-projects" : "projects";

  const linkOptions = {
    pathname: `/${route}/${project.id}`,
    state: {
      project
    }
  };

  return (
    <Link className={link} to={linkOptions}>
      {project?.name}
    </Link>
  );
};

export default ProjectLink;
