import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import { researchFilterLabels } from "~/src/modules/labels";
import SlideFormFooter from "~/src/ui/footers/slide-form-footer/index.new";
import Select from "~/src/ui/forms/select/index.new";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import SlideOver from "~/src/ui/slides/container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { handleUpdate } from "./handlers";
import { generateEditValues, initialValues } from "./schema";

const projectResearchOptions = [
  {
    label: "kein Status",
    value: ""
  },
  {
    label: researchFilterLabels["project-research-initial"],
    value: "project-research-initial"
  },
  {
    label: researchFilterLabels["project-research-open"],
    value: "project-research-open"
  },
  {
    label: researchFilterLabels["project-research-closed"],
    value: "project-research-closed"
  }
];

const unitResearchOptions = [
  {
    label: "kein Status",
    value: ""
  },
  {
    label: researchFilterLabels["unit-research-initial"],
    value: "unit-research-initial"
  },
  {
    label: researchFilterLabels["unit-research-open"],
    value: "unit-research-open"
  },
  {
    label: researchFilterLabels["unit-research-closed"],
    value: "unit-research-closed"
  }
];

const ResearchStateSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError, showSuccess } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control, formState: { isSubmitting }, handleSubmit, reset
  } = useForm({
    defaultValues: initialValues,
    mode: "onChange"
  });

  useEffect(() => {
    if (project) {
      reset(generateEditValues(project));
    }
  }, [reset, project]);

  const handleHide = () => {
    reset();
    setIsLoading(false);
    hide();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    handleUpdate({
      id: project?.id,
      data,
      hide: handleHide,
      mutate,
      setIsLoading,
      showError,
      showSuccess
    });
  };

  return (
    <SlideOver hide={handleHide} visible={visible}>
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">
          <SlideFormHeader closeDisabled={isLoading} handleHide={handleHide} title="Erfassungsstatus bearbeiten" />

          <div className="m-4 space-y-6 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="project_research_filter">
                  Projekterfassung
                </label>
              </div>

              <div className="sm:col-span-2">
                <Select
                  name="project_research_filter"
                  options={projectResearchOptions}
                  {...{ control }}
                  className="col-span-2 mb-8"
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="unit_research_filter">
                  Objekterfassung
                </label>
              </div>

              <div className="sm:col-span-2">
                <Select
                  name="unit_research_filter"
                  options={unitResearchOptions}
                  {...{ control }}
                  className="col-span-2 mb-8"
                />
              </div>
            </div>
          </div>
        </div>

        <SlideFormFooter handleHide={handleHide} isLoading={isLoading} />
      </form>
    </SlideOver>
  );
};

export default ResearchStateSlide;
