import useAuth from "~/src/hooks/use-auth";
import useEdit from "~/src/hooks/use-edit";
import useModal from "~/src/hooks/use-modal";
import useProject from "~/src/hooks/use-project";
import dateFormatter from "~/src/modules/date-formatter";
import SubmitButton from "~/src/ui/buttons/submit-button";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import EditIcon from "~/src/ui/icons/edit-icon";
import DescriptionTitle from "~/src/ui/titles/description-title";
import { useParams } from "react-router-dom";

import ProjectReviewSlide from "./project-review-slide";

/**
 *
 * @example
 */
const ProjectReview = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { editPossible, editUnitPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editPossible || editUnitPossible;

  return (
    <TabListContainer>

      <ProjectReviewSlide hide={hideSlide} project={slideData} projectId={id} visible={slideVisible} />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Letzte Kontrolle" />

        <DescriptionContainer>
          <div className="mb-2 grid grid-cols-3 gap-2 bg-gray-100 p-2">
            <div className="col-span-3">
              <p className="text-sm font-medium text-gray-700">Letzte Projektkontrolle</p>

              {project?.last_review_date
                ? (
                  <div>
                    <span>{dateFormatter(project?.last_review_date, "day")}</span>

                    <span> ({project?.last_reviewer?.username})</span>
                  </div>
                )
                : null}

              {!project?.last_review_date &&
                <p>-</p>}

              <p>Kontrolle: {project?.last_review_should_filter ? "aktiv" : "inaktiv"}</p>
            </div>

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Letzte Kontrolle (Angebote)</p>

              {project?.last_unit_offer_review_date
                ? (
                  <div>
                    <span>{dateFormatter(project?.last_unit_offer_review_date, "day")}</span>

                    <span> ({project?.last_unit_offer_reviewer?.username})</span>
                  </div>
                )
                : null}

              {!project?.last_unit_offer_review_date &&
                <p>-</p>}

              <p>Kontrolle: {project?.last_unit_review_should_filter ? "aktiv" : "inaktiv"}</p>
            </div>

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Letzte Erfassung (Angebote)</p>

              {project?.last_unit_offer_research_date
                ? (
                  <div>
                    <span>{dateFormatter(project?.last_unit_offer_research_date, "day")}</span>

                    <span> ({project?.last_unit_offer_researcher?.username})</span>
                  </div>
                )
                : null}

              {!project?.last_unit_offer_research_date &&
                <p>-</p>}
            </div>

            <div className="col-span-1">
              <p className="text-sm font-medium text-gray-700">Letzte KV Erfassung/Kontrolle</p>

              {project?.last_unit_sale_research_date
                ? (
                  <div>
                    <span>{dateFormatter(project?.last_unit_sale_research_date, "day")}</span>

                    <span> ({project?.last_unit_sale_researcher?.username})</span>
                  </div>
                )
                : null}

              {!project?.last_unit_sale_research_date &&
                <p>-</p>}
            </div>
          </div>
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide(project)}>
                  <EditIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default ProjectReview;
