/* eslint-disable import/exports-last */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable camelcase */
/* eslint-disable import-x/group-exports */
/* eslint-disable import-x/exports-last */

import { dateFormatLabels, genderLabels } from "~/src/modules/labels";
import precision from "~/src/modules/precision";
import dayjs from "dayjs";
import Joi from "joi";

import { loadLocationOptions } from "./helpers";

export const initialValues = {
  buildingPart: {
    id: "",
    name: "",
    sorting: ""
  },
  buyable: false,
  name: "",
  offerDataAvailable: false,
  rentable: false,
  saleDataAvailable: false,
  show: true,
  unit_category: {
    id: "",
    code: "",
    name: ""
  },
  verwertet: false,
  verwertetDate: new Date(),
  verwertetDateFormat: {
    label: dateFormatLabels.get("month"),
    value: "month"
  },

  hideTz: false,
  kvId: "",
  kvUrl: "",
  saleDate: "",
  salePriceGross: "",
  salePriceNet: "",
  tzNumber: "",
  tzYear: "",

  offerPriceBruttoInvestor: "",
  offerPriceBruttoInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceInvestor: "",
  offerPriceInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceNormal: "",
  offerPriceNormalHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showOfferPriceBruttoInvestorHistory: false,
  showOfferPriceInvestorHistory: false,
  showOfferPriceNormalHistory: false,

  rentBk: "",
  rentBkHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentBrutto: "",
  rentBruttoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentNetto: "",
  rentNettoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showRentBkHistory: false,
  showRentBruttoHistory: false,
  showRentNettoHistory: false,

  privateBuyers: [],

  companyBuyers: [],

  floor: "",
  maisonette: false,
  position: "",
  positionGroup: "",
  positionStaircase: "",
  staircase: "",

  offerArea: "",
  saleArea: "",

  offerRoomCount: "",
  saleRoomCount: "",

  offerLoggiaArea: "",
  offerLoggiaCount: "",
  saleLoggiaArea: "",
  saleLoggiaCount: "",

  offerBalkonArea: "",
  offerBalkonCount: "",
  saleBalkonArea: "",
  saleBalkonCount: "",

  offerGartenArea: "",
  offerGartenCount: "",
  saleGartenArea: "",
  saleGartenCount: "",

  offerTerrasseArea: "",
  offerTerrasseCount: "",
  saleTerrasseArea: "",
  saleTerrasseCount: "",

  offerKellerArea: "",
  offerKellerCount: "",
  saleKellerArea: "",
  saleKellerCount: "",

  internalOfferNotes: "",
  internalSaleNotes: "",
  offerNotice: "",
  saleNotice: ""
};

export const initialValuesBulk = {
  buildingPart: {
    id: "",
    name: "",
    sorting: ""
  },
  buyable: false,
  name: "",
  offerDataAvailable: false,
  rentable: false,
  saleDataAvailable: false,
  show: true,
  unit_category: {
    id: "",
    code: "",
    name: ""
  },
  verwertet: false,
  verwertetDate: new Date(),
  verwertetDateFormat: {
    label: dateFormatLabels.get("month"),
    value: "month"
  },

  hideTz: false,
  kvId: "",
  kvUrl: "",
  saleDate: "",
  salePriceGross: "",
  salePriceNet: "",
  tzNumber: "",
  tzYear: "",

  offerPriceBruttoInvestor: "",
  offerPriceBruttoInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceInvestor: "",
  offerPriceInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceNormal: "",
  offerPriceNormalHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showOfferPriceBruttoInvestorHistory: false,
  showOfferPriceInvestorHistory: false,
  showOfferPriceNormalHistory: false,

  rentBk: "",
  rentBkHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentBrutto: "",
  rentBruttoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentNetto: "",
  rentNettoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showRentBkHistory: false,
  showRentBruttoHistory: false,
  showRentNettoHistory: false,

  privateBuyers: [],

  companyBuyers: [],

  floor: "",
  maisonette: false,
  positionFrom: "",
  positionGroup: "",
  positionStaircase: "",
  positionTo: "",
  staircase: "",

  offerArea: "",
  saleArea: "",

  offerRoomCount: "",
  saleRoomCount: "",

  offerLoggiaArea: "",
  offerLoggiaCount: "",
  saleLoggiaArea: "",
  saleLoggiaCount: "",

  offerBalkonArea: "",
  offerBalkonCount: "",
  saleBalkonArea: "",
  saleBalkonCount: "",

  offerGartenArea: "",
  offerGartenCount: "",
  saleGartenArea: "",
  saleGartenCount: "",

  offerTerrasseArea: "",
  offerTerrasseCount: "",
  saleTerrasseArea: "",
  saleTerrasseCount: "",

  offerKellerArea: "",
  offerKellerCount: "",
  saleKellerArea: "",
  saleKellerCount: "",

  internalOfferNotes: "",
  internalSaleNotes: "",
  offerNotice: "",
  saleNotice: ""
};

export const initialValuesEdit = {
  buyable: false,
  offerDataAvailable: false,
  rentable: false,
  show: true,
  verwertet: false,
  verwertetDate: "",
  verwertetDateFormat: {
    label: dateFormatLabels.get("month"),
    value: "month"
  },

  offerPriceBruttoInvestor: "",
  offerPriceBruttoInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceInvestor: "",
  offerPriceInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceNormal: "",
  offerPriceNormalHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showOfferPriceBruttoInvestorHistory: false,
  showOfferPriceInvestorHistory: false,
  showOfferPriceNormalHistory: false,

  rentBk: "",
  rentBkHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentBrutto: "",
  rentBruttoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentNetto: "",
  rentNettoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showRentBkHistory: false,
  showRentBruttoHistory: false,
  showRentNettoHistory: false,

  offerArea: "",
  offerRoomCount: "",

  offerLoggiaArea: "",
  offerLoggiaCount: "",

  offerBalkonArea: "",
  offerBalkonCount: "",

  offerGartenArea: "",
  offerGartenCount: "",

  offerTerrasseArea: "",
  offerTerrasseCount: "",

  offerKellerArea: "",
  offerKellerCount: "",

  internalOfferNotes: "",
  offerNotice: ""
};

/**
 *
 * @param units
 * @example
 */
export async function generateEditValuesBulk(units) {
  return units.map((unit) => generateEditValues(unit));
}

/**
 *
 * @param {typeof test} contract - The root object
 * @param props0 - The root object
 * @param props0.editing - The root object
 * @returns
 * @example
 */
export const transformContractToUnit = async (
  {
    balkon,
    balkon_count,
    garten,
    keller,
    kv_date,
    kv_id,
    loggia,
    loggia_count,
    nutzflaeche,
    persons,
    price_net,
    price_total,
    rooms,
    terrasse,
    terrasse_count,
    tz,
    tz_year
  }
) => {
  const contractBuyers = persons
    .filter(({ typeId }) => typeId === 1);

  const contractCompanyBuyers = contractBuyers
    .filter(({ categoryId }) => [
      0,
      1,
      2
    ].includes(categoryId));

  const contractPrivateBuyers = contractBuyers
    .filter(({ categoryId }) => categoryId === 3);

  const baseUrl = window?.settings?.REACT_APP_API_ENDPOINT;

  const countriesResponse = await fetch(`${baseUrl}/locations/countries`, { credentials: "include" });

  const { payload: { countries } } = await countriesResponse.json();

  return {
    hide_tz: false,
    internal_sale_notes: "",
    kv_id,
    kv_url: `https://www.immomapping.com/contractdetails.aspx?doc=${kv_id}&src=mapping`,
    sale_area: nutzflaeche,
    sale_balkon_area: balkon,
    sale_balkon_count: balkon_count,
    sale_data_available: true,
    sale_date: kv_date,
    sale_garten_area: garten,
    sale_keller_area: keller,
    sale_loggia_area: loggia,
    sale_loggia_count: loggia_count,
    sale_notice: "",
    sale_price_gross: price_total,
    sale_price_net: price_net,
    sale_room_count: rooms,
    sale_terrasse_area: terrasse,
    sale_terrasse_count: terrasse_count,
    tz_number: tz,
    tz_year,

    company_buyers: contractCompanyBuyers
      .map(({
        lastname: name,
        city,
        companyNumber: reg_number,
        country,
        stairs,
        street,
        streetNumber: house_number,
        top,
        zip: zipcode
      }) => ({
        city,
        country: countries.find(({ code }) => code === country) || null,
        house_number: house_number
          ? [
            house_number,
            stairs,
            top
          ].filter(Boolean).join("/")
          : null,
        is_zvr: false,
        name,
        no_reg_number: reg_number === "" || reg_number === null || reg_number === undefined,
        reg_number,
        show: true,
        street,
        zipcode
      })),
    private_buyers: contractPrivateBuyers
      .map(({
        firstname: name,
        lastname: surname,
        birthdate: birthdateString,
        city,
        country,
        stairs,
        street,
        streetNumber: house_number,
        title: title_prefix,
        title_suffix,
        top,
        zip: zipcode
      }) => {
        const birthdate = birthdateString ? new Date(birthdateString) : null;

        if (birthdate) {
          birthdate.setHours(12);
        }

        return {
          birthdate,
          city,
          country: countries.find(({ code }) => code === country) || countries.find(({ code }) => code === "AUT"),
          gender: "unknown",
          house_number: house_number
            ? [
              house_number,
              stairs,
              top
            ].filter(Boolean).join("/")
            : null,
          name,
          show: true,
          street,
          surname,
          title_prefix,
          title_suffix,
          zipcode
        };
      })
  };
};

/**
 *
 * @param unit
 * @example
 */
export const generateSaleEditValues = async (unit) => {
  const {
    company_buyers,
    hide_tz,
    internal_sale_notes,
    kv_id,
    kv_url,
    private_buyers,
    sale_area,
    sale_balkon_area,
    sale_balkon_count,
    sale_data_available,
    sale_date,
    sale_garten_area,
    sale_garten_count,
    sale_keller_area,
    sale_keller_count,
    sale_loggia_area,
    sale_loggia_count,
    sale_notice,
    sale_price_gross,
    sale_price_net,
    sale_room_count,
    sale_terrasse_area,
    sale_terrasse_count,
    tz_number,
    tz_year
  } = unit;

  return {
    hideTz: hide_tz,
    kvId: kv_id || "",
    kvUrl: kv_url || "",
    saleDataAvailable: sale_data_available || false,
    saleDate: sale_date ? new Date(sale_date) : "",
    salePriceGross: sale_price_gross || "",
    salePriceNet: sale_price_net || "",
    tzNumber: tz_number || "",
    tzYear: tz_year ? new Date(String(tz_year)) : "",

    privateBuyers: private_buyers?.length > 0
      ? await Promise.all(
        private_buyers.map(async (
          {
            birthdate,
            city,
            country,
            gender,
            house_number: houseNumber,
            lat,
            lng,
            name: buyerName,
            notes,
            show: buyerShow,
            street,
            surname,
            title_prefix: titlePrefix,
            title_suffix: titleSuffix,
            zipcode
          }
        ) => ({
          birthdate: birthdate ? new Date(birthdate) : null,
          city: city || "",
          coordinates: lat && lng
            ? {
              label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
              lat,
              lng
            }
            : null,
          country: country?.id
            ? {
              id: country.id,
              code: country.code,
              label: country.name,
              name: country.name
            }
            : null,
          gender: gender
            ? {
              label: genderLabels.get(gender),
              value: gender
            }
            : "unknown",
          houseNumber: houseNumber || "",
          name: buyerName || "",
          notes: notes || "",
          show: buyerShow || true,
          street: street || "",
          surname: surname || "",
          titlePrefix: titlePrefix || "",
          titleSuffix: titleSuffix || "",
          zipcode: zipcode || ""
        }))
      )
      : [],

    companyBuyers: company_buyers?.length > 0
      ? await Promise.all(
        company_buyers.map(async ({
          city,
          country,
          house_number: houseNumber,
          is_zvr: isZvr,
          lat,
          lng,
          name: buyerName,
          no_reg_number: noRegNumber,
          notes,
          reg_number: regNumber,
          show: buyerShow,
          street,
          zipcode
        }) => ({
          city: city || "",
          coordinates: lat && lng
            ? {
              label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
              lat,
              lng
            }
            : null,
          country: country?.id
            ? {
              id: country.id,
              code: country.code,
              label: country.name,
              name: country.name
            }
            : null,
          houseNumber: houseNumber || "",
          isZvr: isZvr || false,
          name: buyerName || "",
          noRegNumber: noRegNumber || false,
          notes: notes || "",
          regNumber: regNumber || "",
          show: buyerShow || true,
          street: street || "",
          zipcode: zipcode || ""
        }))
      )
      : [],

    saleArea: sale_area || "",

    saleRoomCount: sale_room_count || "",

    saleLoggiaArea: sale_loggia_area || "",
    saleLoggiaCount: sale_loggia_count || "",

    saleBalkonArea: sale_balkon_area || "",
    saleBalkonCount: sale_balkon_count || "",

    saleGartenArea: sale_garten_area || "",
    saleGartenCount: sale_garten_count || "",

    saleTerrasseArea: sale_terrasse_area || "",
    saleTerrasseCount: sale_terrasse_count || "",

    saleKellerArea: sale_keller_area || "",
    saleKellerCount: sale_keller_count || "",

    internalSaleNotes: internal_sale_notes || "",
    saleNotice: sale_notice || ""
  };
};

/**
 *
 * @param unit
 * @example
 */
export const generateEditValues = async (unit) => {
  if (unit) {
    const {
      building_part,
      buyable,
      company_buyers,
      floor,
      hide_tz,
      internal_offer_notes,
      internal_sale_notes,
      kv_id,
      kv_url,
      maisonette,
      name,
      offer_area,
      offer_balkon_area,
      offer_balkon_count,
      offer_data_available,
      offer_garten_area,
      offer_garten_count,
      offer_keller_area,
      offer_keller_count,
      offer_loggia_area,
      offer_loggia_count,
      offer_notice,
      offer_price_brutto_investor,
      offer_price_brutto_investor_history,
      offer_price_investor,
      offer_price_investor_history,
      offer_price_normal,
      offer_price_normal_history,
      offer_room_count,
      offer_terrasse_area,
      offer_terrasse_count,
      position,
      position_group,
      position_staircase,
      private_buyers,
      rent_bk,
      rent_bk_history,
      rent_brutto,
      rent_brutto_history,
      rent_netto,
      rent_netto_history,
      rentable,
      sale_area,
      sale_balkon_area,
      sale_balkon_count,
      sale_data_available,
      sale_date,
      sale_garten_area,
      sale_garten_count,
      sale_keller_area,
      sale_keller_count,
      sale_loggia_area,
      sale_loggia_count,
      sale_notice,
      sale_price_gross,
      sale_price_net,
      sale_room_count,
      sale_terrasse_area,
      sale_terrasse_count,
      show,
      show_offer_price_brutto_investor_history,
      show_offer_price_investor_history,
      show_offer_price_normal_history,
      show_rent_bk_history,
      show_rent_brutto_history,
      show_rent_netto_history,
      staircase,
      tz_number,
      tz_year,
      unit_category: uc,
      verwertet,
      verwertet_date,
      verwertet_date_format
    } = unit;

    return {
      buildingPart: {
        id: building_part?.id || "",
        label: building_part?.name ? <p><span className="inline-block w-16 px-1 font-bold text-yellow-500">{Number.parseFloat(building_part?.sorting)}</span>Bauteil: {building_part.name}</p> : "",
        name: building_part?.name || "",
        sorting: building_part?.sorting || ""
      },
      buyable: buyable || false,
      name: name || "",
      offerDataAvailable: offer_data_available || false,
      rentable: rentable || false,
      saleDataAvailable: sale_data_available || false,
      show: show || false,
      unit_category: {
        id: uc?.id || "",
        code: uc?.code || "",
        label: uc?.name || "",
        name: uc?.name || ""
      },
      verwertet: verwertet || false,
      verwertetDate: verwertet_date ? new Date(verwertet_date) : "",
      verwertetDateFormat: {
        label: verwertet_date_format ? dateFormatLabels.get(verwertet_date_format) : dateFormatLabels.get("day"),
        value: verwertet_date_format || "day"
      },

      hideTz: hide_tz,
      kvId: kv_id || "",
      kvUrl: kv_url || "",
      saleDate: sale_date ? new Date(sale_date) : "",
      salePriceGross: sale_price_gross || "",
      salePriceNet: sale_price_net || "",
      tzNumber: tz_number || "",
      tzYear: tz_year ? new Date(String(tz_year)) : "",

      offerPriceBruttoInvestor: offer_price_brutto_investor || "",
      offerPriceBruttoInvestorHistory: offer_price_brutto_investor_history?.length > 0
        ? offer_price_brutto_investor_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      offerPriceInvestor: offer_price_investor || "",
      offerPriceInvestorHistory: offer_price_investor_history?.length > 0
        ? offer_price_investor_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      offerPriceNormal: offer_price_normal || "",
      offerPriceNormalHistory: offer_price_normal_history?.length > 0
        ? offer_price_normal_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      showOfferPriceBruttoInvestorHistory: show_offer_price_brutto_investor_history || false,
      showOfferPriceInvestorHistory: show_offer_price_investor_history || false,
      showOfferPriceNormalHistory: show_offer_price_normal_history || false,

      rentBk: rent_bk || "",
      rentBkHistory: rent_bk_history?.length > 0
        ? rent_bk_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      rentBrutto: rent_brutto || "",
      rentBruttoHistory: rent_brutto_history?.length > 0
        ? rent_brutto_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      rentNetto: rent_netto || "",
      rentNettoHistory: rent_netto_history?.length > 0
        ? rent_netto_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      showRentBkHistory: show_rent_bk_history || false,
      showRentBruttoHistory: show_rent_brutto_history || false,
      showRentNettoHistory: show_rent_netto_history || false,

      privateBuyers: private_buyers?.length > 0
        ? await Promise.all(
          private_buyers.map(async (
            {
              id,
              birthdate,
              city,
              country,
              gender,
              house_number: houseNumber,
              lat,
              lng,
              name: buyerName,
              notes,
              show: buyerShow,
              street,
              surname,
              title_prefix: titlePrefix,
              title_suffix: titleSuffix,
              zipcode
            },
            index
          ) => ({
            birthdate: birthdate ? new Date(birthdate) : null,
            city: city || "",
            coordinates: lat && lng
              ? {
                label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
                lat,
                lng
              }
              : null,
            country: country?.id
              ? {
                id: country.id,
                code: country.code,
                label: country.name,
                name: country.name
              }
              : null,
            gender: gender
              ? {
                label: genderLabels.get(gender),
                value: gender
              }
              : "unknown",
            houseNumber: houseNumber || "",
            name: buyerName || "",
            notes: notes || "",
            show: buyerShow || true,
            street: street || "",
            surname: surname || "",
            titlePrefix: titlePrefix || "",
            titleSuffix: titleSuffix || "",
            zipcode: zipcode || ""
          }))
        )
        : [],

      companyBuyers: company_buyers?.length > 0
        ? await Promise.all(
          company_buyers.map(async ({
            city,
            country,
            house_number: houseNumber,
            is_zvr: isZvr,
            lat,
            lng,
            name: buyerName,
            no_reg_number: noRegNumber,
            notes,
            reg_number: regNumber,
            show: buyerShow,
            street,
            zipcode
          }) => ({
            city: city || "",
            coordinates: lat && lng
              ? {
                label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
                lat,
                lng
              }
              : null,
            country: country?.id
              ? {
                id: country.id,
                code: country.code,
                label: country.name,
                name: country.name
              }
              : null,
            houseNumber: houseNumber || "",
            isZvr: isZvr || false,
            name: buyerName || "",
            noRegNumber: noRegNumber || false,
            notes: notes || "",
            regNumber: regNumber || "",
            show: buyerShow || true,
            street: street || "",
            zipcode: zipcode || ""
          }))
        )
        : [],

      floor: floor || "",
      maisonette: maisonette || false,
      position: position ? Number.parseFloat(position) : "",
      positionGroup: position_group || "",
      positionStaircase: position_staircase ? Number.parseFloat(position_staircase) : "",
      staircase: staircase || "",

      offerArea: offer_area || "",
      saleArea: sale_area || "",

      offerRoomCount: offer_room_count || "",
      saleRoomCount: sale_room_count || "",

      offerLoggiaArea: offer_loggia_area || "",
      offerLoggiaCount: offer_loggia_count || "",
      saleLoggiaArea: sale_loggia_area || "",
      saleLoggiaCount: sale_loggia_count || "",

      offerBalkonArea: offer_balkon_area || "",
      offerBalkonCount: offer_balkon_count || "",
      saleBalkonArea: sale_balkon_area || "",
      saleBalkonCount: sale_balkon_count || "",

      offerGartenArea: offer_garten_area || "",
      offerGartenCount: offer_garten_count || "",
      saleGartenArea: sale_garten_area || "",
      saleGartenCount: sale_garten_count || "",

      offerTerrasseArea: offer_terrasse_area || "",
      offerTerrasseCount: offer_terrasse_count || "",
      saleTerrasseArea: sale_terrasse_area || "",
      saleTerrasseCount: sale_terrasse_count || "",

      offerKellerArea: offer_keller_area || "",
      offerKellerCount: offer_keller_count || "",
      saleKellerArea: sale_keller_area || "",
      saleKellerCount: sale_keller_count || "",

      internalOfferNotes: internal_offer_notes || "",
      internalSaleNotes: internal_sale_notes || "",
      offerNotice: offer_notice || "",
      saleNotice: sale_notice || ""
    };
  }

  return initialValues;
};

const locationSchema = {
  city: Joi.string().empty("")
    .when("street", {
      is: Joi.string().required(),
      then: Joi.string().required()
    }),
  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),
  country: Joi.object({
    code: Joi.string().empty(""),
    name: Joi.string().empty(""),
    value: Joi.number().positive().empty("")
  }).unknown().rename("value", "id")
    .empty(null, "")
    .optional()
    .when("city", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("street", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("houseNumber", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("zipcode", {
      is: Joi.string().required(),
      then: Joi.object().required()
    }),
  houseNumber: Joi.string().empty(""),
  street: Joi.string().empty("")
    .when("houseNumber", {
      is: Joi.string().required(),
      then: Joi.string().required()
    }),
  zipcode: Joi.string().empty("")
};

const privateBuyerCheckSchema = Joi.custom((privateBuyer, helpers) => {
  const surnameIsEmpty = !privateBuyer.surname;

  const otherValuesAreSet = Object.values(privateBuyer).some((value) => (typeof value === "string" && value !== "") || ((typeof value === "object" && value?.constructor === Object) && Object.keys(value).length > 0));

  if (privateBuyer && surnameIsEmpty && otherValuesAreSet) {
    return helpers.error("any.required");
  }

  return privateBuyer;
});

const privateBuyerSchema = Joi.object({
  titlePrefix: Joi.string().empty(""),
  titleSuffix: Joi.string().empty(""),

  name: Joi.string().empty(""),
  surname: Joi.string().empty(""),

  gender: Joi.object({
    label: Joi.string().empty(""),
    value: Joi.string().empty("")
  }).unknown().allow(null),

  birthdate: Joi.date().empty(null).allow(""),

  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),

  id: Joi.number().positive().empty("")
    .optional(),
  notes: Joi.string().empty(""),
  show: Joi.boolean().default(true)
})
  .append(locationSchema)
  .when(privateBuyerCheckSchema, {
    otherwise: Joi.object({
      surname: Joi.required()
    })
  });

const companyBuyerCheckSchema = Joi.custom((companyBuyer, helpers) => {
  const nameIsEmpty = !companyBuyer.name;

  const otherValuesAreSet = Object.values(companyBuyer).some((value) => (typeof value === "string" && value !== "") || ((typeof value === "object" && value?.constructor === Object) && Object.keys(value).length > 0));

  if (companyBuyer && nameIsEmpty && otherValuesAreSet) {
    return helpers.error("any.required");
  }

  return companyBuyer;
});

const companyBuyerSchema = Joi.object({
  name: Joi.string().empty(""),
  regNumber: Joi.string().empty(""),

  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),

  id: Joi.number().positive().empty("")
    .optional(),
  isZvr: Joi.boolean().default(false),
  noRegNumber: Joi.boolean().default(false),
  notes: Joi.string().empty(""),
  show: Joi.boolean().default(true)
})
  .append(locationSchema)
  .when(companyBuyerCheckSchema, {
    otherwise: Joi.object({
      name: Joi.required()
    })
  });

export const schema = Joi.object({
  buildingPart: Joi.object({
    id: Joi.number().positive().empty(""),
    label: Joi.optional(),
    name: Joi.string().empty(""),
    sorting: Joi.number().positive().empty(""),
    value: Joi.optional()
  }).empty(null),
  buyable: Joi.boolean().required(),
  name: Joi.string().empty("").required(),
  offerDataAvailable: Joi.boolean().optional(),
  rentable: Joi.boolean().required(),
  saleDataAvailable: Joi.boolean().when("offerDataAvailable", {
    is: Joi.equal(null, false),
    then: Joi.valid(true).required()
  }),
  show: Joi.boolean().required(),
  unit_category: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    code: Joi.string().empty("").required(),
    name: Joi.string().empty("")
  }).unknown(),
  verwertet: Joi.boolean().required(),
  verwertetDate: Joi.date()
    .empty(null).allow("")
    .optional()
    .when("saleDataAvailable", {
      is: true,
      then: Joi.when("offerDataAvailable", {
        is: true,
        then: Joi.when("verwertet", {
          is: true,
          then: Joi.date()
            .max(Joi.ref("saleDate", {
              adjust: (saleDate) => (saleDate
                ? dayjs(saleDate).subtract(1, "month").add(1, "day")
                  .toDate()
                : dayjs().add(1_000, "years").toDate())
            }))
            .error((errors) => {
              const error = errors.find(({ code }) => code === "date.max");

              error.code = "date.max.verwertet";

              return error;
            })
        })
      })
    }),
  verwertetDateFormat: Joi
    .when("verwertet", {
      is: true,
      otherwise: Joi.object().optional(),
      then: Joi.object({
        label: Joi.string().empty("").optional(),
        value: Joi.string().empty("").required()
      }).required()
    }),

  hideTz: Joi.boolean(),
  kvId: Joi.string().max(100).empty("")
    .optional(),
  kvUrl: Joi.string().uri().empty("")
    .optional(),
  tzNumber: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").required()
    }),
  tzYear: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.date().empty("").optional(),
      then: Joi.date().empty("").required()
    }),

  saleDate: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.date().empty("").optional(),
      then: Joi.date().empty(null).required()
    }),
  salePriceGross: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").optional()
    }),
  salePriceNet: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").required()
    }),

  offerPriceBruttoInvestor: Joi.string().empty(""),
  offerPriceBruttoInvestorHistory: Joi.any(),
  offerPriceInvestor: Joi.string().empty(""),
  offerPriceInvestorHistory: Joi.any(),
  offerPriceNormal: Joi.string().empty(""),
  offerPriceNormalHistory: Joi.any(),
  showOfferPriceBruttoInvestorHistory: Joi.boolean(),
  showOfferPriceInvestorHistory: Joi.boolean(),
  showOfferPriceNormalHistory: Joi.boolean(),

  rentBk: Joi.string().empty(""),
  rentBkHistory: Joi.any(),
  rentBrutto: Joi.string().empty(""),
  rentBruttoHistory: Joi.any(),
  rentNetto: Joi.string().empty(""),
  rentNettoHistory: Joi.any(),
  showRentBkHistory: Joi.boolean(),
  showRentBruttoHistory: Joi.boolean(),
  showRentNettoHistory: Joi.boolean(),

  privateBuyers: Joi.array().items(privateBuyerSchema).sparse(),

  companyBuyers: Joi.array().items(companyBuyerSchema).sparse(),

  floor: Joi.string().empty(""),
  maisonette: Joi.boolean(),
  position: Joi.number().positive().precision(4)
    .empty(""),
  positionGroup: Joi.number().positive().precision(4)
    .empty(""),
  positionStaircase: Joi.number().positive().precision(4)
    .empty(""),
  staircase: Joi.string().empty("").pattern(/(^|\s)etage($|\s)/iu, { invert: true })
    .error((errors) => {
      const error = errors.find(({ code }) => code === "string.pattern.invert.base");

      error.code = "string.etage";

      return error;
    }),

  offerArea: Joi.string().empty(""),
  saleArea: Joi.string().empty(""),

  offerRoomCount: Joi.number().positive().empty(""),
  saleRoomCount: Joi.number().positive().empty(""),

  offerLoggiaArea: Joi.string().empty(""),
  offerLoggiaCount: Joi.number().positive().empty(""),
  saleLoggiaArea: Joi.string().empty(""),
  saleLoggiaCount: Joi.number().positive().empty(""),

  offerBalkonArea: Joi.string().empty(""),
  offerBalkonCount: Joi.number().positive().empty(""),
  saleBalkonArea: Joi.string().empty(""),
  saleBalkonCount: Joi.number().positive().empty(""),

  offerGartenArea: Joi.string().empty(""),
  offerGartenCount: Joi.number().positive().empty(""),
  saleGartenArea: Joi.string().empty(""),
  saleGartenCount: Joi.number().positive().empty(""),

  offerTerrasseArea: Joi.string().empty(""),
  offerTerrasseCount: Joi.number().positive().empty(""),
  saleTerrasseArea: Joi.string().empty(""),
  saleTerrasseCount: Joi.number().positive().empty(""),

  offerKellerArea: Joi.string().empty(""),
  offerKellerCount: Joi.number().positive().empty(""),
  saleKellerArea: Joi.string().empty(""),
  saleKellerCount: Joi.number().positive().empty(""),

  internalOfferNotes: Joi.string().empty(""),
  internalSaleNotes: Joi.string().empty(""),
  offerNotice: Joi.string().empty(""),
  saleNotice: Joi.string().empty("")
});

export const schemaBulk =
  Joi.object({
    buildingPart: Joi.object({
      id: Joi.number().positive().empty(""),
      label: Joi.optional(),
      name: Joi.string().empty(""),
      sorting: Joi.number().positive().empty(""),
      value: Joi.optional()
    }).empty(null),
    buyable: Joi.boolean().required(),
    name: Joi.string().empty("").required(),
    offerDataAvailable: Joi.boolean().required(),
    rentable: Joi.boolean().required(),
    saleDataAvailable: Joi.boolean().required(),
    show: Joi.boolean().required(),
    unit_category: Joi.object({
      id: Joi.number().positive().empty("")
        .required(),
      code: Joi.string().empty("").required(),
      name: Joi.string().empty("")
    }).unknown(),
    verwertet: Joi.boolean().required(),
    verwertetDate: Joi.date()
      .empty(null).allow("")
      .optional()
      .when("saleDataAvailable", {
        is: true,
        then: Joi.when("offerDataAvailable", {
          is: true,
          then: Joi.when("verwertet", {
            is: true,
            then: Joi.date()
              .max(Joi.ref("saleDate", {
                adjust: (saleDate) => (saleDate
                  ? dayjs(saleDate).subtract(1, "month").add(1, "day")
                    .toDate()
                  : dayjs().add(1_000, "years").toDate())
              }))
              .error((errors) => {
                const error = errors.find(({ code }) => code === "date.max");

                error.code = "date.max.verwertet";

                return error;
              })
          })
        })
      }),
    verwertetDateFormat: Joi
      .when("verwertet", {
        is: true,
        otherwise: Joi.object().optional(),
        then: Joi.object({
          label: Joi.string().empty("").optional(),
          value: Joi.string().empty("").required()
        }).required()
      }),

    hideTz: Joi.boolean(),
    kvId: Joi.string().max(100).empty("")
      .optional(),
    kvUrl: Joi.string().uri().empty("")
      .optional(),
    tzNumber: Joi
      .when("saleDataAvailable", {
        is: true,
        otherwise: Joi.string().empty("").optional(),
        then: Joi.string().empty("").required()
      }),
    tzYear: Joi
      .when("saleDataAvailable", {
        is: true,
        otherwise: Joi.date().empty("").optional(),
        then: Joi.date().empty("").required()
      }),

    saleDate: Joi
      .when("saleDataAvailable", {
        is: true,
        otherwise: Joi.date().empty("").optional(),
        then: Joi.date().empty(null).required()
      }),
    salePriceGross: Joi
      .when("saleDataAvailable", {
        is: true,
        otherwise: Joi.string().empty("").optional(),
        then: Joi.string().empty("").optional()
      }),
    salePriceNet: Joi
      .when("saleDataAvailable", {
        is: true,
        otherwise: Joi.string().empty("").optional(),
        then: Joi.string().empty("").required()
      }),

    offerPriceBruttoInvestor: Joi.string().empty(""),
    offerPriceBruttoInvestorHistory: Joi.any(),
    offerPriceInvestor: Joi.string().empty(""),
    offerPriceInvestorHistory: Joi.any(),
    offerPriceNormal: Joi.string().empty(""),
    offerPriceNormalHistory: Joi.any(),
    showOfferPriceBruttoInvestorHistory: Joi.boolean(),
    showOfferPriceInvestorHistory: Joi.boolean(),
    showOfferPriceNormalHistory: Joi.boolean(),

    rentBk: Joi.string().empty(""),
    rentBkHistory: Joi.any(),
    rentBrutto: Joi.string().empty(""),
    rentBruttoHistory: Joi.any(),
    rentNetto: Joi.string().empty(""),
    rentNettoHistory: Joi.any(),
    showRentBkHistory: Joi.boolean(),
    showRentBruttoHistory: Joi.boolean(),
    showRentNettoHistory: Joi.boolean(),

    privateBuyers: Joi.array().items(privateBuyerSchema).sparse(),

    companyBuyers: Joi.array().items(companyBuyerSchema).sparse(),

    floor: Joi.string().empty(""),
    maisonette: Joi.boolean(),
    positionFrom: Joi.number().positive().precision(4)
      .max(10_000)
      .required(),
    positionGroup: Joi.number().positive().precision(4)
      .empty(""),
    positionStaircase: Joi.number().positive().precision(4)
      .empty(""),
    positionTo: Joi.number().positive().precision(4)
      .required()
      .custom((value, helpers) => {
        const positionFrom = typeof helpers.state.ancestors[0].positionFrom === "number" ? helpers.state.ancestors[0].positionFrom : 0;
        const positionTo = value;

        const maxPrecision = (precision(positionFrom) > precision(positionTo)) ? precision(positionFrom) : precision(positionTo);
        const powerOfTen = 10 ** maxPrecision;

        if (positionFrom * powerOfTen > positionTo * powerOfTen) {
          return helpers.error("number.min.positionTo");
        }
        else if (positionFrom * powerOfTen + 1_000 < positionTo * powerOfTen) {
          return helpers.error("number.difference.thousand");
        }
        else if (positionTo * powerOfTen > 10_000 * powerOfTen) {
          return helpers.error("number.max");
        }

        return positionTo;
      }),
    staircase: Joi.string().empty("").pattern(/(^|\s)etage($|\s)/iu, { invert: true })
      .error((errors) => {
        const error = errors.find(({ code }) => code === "string.pattern.invert.base");

        error.code = "string.etage";

        return error;
      }),

    offerArea: Joi.string().empty(""),
    saleArea: Joi.string().empty(""),

    offerRoomCount: Joi.number().positive().empty(""),
    saleRoomCount: Joi.number().positive().empty(""),

    offerLoggiaArea: Joi.string().empty(""),
    offerLoggiaCount: Joi.number().positive().empty(""),
    saleLoggiaArea: Joi.string().empty(""),
    saleLoggiaCount: Joi.number().positive().empty(""),

    offerBalkonArea: Joi.string().empty(""),
    offerBalkonCount: Joi.number().positive().empty(""),
    saleBalkonArea: Joi.string().empty(""),
    saleBalkonCount: Joi.number().positive().empty(""),

    offerGartenArea: Joi.string().empty(""),
    offerGartenCount: Joi.number().positive().empty(""),
    saleGartenArea: Joi.string().empty(""),
    saleGartenCount: Joi.number().positive().empty(""),

    offerTerrasseArea: Joi.string().empty(""),
    offerTerrasseCount: Joi.number().positive().empty(""),
    saleTerrasseArea: Joi.string().empty(""),
    saleTerrasseCount: Joi.number().positive().empty(""),

    offerKellerArea: Joi.string().empty(""),
    offerKellerCount: Joi.number().positive().empty(""),
    saleKellerArea: Joi.string().empty(""),
    saleKellerCount: Joi.number().positive().empty(""),

    internalOfferNotes: Joi.string().empty(""),
    internalSaleNotes: Joi.string().empty(""),
    offerNotice: Joi.string().empty(""),
    saleNotice: Joi.string().empty("")
  });

export const schemaBulkEdit =
  Joi.object({
    buyable: Joi.boolean().optional(),
    offerDataAvailable: Joi.boolean().optional(),
    rentable: Joi.boolean().optional(),
    show: Joi.boolean().optional(),
    verwertet: Joi.boolean().optional(),
    verwertetDate: Joi.date()
      .empty(null).allow("")
      .optional()
      .when("offerDataAvailable", {
        is: true,
        then: Joi.when("verwertet", {
          is: true,
          then: Joi.date()
            .max(Joi.ref("saleDate", {
              adjust: (saleDate) => (saleDate
                ? dayjs(saleDate).subtract(1, "month").add(1, "day")
                  .toDate()
                : dayjs().add(1_000, "years").toDate())
            }))
            .error((errors) => {
              const error = errors.find(({ code }) => code === "date.max");

              error.code = "date.max.verwertet";

              return error;
            })
        })
      }),

    verwertetDateFormat: Joi
      .when("verwertet", {
        is: true,
        otherwise: Joi.object().optional(),
        then: Joi.object({
          label: Joi.string().empty(""),
          value: Joi.string().empty("")
        })
      }),

    offerPriceBruttoInvestor: Joi.string().empty("").optional(),
    offerPriceBruttoInvestorHistory: Joi.any().optional(),
    offerPriceInvestor: Joi.string().empty("").optional(),
    offerPriceInvestorHistory: Joi.any().optional(),
    offerPriceNormal: Joi.string().empty("").optional(),
    offerPriceNormalHistory: Joi.any().optional(),
    showOfferPriceBruttoInvestorHistory: Joi.boolean().optional(),
    showOfferPriceInvestorHistory: Joi.boolean().optional(),
    showOfferPriceNormalHistory: Joi.boolean().optional(),

    rentBk: Joi.string().empty("").optional(),
    rentBkHistory: Joi.any().optional(),
    rentBrutto: Joi.string().empty("").optional(),
    rentBruttoHistory: Joi.any().optional(),
    rentNetto: Joi.string().empty("").optional(),
    rentNettoHistory: Joi.any().optional(),
    showRentBkHistory: Joi.boolean().optional(),
    showRentBruttoHistory: Joi.boolean().optional(),
    showRentNettoHistory: Joi.boolean().optional(),

    offerArea: Joi.string().empty("").optional(),

    offerRoomCount: Joi.number().positive().empty("")
      .optional(),

    offerLoggiaArea: Joi.string().empty("").optional(),
    offerLoggiaCount: Joi.number().positive().empty("")
      .optional(),

    offerBalkonArea: Joi.string().empty("").optional(),
    offerBalkonCount: Joi.number().positive().empty("")
      .optional(),

    offerGartenArea: Joi.string().empty("").optional(),
    offerGartenCount: Joi.number().positive().empty("")
      .optional(),

    offerTerrasseArea: Joi.string().empty("").optional(),
    offerTerrasseCount: Joi.number().positive().empty("")
      .optional(),

    offerKellerArea: Joi.string().empty("").optional(),
    offerKellerCount: Joi.number().positive().empty("")
      .optional(),

    internalOfferNotes: Joi.string().empty("").optional(),
    offerNotice: Joi.string().empty("").optional()
  });
