import {
  customActivity,
  dateActivity,
  dateFormatActivity,
  hiddenActivity
} from "~/src/modules/format-activity-change/helpers";
import {
  constructionPhaseLabels,
  marketingPhaseLabels
} from "~/src/modules/labels";

export default {
  ...Object.fromEntries(
    ["id", "project_id"].map((key) => [key, hiddenActivity])
  ),

  current_construction_phase: customActivity({
    label: "Bau-Timeline - Aktueller Baustatus",
    value: (value) => constructionPhaseLabels[value]
  }),

  ...Object.fromEntries(
    Object.entries(constructionPhaseLabels)
      .filter(([key]) => key.startsWith("construction"))
      .flatMap(([key, label]) => [["date", dateActivity], ["date_format", dateFormatActivity]].map(([type, activityFunction]) => [
        `${key}_${type}`,
        activityFunction(
          `Bau-Timeline - ${label} (${type === "date" ? "Datum" : "Format"})`
        )
      ]))
  ),

  current_marketing_phase: customActivity({
    label: "Vermarktungs-Timeline - Aktueller Verkmarktungsstatus",
    value: (value) => marketingPhaseLabels[value]
  }),

  ...Object.fromEntries(
    Object.entries(marketingPhaseLabels)
      .filter(([key]) => key.startsWith("marketing"))
      .flatMap(([key, label]) => [["date", dateActivity], ["date_format", dateFormatActivity]].map(([type, activityFunction]) => [
        `${key}_${type}`,
        activityFunction(
          `Vermarktungs-Timeline - ${label} (${type === "date" ? "Datum" : "Format"
          })`
        )
      ]))
  )
};
