import cn from "classnames";

import {
  errorIcon, input, inputReadOnly, inputSmall, inputSmallReadOnly
} from "./styles.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.id - The root object
 * @param props0.autoFocus - The root object
 * @param props0.className - The root object
 * @param props0.disabled - The root object
 * @param props0.errors - The root object
 * @param props0.isDetailsView - The root object
 * @param props0.name - The root object
 * @param props0.onChange - The root object
 * @param props0.onFocus - The root object
 * @param props0.placeholder - The root object
 * @param props0.register - The root object
 * @param props0.small - The root object
 * @param props0.type - The root object
 * @example
 */
const InlineInput = ({
  autoFocus = false,
  className,
  disabled = false,
  errors,
  isDetailsView = false,
  name,
  onChange = () => { },
  onFocus = () => { },
  placeholder = "",
  register,
  small = false,
  type = "search",

  id = `${name}${Date.now()}`
}) => {
  const error = errors?.[name] || errors?.[name.split(".")[0]] || errors?.[name.replace(/\[(\d+)]\.value/, "")];

  if (isDetailsView) {
    disabled = true;
  }

  const labelMatchesName = () => error.context.label.replace(/\[(\d+)]./, ".$1.") === name ||
    error.context.label === name;

  const showError = Boolean(error) && labelMatchesName;

  const field = register(name);

  const { onChange: innerOnChange } = field;

  return (
    <div className={cn("relative", className)}>
      <input
        {...register(name)}
        {
          ...{
            id,
            autoFocus,
            disabled,
            onChange: (e) => {
              onChange(e);
              innerOnChange(e);
            },
            onFocus,
            placeholder,
            type
          }
        }
        autoComplete="false"
        className={cn({
          "bg-gray-200 cursor-not-allowed": disabled && !isDetailsView,
          "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError && !isDetailsView,
          "border-none px-0": isDetailsView,
          "border-red-500 focus:ring-red-700 focus:border-red-700": showError,
          [input]: !small,
          [inputReadOnly]: !small && isDetailsView,
          [inputSmall]: small,
          [inputSmallReadOnly]: small && isDetailsView
        })}
      />

      {showError
        ? (
          <div className={errorIcon}>
            <svg className="size-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
              <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" fillRule="evenodd" />
            </svg>
          </div>
        )
        : null}

      {showError ? <p className="mt-2 text-sm text-red-600">{error.message}</p> : null}
    </div>
  );
};

export default InlineInput;
