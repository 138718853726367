import dayjs from "dayjs";
import dayJsIsToday from "dayjs/plugin/isToday";
import dayJsIsYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(dayJsIsToday);
dayjs.extend(dayJsIsYesterday);

/**
 *
 * @param value
 * @example
 */
const formatArea = (value) => {
  if (value !== undefined && value !== null) {
    return `${Number.parseFloat(value).toLocaleString("de", { maximumFractionDigits: 2 })} m²`;
  }

  return "";
};

export { formatArea };

/**
 *
 * @param value
 * @example
 */
const formatNumber = (value) => {
  if (value !== undefined && value !== null) {
    return Number.parseFloat(value).toLocaleString("de", { maximumFractionDigits: 2 });
  }

  return "";
};

export { formatNumber };

/**
 *
 * @param value
 * @example
 */
const formatEuro = (value) => {
  if (value !== undefined && value !== null) {
    return `€ ${Number.parseFloat(value).toLocaleString("de", { maximumFractionDigits: 2 })}`;
  }

  return "";
};

export { formatEuro };

/**
 *
 * @param value
 * @example
 */
const formatPrice = (value) => {
  if (value !== undefined && value !== null) {
    return value.toLocaleString("de");
  }

  return "";
};

export { formatPrice };

/**
 *
 * @param options0 - The root object
 * @param options0.date - The root object
 * @param options0.withTime - The root object
 * @param options0.withWords - The root object
 * @example
 */
const formatDate = ({
  date = null, withTime = false, withWords = false
}) => {
  if (date === null) {
    return null;
  }

  if (withWords) {
    const addOptionalTime = withTime ? dayjs(date).format(" HH:mm") : "";

    if (isToday(date)) {
      return `heute${addOptionalTime}`;
    }

    if (isYesterday(date)) {
      return `gestern${addOptionalTime}`;
    }
  }

  if (withTime) {
    return dayjs(date).format("DD.MM.YYYY HH:mm");
  }

  return dayjs(date).format("DD.MM.YYYY");
};

export { formatDate };

/**
 *
 * @param date
 * @example
 */
const validDate = (date) => {
  if (date) {
    return dayjs(date).format("YYYY-MM-DD");
  }

  return "";
};

export { validDate };

/**
 *
 * @param date
 * @example
 */
const formatYear = (date) => {
  if (date) {
    return dayjs(date).format("YYYY");
  }

  return null;
};

export { formatYear };

/**
 *
 * @param singular
 * @param plural
 * @param length
 * @example
 */
const pluralize = (singular = "", plural = "", length = 0) => (length === 0 || length > 1 ? plural : singular);

export { pluralize };

/**
 *
 * @param a
 * @param b
 * @example
 */
const formatBytes = (a, b = 2) => {
  if (a === 0) {
    return "0 Bytes";
  } const c = b < 0 ? 0 : b; const
    d = Math.floor(Math.log(a) / Math.log(1_024));

  return `${Number.parseFloat((a / 1_024 ** d).toFixed(c))} ${[
    "Bytes",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB"
  ][d]}`;
};

export { formatBytes };

/**
 *
 * @param date
 * @example
 */
const isToday = (date) => {
  if (date) {
    return dayjs(date).isToday();
  }

  return null;
};

export { isToday };

/**
 *
 * @param date
 * @example
 */
const isYesterday = (date) => {
  if (date) {
    return dayjs(date).isYesterday();
  }

  return null;
};

export { isYesterday };
