import useAuth from "~/src/hooks/use-auth";
import useEdit from "~/src/hooks/use-edit";
import useModal from "~/src/hooks/use-modal";
import useProject from "~/src/hooks/use-project";
import SubmitButton from "~/src/ui/buttons/submit-button";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import EditIcon from "~/src/ui/icons/edit-icon";
import DescriptionTitle from "~/src/ui/titles/description-title";
import { useParams } from "react-router-dom";

import UnitNotesSlide from "./unit-notes-slide";

/**
 *
 * @example
 */
const UnitNotes = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    isError, isLoading, project
  } = useProject(id);
  const { editUnitPossible } = useEdit({
    project,
    user
  });

  const {
    data: slideData, hide: hideSlide, show: showSlide, visible: slideVisible
  } = useModal();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  const showEditActions = editUnitPossible;

  return (
    <TabListContainer>

      <UnitNotesSlide hide={hideSlide} project={slideData} projectId={id} visible={slideVisible} />

      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Objektnotizen" />

        <DescriptionContainer>
          <div
            className="prose prose-sm"
            dangerouslySetInnerHTML={{
              __html: project?.unit_notes
            }}
          />
        </DescriptionContainer>

        {showEditActions
          ? (
            <div className="flex justify-end">
              <div>
                <SubmitButton onClick={() => showSlide(project)}>
                  <EditIcon className="size-5" />
                </SubmitButton>
              </div>
            </div>
          )
          : null}

      </div>

    </TabListContainer>
  );
};

export default UnitNotes;
