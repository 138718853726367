import ProjectTransactions from "~/src/features/project-transactions";
import useProject from "~/src/hooks/use-project";
import Skeleton from "~/src/ui/loading/item-skeleton";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const ProjectTransactionsPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <ProjectTransactions />
  );
};

export default ProjectTransactionsPage;
