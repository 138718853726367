import useCityId from "~/src/hooks/use-city-id";
import API from "~/src/modules/api";
import AsyncSelect from "~/src/ui/selects/custom-async-select";
import { useEffect, useState } from "react";

/**
 *
 * @param props0 - The root object
 * @param props0.cityId - The root object
 * @param props0.error - The root object
 * @param props0.isSubmitting - The root object
 * @param props0.onChange - The root object
 * @param props0.placeholder - The root object
 * @example
 */
const CitySelect = ({
  cityId,
  error,
  isSubmitting,
  onChange,
  placeholder = "Ort"
}) => {
  const showError = error && isSubmitting;

  const {
    city, isError, isLoading, mutate
  } = useCityId(cityId);

  const cityDisplayed = (city)
    ? {
      label: `${city.zipcode} ${city.name}`,
      value: city.id
    }
    : null;

  return (
    <div>
      <AsyncSelect
        customValueKey="body"
        defaultValue={null}
        error={showError}
        loadOptions={loadOptions}
        name="city"
        noOptions="Postleitzahl oder Ort eingeben..."
        onChange={onChange}
        placeholder={placeholder}
        {...(city && { value: cityDisplayed })}
      />

      {showError ? <p className="mt-2 text-sm text-red-600">{error.id?.message}</p> : null}
    </div>
  );
};

export default CitySelect;

/**
 *
 * @param inputValue
 * @param callback
 * @example
 */
async function loadOptions(inputValue, callback) {
  API.post("/cities/search-by-zipcode", {
    q: inputValue
  }).then((res) => {
    const options = res.data.payload.cities.map((city) => ({
      ...city,
      body: {
        id: city.id,
        name: city.name,
        zipcode: city.zipcode
      },
      label: `${city.zipcode} ${city.name}`,
      value: city.id
    }));

    callback(options);
  }).catch((error) => {
    console.log(error);
  });
}
