/* eslint-disable max-lines-per-function */
import useForm from "~/src/hooks/use-form";
import useNotification from "~/src/hooks/use-notification";
import useProject from "~/src/hooks/use-project";
import SlideFormFooter from "~/src/ui/footers/slide-form-footer";
import DateInput from "~/src/ui/forms/date-input";
import SlideFormHeader from "~/src/ui/headers/slide-form-header";
import ConstructionPhaseSelect from "~/src/ui/selects/construction-phase-select";
import DateFormatSelect from "~/src/ui/selects/date-format-select";
import MarketingPhaseSelect from "~/src/ui/selects/marketing-phase-select";
import SlideOver from "~/src/ui/slides/container";

import { handleUpdate } from "./handlers";
import { generateEditValues, schema } from "./schema";

/**
 *
 * @param props0 - The root object
 * @param props0.hide - The root object
 * @param props0.project - The root object
 * @param props0.projectId - The root object
 * @param props0.visible - The root object
 * @example
 */
const EditTimelineSlide = ({
  hide, project, projectId, visible
}) => {
  const { mutate } = useProject(projectId);
  const { showError } = useNotification();

  const initialValues = generateEditValues(project);

  const handleHide = (innerForm) => {
    innerForm.resetForm();
    hide();
  };

  const form = useForm({
    initialValues,
    onSubmit: () => {
      handleUpdate({
        id: project?.timeline?.id,
        data: form.values,
        hide: () => {
          handleHide(form);
        },
        mutate,
        setIsLoading: form.setIsLoading,
        setIsSubmitting: form.setIsSubmitting,
        showError
      });
    },
    schema
  });

  return (
    <SlideOver
      visible={visible}
      hide={() => {
        handleHide(form);
      }}
    >
      <form autoComplete="off" className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl" onSubmit={form.handleSubmit}>
        <div className="flex-1">

          <SlideFormHeader
            closeDisabled={form.isLoading}
            title="Timeline bearbeiten"
            handleHide={() => {
              handleHide(form);
            }}
          />

          <div className="m-4">
            <h3 className="text-lg font-medium">Bau-Timeline</h3>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="current_construction_phase">
                  Aktueller Baustatus
                </label>
              </div>

              <div className="sm:col-span-2">
                <ConstructionPhaseSelect
                  defaultValue={form.values.current_construction_phase}
                  error={form.errors.current_construction_phase}
                  isSubmitting={form.isSubmitting}
                  name="current_construction_phase"
                  onChange={form.handleChange}
                  touched={form.touched.current_construction_phase}
                />
              </div>

            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="construction_phase_purchase_date">
                  Liegenschaft/Baurecht erworben
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.construction_phase_purchase_date}
                  id="construction_phase_purchase_date"
                  isSubmitting={form.isSubmitting}
                  name="construction_phase_purchase_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.construction_phase_purchase_date}
                  value={form.values.construction_phase_purchase_date ? new Date(form.values.construction_phase_purchase_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.construction_phase_purchase_date_format}
                  error={form.errors.construction_phase_purchase_date_format}
                  isSubmitting={form.isSubmitting}
                  name="construction_phase_purchase_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.construction_phase_purchase_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="construction_phase_active_date">
                  In Bau
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.construction_phase_active_date}
                  id="construction_phase_active_date"
                  isSubmitting={form.isSubmitting}
                  name="construction_phase_active_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.construction_phase_active_date}
                  value={form.values.construction_phase_active_date ? new Date(form.values.construction_phase_active_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.construction_phase_active_date_format}
                  error={form.errors.construction_phase_active_date_format}
                  isSubmitting={form.isSubmitting}
                  name="construction_phase_active_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.construction_phase_active_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="construction_phase_completed_date">
                  Bau abgeschlossen
                </label>
              </div>

              <div className="sm:col-span-1">

                <DateInput
                  error={form.errors.construction_phase_completed_date}
                  id="construction_phase_completed_date"
                  isSubmitting={form.isSubmitting}
                  name="construction_phase_completed_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.construction_phase_completed_date}
                  value={form.values.construction_phase_completed_date ? new Date(form.values.construction_phase_completed_date) : null}
                />

              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.construction_phase_completed_date_format}
                  error={form.errors.construction_phase_completed_date_format}
                  isSubmitting={form.isSubmitting}
                  name="construction_phase_completed_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.construction_phase_completed_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4">
            <h3 className="text-lg font-medium">Vermarktungs-Timeline</h3>
          </div>

          <div className="m-4 space-y-4 bg-gray-100 py-4 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="current_marketing_phase">
                  Aktueller Verkmarktungsstatus
                </label>
              </div>

              <div className="sm:col-span-2">
                <MarketingPhaseSelect
                  defaultValue={form.values.current_marketing_phase}
                  error={form.errors.current_marketing_phase}
                  isSubmitting={form.isSubmitting}
                  name="current_marketing_phase"
                  onChange={form.handleChange}
                  touched={form.touched.current_marketing_phase}
                />
              </div>

            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="marketing_phase_coming_soon_date">
                  Coming soon
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.marketing_phase_coming_soon_date}
                  id="marketing_phase_coming_soon_date"
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_coming_soon_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.marketing_phase_coming_soon_date}
                  value={form.values.marketing_phase_coming_soon_date ? new Date(form.values.marketing_phase_coming_soon_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.marketing_phase_coming_soon_date_format}
                  error={form.errors.marketing_phase_coming_soon_date_format}
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_coming_soon_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.marketing_phase_coming_soon_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">

              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="marketing_phase_active_date">
                  In Vermarktung
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.marketing_phase_active_date}
                  id="marketing_phase_active_date"
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_active_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.marketing_phase_active_date}
                  value={form.values.marketing_phase_active_date ? new Date(form.values.marketing_phase_active_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.marketing_phase_active_date_format}
                  error={form.errors.marketing_phase_active_date_format}
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_active_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.marketing_phase_active_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="marketing_phase_completed_apartments_date">
                  Wohneinheiten vermarktet
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.marketing_phase_completed_apartments_date}
                  id="marketing_phase_completed_apartments_date"
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_completed_apartments_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.marketing_phase_completed_apartments_date}
                  value={form.values.marketing_phase_completed_apartments_date ? new Date(form.values.marketing_phase_completed_apartments_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.marketing_phase_completed_apartments_date_format}
                  error={form.errors.marketing_phase_completed_apartments_date_format}
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_completed_apartments_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.marketing_phase_completed_apartments_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="marketing_phase_completed_all_date">
                  Vollständig vermarktet
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.marketing_phase_completed_all_date}
                  id="marketing_phase_completed_all_date"
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_completed_all_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.marketing_phase_completed_all_date}
                  value={form.values.marketing_phase_completed_all_date ? new Date(form.values.marketing_phase_completed_all_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.marketing_phase_completed_all_date_format}
                  error={form.errors.marketing_phase_completed_all_date_format}
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_completed_all_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.marketing_phase_completed_all_date_format}
                />
              </div>
            </div>
          </div>

          <div className="m-4 space-y-6 bg-gray-100 py-6 sm:space-y-0 sm:py-0">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
              <div>
                <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2" htmlFor="marketing_phase_project_finished_date">
                  Projekt abgeschlossen
                </label>
              </div>

              <div className="sm:col-span-1">
                <DateInput
                  error={form.errors.marketing_phase_project_finished_date}
                  id="marketing_phase_project_finished_date"
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_project_finished_date"
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  placeholder="Datum"
                  touched={form.touched.marketing_phase_project_finished_date}
                  value={form.values.marketing_phase_project_finished_date ? new Date(form.values.marketing_phase_project_finished_date) : null}
                />
              </div>

              <div className="sm:col-span-1">
                <DateFormatSelect
                  defaultValue={form.values.marketing_phase_project_finished_date_format}
                  error={form.errors.marketing_phase_project_finished_date_format}
                  isSubmitting={form.isSubmitting}
                  name="marketing_phase_project_finished_date_format"
                  onChange={form.handleChange}
                  touched={form.touched.marketing_phase_project_finished_date_format}
                />
              </div>
            </div>
          </div>

        </div>

        <SlideFormFooter
          form={form}
          handleHide={() => {
            handleHide(form);
          }}
        />

      </form>
    </SlideOver>
  );
};

export default EditTimelineSlide;
