import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/solid";
import { pluralize } from "~/src/modules/formatters";
import cn from "classnames";

import { generatePageItems } from "./pagination-helper";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 *
 * @param props0 - The root object
 * @param props0.className - The root object
 * @param props0.labels - The root object
 * @param props0.page - The root object
 * @param props0.pagination - The root object
 * @param props0.setPage - The root object
 * @example
 */
const Pagination = ({
  className, labels, page, pagination, setPage
}) => {
  if (!pagination) {
    return null;
  }

  /**
   *
   * @example
   */
  function handleClickBack() {
    setPage(page - 1);
  }

  /**
   *
   * @example
   */
  function handleClickNext() {
    setPage(page + 1);
  }

  if (pagination?.totalRecords === 0) {
    return null;
  }

  return (
    <nav
      className={cn(
        "flex items-center justify-between bg-gray-100 border-2 border-gray-200 rounded-sm py-2 px-3",
        className
      )}
    >

      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Seite <span className="font-medium">{pagination?.currentPage || 1}</span> von <span className="font-medium">{pagination?.totalPages}</span><span className="ml-4 text-xs">({pagination?.totalRecords} {pluralize(...labels, pagination?.totalRecords)} gefiltert)</span>
        </p>
      </div>

      <div className="flex flex-1 justify-between space-x-1 sm:justify-end">

        {generatePageItems(pagination?.totalPages, page).map((pageItem) => {
          if (pageItem === LEFT_PAGE) {
            return (
              <button className={cn("flex justify-center items-center w-10 py-1 bg-white shadow-sm rounded-sm focus:outline-none")} key={`page_item_${pageItem}`} onClick={handleClickBack} type="button">
                <ChevronDoubleLeftIcon className="size-4 text-gray-600" />
              </button>
            );
          }

          if (pageItem === RIGHT_PAGE) {
            return (
              <button className={cn("flex justify-center items-center w-10 py-1 bg-white shadow-sm rounded-sm focus:outline-none")} key={`page_item_${pageItem}`} onClick={handleClickNext} type="button">
                <ChevronDoubleRightIcon className="size-4 text-gray-600" />
              </button>
            );
          }

          return (
            <button
              key={`page_item_${pageItem}`}
              onClick={() => setPage(pageItem)}
              type="button"
              className={cn("flex justify-center items-center w-10 py-1 shadow-sm rounded-sm focus:outline-none", {
                "bg-gray-700 text-white cursor-default": page === pageItem,
                "bg-white hover:bg-gray-50": page !== pageItem
              })}
            >
              {pageItem}
            </button>
          );
        })}

      </div>

    </nav>
  );
};

export default Pagination;
