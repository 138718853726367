import useAutomatedProject from "~/src/hooks/use-automated-project";
import DescriptionContainer from "~/src/ui/containers/description-container";
import TabListContainer from "~/src/ui/containers/tab-list-container";
import DescriptionTitle from "~/src/ui/titles/description-title";
import cn from "classnames";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const AutomatedProjectOwners = () => {
  const { id } = useParams();
  const {
    automated_project: project, isError, isLoading
  } = useAutomatedProject(id);

  if (isLoading) {
    return null;
  }
  if (isError) {
    return null;
  }

  return (
    <TabListContainer>
      <div className="sm:grid sm:grid-cols-8 sm:gap-4">

        <DescriptionTitle title="Eigentümer" />

        <DescriptionContainer>
          {project?.sellers.map((seller, index) => (

            <div
              key={index}
              className={cn("grid grid-cols-4 gap-2 p-2", {
                "bg-gray-100": index % 2 === 0
              })}
            >

              <div className="col-span-1">
                <p className="text-sm font-medium text-gray-700">FB-Nr.</p>

                <p className="">{seller?.companyNumber}</p>
              </div>

              <div className="col-span-2">
                <p className="text-sm font-medium text-gray-700">Firmenname</p>

                <p className="">{seller?.name}</p>
              </div>

              <div className="col-span-1" />
            </div>
          ))}
        </DescriptionContainer>
      </div>

    </TabListContainer>
  );
};

export default AutomatedProjectOwners;
