import { NotificationContext } from "~/src/contexts/notificationContext";
import { useContext } from "react";

/**
 *
 * @example
 */
const useNotification = () => {
  const {
    error,
    hideError,
    hideSuccess,
    showError,
    showSuccess,
    success
  } = useContext(NotificationContext);

  return {
    error,
    hideError,
    hideSuccess,
    showError,
    showSuccess,
    success
  };
};

export default useNotification;
