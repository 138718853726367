import ProjectUnitsStatistics from "~/src/features/project-units/statistics";
import useProject from "~/src/hooks/use-project";
import Skeleton from "~/src/ui/loading/item-skeleton";
import Page from "~/src/ui/page";
import {
  Link, useParams, useRouteMatch
} from "react-router-dom";

/**
 *
 * @example
 */
const ProjectUnitsStatisticsPage = () => {
  const { id } = useParams();
  const { isError, isLoading } = useProject(id);
  const { url } = useRouteMatch();

  if (isLoading) {
    return <Skeleton />;
  }
  if (isError) {
    return null;
  }

  return (
    <Page className="!bg-transparent" title="Objekte - Statistiken">
      <div className="flex justify-end p-2">
        <div className="underline">
          <Link to={url.replace(/\/statistics$/u, "")}>Liste</Link>
        </div>
      </div>

      <ProjectUnitsStatistics />
    </Page>
  );
};

export default ProjectUnitsStatisticsPage;
