import Layout from "~/src/ui/layout";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

/**
 *
 * @param root0 - The root object
 * @param root0.title - The root object
 * @param root0.className - The root object
 * @param root0.children - The root object
 * @example
 */
const Page = ({
  children, className, title = ""
}) => {
  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <title>{title} | IMMOdev Editor</title>
      </Helmet>

      <Layout
        className={
          cn(
            {
              "bg-stone-50": pathname.startsWith("/automated-projects"),
              "bg-white": !pathname.startsWith("/automated-projects")
            },
            className
          )
        }
      >
        {children}
      </Layout>
    </>
  );
}

export default Page;
