import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

const useLastJob = (name) => {
  const urlSearchParameters = new URLSearchParams({ name });

  const {
    data, error, mutate
  } = useSWR(`/jobs/last?${urlSearchParameters}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    job: data?.payload.job,
    mutate
  };
};

export default useLastJob;
