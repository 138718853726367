import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @example
 */
const useUsers = () => {
  const {
    data, error, mutate
  } = useSWR("/users", fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    users: data?.payload
  };
};

export default useUsers;
