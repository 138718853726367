import { useIssuerCertificates } from "~/src/hooks/use-certificates.js";
import Modal from "~/src/ui/modals";

import {
  handleClose,
  onIssuerCertificateResponse,
  setIssuerCertificatesInForm
} from "./modal/controller.js";
import IssuerForm from "./modal/form.jsx";

const IssuerModal = ({
  modal, mutateIssuers, reactForm
}) => {
  const issuerId = modal.data?.id;

  const issuerCertificatesCache = useIssuerCertificates({
    issuerId,
    onSuccess: onIssuerCertificateResponse.bind({
      setValue: reactForm.setValue
    })
  });

  setIssuerCertificatesInForm({
    issuerCertificatesCache,
    reactForm
  });

  return (
    <Modal
      {...{
        hide: handleClose.bind(null, {
          modal,
          reactForm
        }),
        show: modal.visible
      }}
    >
      <div className="inline-block w-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle" role="dialog">
        <IssuerForm
          {...{
            issuerCertificatesCache,
            modal,
            mutateIssuers,
            reactForm
          }}
        />
      </div>
    </Modal>
  );
};

export default IssuerModal;
