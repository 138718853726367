import Joi from "joi";

export const initialValues = {
  last_review_date: "",
  last_review_should_filter: false,
  last_unit_offer_research_date: "",
  last_unit_offer_review_date: "",
  last_unit_review_should_filter: false,
  last_unit_sale_research_date: ""
};

/**
 *
 * @param project
 * @example
 */
const generateEditValues = (project) => {
  if (project) {
    return {
      last_review_date: project.last_review_date,
      last_review_should_filter: project.last_review_should_filter,
      last_unit_offer_research_date: project.last_unit_offer_research_date,
      last_unit_offer_review_date: project.last_unit_offer_review_date,
      last_unit_review_should_filter: project.last_unit_review_should_filter,
      last_unit_sale_research_date: project.last_unit_sale_research_date
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  last_review_date: Joi.date().empty(null).optional(),
  last_review_should_filter: Joi.boolean().required(),
  last_unit_offer_research_date: Joi.date().empty(null).optional(),
  last_unit_offer_review_date: Joi.date().empty(null).optional(),
  last_unit_review_should_filter: Joi.boolean().required(),
  last_unit_sale_research_date: Joi.date().empty(null).optional()
});
