import useNotification from "~/src/hooks/use-notification";
import API, { fetcher } from "~/src/modules/api";
import useSWR from "swr";

/**
 *
 * @param id
 * @example
 */
const useProject = (id) => {
  const {
    data, error, mutate
  } = useSWR(`/projects/${id}`, fetcher);
  const { showError, showSuccess } = useNotification();

  const submitProject = async (project) => {
    try {
      const response = await API.patch(`/projects/${id}`, project);

      if (response.status === 200) {
        showSuccess();
      }
      else {
        throw new Error();
      }
    }
    catch (error) {
      showError();
      throw new Error(error);
    }
  };

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    project: data?.payload?.project,
    submitProject,
    transactionCategories: data?.payload?.transaction_categories
  };
};

export default useProject;
