.tableContainer {
  @apply my-3 grid grid-cols-2 md:grid-cols-1 border-2 border-gray-200 overflow-hidden rounded-sm;
}

.headerItem {
  @apply bg-gray-100 text-left text-xs font-bold text-gray-500 uppercase tracking-wider;
}

.tableBody {
  @apply grid grid-cols-1 col-span-2 md:col-span-1 divide-y-2 divide-gray-200;
}

.colId {
  @apply col-span-2 xl:col-span-1 py-4 pl-4 pr-1.5 whitespace-nowrap text-sm text-gray-500 absolute right-4 md:static md:p-0 font-medium;
}

.colProjectName {
  @apply col-span-1 md:col-span-5 px-4 py-4 text-left whitespace-nowrap text-sm text-gray-500 truncate;
}

.colProjectLocations {
  @apply col-span-1 md:col-span-4 xl:col-span-5 px-4 py-1 md:py-4 text-left whitespace-nowrap text-sm text-gray-500 truncate;
}

.colDateEdited {
  @apply col-span-3 md:col-span-3 xl:col-span-2 px-4 py-1 md:py-4 text-left whitespace-nowrap text-sm text-gray-500 truncate;
}

.colBadge {
  @apply col-span-1 md:col-span-2 px-4 pt-1 pb-2 md:py-4 text-left whitespace-nowrap text-sm text-gray-500 truncate flex flex-col items-start gap-1;
}

.colKv {
  @apply col-span-1 md:col-span-1 px-4 pt-1 pb-2 md:py-4 text-left whitespace-nowrap text-sm text-gray-500 truncate flex flex-row md:flex-col gap-2 items-center;
}
