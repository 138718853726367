import { fetcher } from "~/src/modules/api";
import useSWR from "swr";

import { createRequestQuery } from "./_common/_exports.js";
import { getRequestParamName as getRequestParameterName } from "./use-projects/_exports.js";

/**
 *
 * @param pageParameters
 * @example
 */
const useProjects = (pageParameters) => {
  const requestQuery = createRequestQuery({
    getRequestParamName: getRequestParameterName,
    pageParams: pageParameters
  });
  const { data, error } = useSWR(`/projects?${requestQuery}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    pagination: data?.payload.pagination,
    projects: data?.payload.projects
  };
};

export default useProjects;
