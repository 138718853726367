import { ExclamationIcon } from "@heroicons/react/solid";
import useAuth from "~/src/hooks/use-auth";
import { formatDate } from "~/src/modules/formatters";
import LockBadgeSmall from "~/src/ui/badges/lock-badge-small";
import Badge from "~/src/ui/badges/status-badge";
import Skeleton from "~/src/ui/loading/item-skeleton";
import ProjectLink from "~/src/ui/project-link";
import ProjectLocation from "~/src/ui/project-location";
import cn from "classnames";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import {
  colBadge, colDateEdited, colId, colKv, colProjectLocations, headerItem, tableBody, tableContainer
} from "./styles.module.css";

/**
 *
 * @param props0 - The root object
 * @param props0.isLoading - The root object
 * @param props0.projects - The root object
 * @example
 */
const FeedList = ({ isLoading, projects }) => {
  const { user } = useAuth();

  if (isLoading) {
    return <div className="mt-28"><Skeleton /><Skeleton /><Skeleton /></div>;
  }

  return (
    <div className="mt-8">
      <div className="flex items-end justify-between px-4 text-gray-600 sm:px-2 lg:px-0">
        <h2 className="text-xl">Projekte mit neuen Kaufverträgen: {projects?.length}</h2>

        <div className="flex flex-col gap-2">
          <div className="grid auto-cols-min grid-cols-[auto_1.25rem_auto] items-center gap-1 text-xs">
            <span>*</span>

            <ExclamationIcon className="size-5 text-red-500" />

            <span>ohne letztes Kontrolldatum</span>
          </div>
        </div>
      </div>

      <div className={tableContainer}>

        <div className="hidden grid-cols-16 border-b-2 border-gray-200 md:grid">

          <div className={cn(headerItem, "px-4 py-3 col-span-1 md:col-span-6 grid grid-cols-8")}>
            <div className={cn(headerItem, "col-span-2 xl:col-span-1 pr-1.5")}>
              <p>ID</p>
            </div>

            <p>Name</p>
          </div>

          <div className={cn(headerItem, "px-4 py-3 col-span-1 md:col-span-4 xl:col-span-5")}>
            <p>Adresse</p>
          </div>

          <div className={cn(headerItem, "px-4 py-3 col-span-3 xl:col-span-2")}>
            <p>Datum</p>
          </div>

          <div className={cn(headerItem, "px-4 py-3 col-span-2")}>
            <p>Status</p>
          </div>

          <div className={cn(headerItem, "px-4 py-3 col-span-1 whitespace-nowrap")}>
            <p># KV</p>
          </div>

        </div>

        <div className={tableBody}>
          {projects?.map((project) => {
            const isProjectEditor = project?.active_project_editor && project.active_project_editor.email === user?.email;
            const isProjectLocked = Boolean(project?.active_project_editor);
            const isUnitEditor = project?.active_unit_editor && project.active_unit_editor.email === user?.email;
            const isUnitLocked = Boolean(project?.active_unit_editor);

            return (
              <div
                className="col-span-1 flex flex-col truncate md:grid md:grid-cols-16"
                key={`projects_${project.id}`}
              >

                <div className="relative col-span-1 grid grid-cols-8 truncate whitespace-nowrap p-4 text-left text-sm text-gray-500 md:col-span-6">
                  <div className={colId}>
                    {project?.id}
                  </div>

                  <div>
                    <ProjectLink project={project} />

                    {isProjectLocked
                      ? (
                        <div className="mt-1 flex items-center">
                          <LockBadgeSmall isEditor={isProjectEditor} isLocked={isProjectLocked} />

                          <span className="ml-1">{project?.active_project_editor?.username} (Projekterfassung)</span>
                        </div>
                      )
                      : null}

                    {isUnitLocked
                      ? (
                        <div className="mt-1 flex items-center">
                          <LockBadgeSmall isEditor={isUnitEditor} isLocked={isUnitLocked} />

                          <span className="ml-1">{project?.active_unit_editor?.username} (Objekterfassung)</span>
                        </div>
                      )
                      : null}
                  </div>

                </div>

                <div className={colProjectLocations}>
                  {
                    [...project?.locations?.slice(0, 3)]
                      .sort(({ default_location: defaultA }, { default_location: defaultB }) => (defaultA ? -1 : 0))
                      .map((location, index, array) => (
                        <Fragment key={`location_${location.id}`}>
                          <ProjectLocation location={location} />

                          {(index === array.length - 1 && project?.locations.length > array.length) && (
                            <Link className="underline" to={`/projects/${project.id}/project-infos`}>...</Link>
                          )}
                        </Fragment>
                      ))
                  }
                </div>

                <div className={colDateEdited}>
                  <div>
                    <p className="text-xs font-medium text-gray-500">Letzte Aktualisierung:</p>

                    <p>
                      {project.last_contract_update && project.last_unit_sale_research_date
                        ? formatDate({
                          date: project.last_contract_update,
                          withTime: true
                        })
                        : "-"}
                    </p>
                  </div>

                  <div className="mt-2">
                    <p className="text-xs font-medium text-gray-500">Letzte Erfassung:</p>

                    <p>
                      {project.last_unit_sale_research_date
                        ? formatDate({
                          date: project.last_unit_sale_research_date,
                          withTime: true
                        })
                        : "-"}
                    </p>
                  </div>

                </div>

                <div className={colBadge}>
                  {
                    project.published
                      ? <Badge badgeFor="public" text="Öffentlich" />
                      : <Badge badgeFor="internal" text="Intern" />
                  }

                  {(project.published && project.unpublished_changes) ? <Badge badgeFor="changes" text="Offene Änderungen" /> : null}
                </div>

                <div className={colKv}>
                  {
                    project.last_unit_sale_research_date
                      ? (
                        <Link
                          className="truncate font-medium text-gray-500 underline hover:no-underline"
                          to={`/projects/${project.id}/project-contracts`}
                        >
                          <span>{project.new_contracts_count}</span>
                        </Link>
                      )
                      : null
                  }

                  {
                    !project.last_unit_sale_research_date && (
                      <Link
                        className="truncate font-medium text-gray-500 underline hover:no-underline"
                        to={`/projects/${project.id}/project-contracts`}
                      >
                        <ExclamationIcon className="size-5 text-red-500" />
                      </Link>
                    )
                  }
                </div>

              </div>
            );
          })}
        </div>
      </div>
    </div>

  );
};

export default FeedList;
