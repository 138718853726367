import Button from "~/src/ui/buttons/button/index.jsx";
import Field from "~/src/ui/forms/field";
import DeleteIcon from "~/src/ui/icons/delete-icon.jsx";
import PlusIcon from "~/src/ui/icons/plus-icon.jsx";
import cn from "classnames";

import { addNewIssuerCertificateEntry, removeCertificateFromForm } from "./controller.js";

const IssuerCertificateEntry = ({
  certificate, commonFieldProps, isDetailsView, issuerCertificateIndex, modal, reactForm
}) => {
  if (certificate?.fromDb) {
    return (
      <div className="grid w-full grid-cols-6">
        <Field
          {...{
            className: "col-span-3",
            hasLabel: false,
            inline: true,
            isDetailsView: commonFieldProps.isDetailsView,
            name: `issuerCertificates.${issuerCertificateIndex}.name`,
            register: reactForm.register
          }}
        />

        {!commonFieldProps.isDetailsView && (
          <Field
            {...{
              className: "col-span-2",
              hasLabel: false,
              inline: true,
              name: `issuerCertificates.${issuerCertificateIndex}.rank`,
              ...commonFieldProps
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className="grid w-full grid-cols-6">
      <Field
        {...{
          className: "col-span-3",
          hasLabel: false,
          inline: true,
          isDetailsView: commonFieldProps.isDetailsView,
          name: `issuerCertificates.${issuerCertificateIndex}.name`,
          register: reactForm.register
        }}
      />

      {!commonFieldProps.isDetailsView && (
        <Field
          {...{
            className: "col-span-2",
            hasLabel: false,
            inline: true,
            name: `issuerCertificates.${issuerCertificateIndex}.rank`,
            ...commonFieldProps
          }}
        />
      )}

      {!commonFieldProps.isDetailsView && (
        <div>
          <Button
            {...{
              className: "bg-red-700 hover:bg-red-800 focus:ring-red-700 disabled:bg-red-300 self-end ml-2",
              icon: DeleteIcon,
              iconSize: 5,
              onClick: removeCertificateFromForm.bind(null, {
                issuerCertificateIndex,
                modal,
                reactForm
              })
            }}
          />
        </div>
      )}
    </div>
  );
};

const CertificateList = ({
  commonFieldProps, modal, reactForm
}) => {
  const { isDetailsView } = commonFieldProps;
  const hasCertificatesChecked = reactForm.watch("has_certificates");

  if (!hasCertificatesChecked && !isDetailsView) {
    return null;
  }

  if (!hasCertificatesChecked && isDetailsView) {
    return (
      <>
        <label className="col-span-3 text-gray-700">Zertifikat</label>

        <p>k.A.</p>
      </>
    );
  }

  const items = [];
  //const issuerCertificatesList = [];
  const issuerCertificates = reactForm.getValues("issuerCertificates");

  const issuerCertificatesList = issuerCertificates.map((certificate, index) => (
    <IssuerCertificateEntry
      {...{
        certificate,
        commonFieldProps,
        isDetailsView,
        issuerCertificateIndex: index,
        modal,
        reactForm
      }}
    />
  ));

  items.push(<div className="w-full">{issuerCertificatesList}</div>);

  if (!isDetailsView && hasCertificatesChecked) {
    items.push(
      <div className="mt-2">
        <Button
          {...{
            className: "self-center",
            icon: PlusIcon,
            onClick: addNewIssuerCertificateEntry.bind(null, {
              modal,
              reactForm
            })
          }}
        />
      </div>
    );
  }

  const certificatesColumnLabel = isDetailsView
    ? "Zertifikate"
    : "Zertifikatsname";

  return (
    <>
      <div className="grid w-full grid-cols-6">
        {hasCertificatesChecked
          ? (
            <label
              className={cn(
                "col-span-3",
                { "text-gray-700": isDetailsView }
              )}
            >
              {certificatesColumnLabel}
            </label>
          )
          : null}

        {!isDetailsView &&
          <label className="col-span-2">Position</label>}
      </div>

      {items}
    </>
  );
};

export default CertificateList;
