import Joi from "joi";

export const initialValues = {
  ez: "",
  kgnr: ""
};

/**
 *
 * @param contractRegion
 * @example
 */
const generateEditValues = (contractRegion) => {
  if (contractRegion) {
    return {
      ez: contractRegion.ez || "",
      kgnr: contractRegion.kgnr || ""
    };
  }

  return initialValues;
};

export { generateEditValues };

export const schema = Joi.object({
  ez: Joi.number().integer().positive()
    .empty("")
    .required(),
  kgnr: Joi.number().integer().positive()
    .empty("")
    .required()
});
