import Select from "~/src/ui/forms/select";

import dateFormats from "./options";

/**
 *
 * @param properties
 * @example
 */
const DateFormatSelect = (properties) => {
  let defaultValue = dateFormats[0];

  if (properties.defaultValue) {
    defaultValue = Object
      .keys(dateFormats)
      .map((key) => dateFormats[key])
      .find((format) => format.value === properties.defaultValue.value);
  }

  return (
    <Select
      options={dateFormats}
      placeholder="Datumsformat"
      {...properties}
      defaultValue={defaultValue}
    />
  );
};

export default DateFormatSelect;
