import useSWR from "swr";

import { selectByKeys } from "../_common/_exports.js";
import { path } from "../features/certificates/issuers/schema.js";
import { fetcher } from "../modules/api.js";

import { createRequestQuery } from "./_common/_exports.js";

const useIssuers = (pageParameters) => {
  const requestQuery = createRequestQuery({ pageParams: pageParameters });
  const {
    data, error, mutate
  } = (
    useSWR(`${path.issuers}?${requestQuery}`, fetcher)
  );

  return {
    ...selectByKeys(data?.payload, ["issuers", "pagination"]),
    isError: Boolean(error),
    isLoading: !error && !data,
    mutate
  };
};

const useIssuerCertificates = ({ issuerId, onSuccess }) => {
  if (!issuerId) {
    return { data: [] };
  }

  const requestQuery = createRequestQuery({ pageParams: { issuerId } });
  const {
    data, error, mutate
  } = (
    useSWR(
      `${path.issuer_certificates}?${requestQuery}`,
      fetcher,
      { onSuccess }
    )
  );

  console.log("useIssuerCertificates", {
    data,
    issuerId
  });

  return {
    data: data?.payload?.issuer_certificates ?? [],
    isLoading: !error && !data,
    mutate
  };
};

export { useIssuerCertificates, useIssuers };
